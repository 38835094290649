import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger';
import rootReducer from './reducers';
import sagas from './common/sagas';

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

//Configure hot module replacement if running in dev server.
export const configureHotModuleReplacement = store => {
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');

      store.replaceReducer(nextRootReducer);
    });
  }
};

export default function configureStore(initialState, history) {
  let devTools = (f) => f;

  if (window.devToolsExtension) {
    devTools = window.devToolsExtension;
  }

  const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

  let createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      logger
    )
  )(createStore);

  const persistedState = undefined; // use loadState(); to allow for loading of saved states

  // devTools need to be setup here, and not in MW compose
  let store = createStoreWithMiddleware(rootReducer, persistedState, devTools());

  //enable the following to allow for saving the store state
  //store.subscribe(throttle(() => {
  //  saveState(store.getState());
  //}, 1000));

  configureHotModuleReplacement(store);

  // as of redux-saga 0.10.0, you can't pass a sagas function into the createSagaMiddleware
  // function. instead .run(... ) must be called
  sagaMiddleware.run(sagas);

  return store;
}
