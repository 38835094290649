import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.serviceCallsInProgress, action) {
  switch(action.type) {
    case types.SERVICE_CALL_STARTED:
      return state + 1;
    case types.SERVICE_CALL_COMPLETE:
      return state - 1;
    default:
      return state;
  }
}
