import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import * as documentActions from '../../actions/documentActions';
import * as previousOrdersActions from '../../actions/mpOnlineActions';
import * as authProfileActions from '../../actions/authProfileActions';
import { ActionBar, ActionDatePicker, ActionButton } from '../common/action-bar';
import * as selectors from './../../selectors';
import Table  from './../../components/common/table';
import { PreviousOrdersRow } from './previous-orders-row';
import { MessageType, MessageLocation } from './../../common/utils/stringConstants';
import { filterItemsV2, getFormattedSearchDate } from './../../common/utils/generalFunctions';
import * as documentHelpers from '../../common/utils/documentHelpers';
import { ClearableSearchBar } from '../common/searchBoxWithClear';

const headers = [
  { header: "Web Order #", className: "setWidth200", name: "headerId" }, //width: "8%",    name: "headerId" },
  { header: "Order Date", className: "setWidth200", name: "orderDateDisplay" },//width: "8%",    name: "orderDateDisplay" },
  { header: "Delivery Date", className: "table-cell-center setWidth100", name: "deliveryDateDisplay" },//width: "7%",    name: "deliveryDateDisplay" },
  { header: "Total", className: "table-cell-center setWidth100 isCurrency", name: "total" },//width: "5%",    name: "total" },
  { header: "Lines", className: "table-cell-center setWidth80", name: "lineCount" },//width: "4%",    name: "lineCount" },
  { header: "Processed", className: "table-cell-center setWidth120", name: "isApproved" },//width: "4%",    name: "isProcessed" },
  { header: "CHR Ref. Number", className: "setWidth300", name: "customerReferenceCode" },//width: "10%",   name: "customerReferenceCode" },
  { header: "Vendor Number", className: "setWidth200", name: "storeNumber" },//width: "7%",    name: "storeNumber" },
  { header: "PO", className: "setWidth300", name: "purchaseOrderNumber" },//width: "10%",   name: "purchaseOrderNumber" },
  { header: "Notes", className: "width300", name: "orderNote" }//width: "10%",   name: "orderNote" }
];

export class PreviousOrders extends React.Component {
  static propTypes = {
    vendor: PropTypes.object,
    account: PropTypes.object,
    previousOrders: PropTypes.array,
    previousOrdersStartDate: PropTypes.object,
    previousOrdersEndDate: PropTypes.object,
    previousOrdersFilterText: PropTypes.string,
    previousOrdersFilteredOrders: PropTypes.array,
    previousOrdersPdf: PropTypes.string,
    orderDetailPdf: PropTypes.string,
    previousOrdersExcel: PropTypes.string,
    orderDetailExcel: PropTypes.string,
    documentActions: PropTypes.object,
    previousOrdersActions: PropTypes.object,
    authProfileActions: PropTypes.object,
    orderGuides: PropTypes.array,
    orderGuideItems: PropTypes.array,
    orderGuideActions: PropTypes.object,
    selectedOrderGuide: PropTypes.object,
    selectedDeliveryDate: PropTypes.string,
    reportDetailPdf: PropTypes.string,
    reportDetailExcel: PropTypes.string,
    setParentState: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      endDate: props.previousOrdersEndDate,
      startDate: props.previousOrdersStartDate,
      isModalVisible: false,
      filterText: (props.previousOrdersFilterText) ? props.previousOrdersFilterText : '',
      filteredOrders: (props.previousOrdersFilteredOrders && props.previousOrdersFilteredOrders.length > 0) ? props.previousOrdersFilteredOrders : props.previousOrders,
      deliveryDate: moment().format()
    };

    this.ClearTextField = this.ClearTextField.bind(this);
  }

  componentDidMount() {
    if (!this.props.account || !this.props.account.accountId) {
      if (this.props.authProfileActions) {
        this.props.authProfileActions.retrieveProfile();
      }
    }

    if (this.orderGuideHasBeenSelected()) {
      this.setDeliveryDate();
      const { orderGuideHeaderId } = this.props.selectedOrderGuide;
      this.props.orderGuideActions.getOrderGuideItems(orderGuideHeaderId);
    }

    this.searchPreviousOrders();
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account && nextProps.account.accountId) {
      this.props.orderGuideActions.orderGuide(nextProps.account.accountId);
    }

    if (nextProps.orderGuides !== this.props.orderGuides) {
      const defaultOrderGuide = this.getDefaultOrderGuide(nextProps.orderGuides);

      if (isUndefined(defaultOrderGuide)) {
        return;
      }

      let conceptId = undefined;
      if ((this.props.account && this.props.account.conceptId !== nextProps.account.conceptId) || nextProps.vendor) {
        if(!isUndefined(nextProps.account.conceptId)) {
          conceptId = nextProps.account.conceptId;
        }
      }

      this.props.orderGuideActions.getOrderGuideItems(defaultOrderGuide.orderGuideHeaderId);
      this.props.orderGuideActions.getVendor(defaultOrderGuide.vendorId, conceptId);
      this.props.orderGuideActions.setSelectedOrderGuide(defaultOrderGuide);
      this.props.orderGuideActions.setDeliveryDate(defaultOrderGuide.deliveryDate);

      this.setState({
        orderGuides: nextProps.orderGuides,
        selectedOption: defaultOrderGuide
      });
    }

    if (this.props.previousOrders !== nextProps.previousOrders) {
      this.setState({ filteredOrders: nextProps.previousOrders });
    }

    this.downloadPdf(nextProps);
    this.downloadDetailPdf(nextProps);
    this.downloadExcel(nextProps);
    this.downloadDetailExcel(nextProps);
  }

  filter = (event) => {
    const { previousOrders } = this.props;
    const inputValue = event.target.value.toLowerCase().trim();

    if (inputValue === "") {
      this.setState({
        filterText: event.target.value,
        filteredOrders: previousOrders
      });
      return;
    }

    const matchedItems = filterItemsV2(previousOrders, inputValue);

    this.setState({
      filterText: event.target.value,
      filteredOrders: matchedItems
    });

    this.props.setParentState({ previousOrdersFilterText: event.target.value });
    this.props.setParentState({ previousOrdersFilteredOrders: matchedItems });
  };

  setDate = (date, key) => {
    this.setState({ [key]: date });
  };

  searchPreviousOrders = () => {
    const { accountId } = this.props.account;
    const endDate = getFormattedSearchDate(this.state.endDate, "end"); //.format("MM-DD-YYYY");
    const startDate = getFormattedSearchDate(this.state.startDate, "start"); //.format("MM-DD-YYYY");

    if (moment(startDate) > moment(endDate)) {
      this.displayToastrMessage(MessageType.ERROR, 'Start date cannot be after end date');
      return;
    }

    this.props.previousOrdersActions.searchPreviousOrders(accountId, startDate, endDate);
  };

  displayToastrMessage = (type, text) => {
    const message = { type, text };
    this.props.previousOrdersActions.showToastrMessage(message);
  };

  retrievePdf = () => {
    const endDate = getFormattedSearchDate(this.state.endDate, "end"); //.format("MM-DD-YYYY");
    const startDate = getFormattedSearchDate(this.state.startDate, "start"); //.format("MM-DD-YYYY");
    this.props.documentActions.getPreviousOrdersPdf(this.props.account.accountId, startDate, endDate);
  };

  downloadPdf = (nextProps) => {
    if (nextProps.previousOrdersPdf !== this.props.previousOrdersPdf) {
      const pdf = nextProps.previousOrdersPdf;
      documentHelpers.download(pdf, 'PreviousOrders.pdf');
    }
  };

  retrieveDetail = (row, fileType) => {
    const { account, vendor } = this.props;

    switch (fileType) {
      case 'pdf':
        this.props.documentActions.getOrderDetailPdf(account.accountId, row.headerId);
        break;
      case 'excel':
        this.props.documentActions.getOrderDetailExcel(account.accountId, row.headerId);
        break;
      case 'pdfInvoice':
        this.props.documentActions.getReportDetailPdf(account, row.invoiceId);
        break;
      case 'excelInvoice':
        this.props.documentActions.getReportDetailExcel(account, row.invoiceId, vendor.vendorName);
        break;
      default:
        break;
    }
  };

  downloadDetailPdf = (nextProps) => {
    if (nextProps.orderDetailPdf !== this.props.orderDetailPdf) {
      const pdf = nextProps.orderDetailPdf;
      documentHelpers.download(pdf, 'OrderDetail.pdf');
    }
    if (nextProps.reportDetailPdf !== this.props.reportDetailPdf) {
      const pdf = nextProps.reportDetailPdf;
      documentHelpers.download(pdf, 'ReportDetail.pdf');
    }
  };

  retrieveExcel = (_row) => {
    const endDate = getFormattedSearchDate(this.state.endDate, "end"); //.format("MM-DD-YYYY");
    const startDate = getFormattedSearchDate(this.state.startDate, "start"); //.format("MM-DD-YYYY");
    this.props.documentActions.getPreviousOrdersExcel(this.props.account.accountId, startDate, endDate);
  };

  downloadExcel = (nextProps) => {
    if (nextProps.previousOrdersExcel !== this.props.previousOrdersExcel) {
      const excel = nextProps.previousOrdersExcel;
      documentHelpers.download(excel, 'PreviousOrders.xlsx');
    }
  };

  downloadDetailExcel = (nextProps) => {
    if (nextProps.orderDetailExcel !== this.props.orderDetailExcel) {
      const excel = nextProps.orderDetailExcel;
      documentHelpers.download(excel, 'OrderDetail.xlsx');
    }
    if (nextProps.reportDetailExcel !== this.props.reportDetailExcel) {
      const excel = nextProps.reportDetailExcel;
      documentHelpers.download(excel, 'ReportDetail.xlsx');
    }
  };

  ClearTextField = (e) => {
    if (e.currentTarget.onclick) {
      const { previousOrders } = this.props;
      this.setState({
        filterText: '',
        filteredOrders: previousOrders
      });

      this.props.setParentState({ previousOrdersFilterText: '' });
      this.props.setParentState({ previousOrdersFilteredOrders: [] });
    }
  };

  // Following (and related config/props/states) should get refactored from each guide page to a central location/call
  setDeliveryDate = () => {
    const { selectedDeliveryDate } = this.props;
    this.setState({ deliveryDate: moment(selectedDeliveryDate).format() });
  };

  orderGuideHasBeenSelected = () => {
    return !isEmpty(this.props.selectedOrderGuide);
  };

  getDefaultOrderGuide = (orderGuides) => {
    if (orderGuides) {
      const index = orderGuides.findIndex(x => x.isDefault);
      return index >= 0 ? orderGuides[index] : orderGuides[0];
    }
  };

  render() {
    const { account } = this.props;
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const { endDate, startDate, filteredOrders, filterText } = this.state;
    const cssClasses = "fa fa-2x vertical-align-middle";

    if (isEmpty(account)) {
      return (<Redirect to="/" />);
    }

    return (
      <div className="container-fluid">
        <ActionBar conceptColor={color}>
          <ClearableSearchBar
            filterText={filterText}
            multiFilter={this.filter}
            ClearTextField={this.ClearTextField} />
          <DatePicker id="dpStartDate"
            name="startDate"
            selected={moment.isMoment(startDate) ? startDate.toDate() : startDate}
            customInput={<ActionDatePicker text="Start Date" />}
            onChange={(date) => this.setDate(date, "startDate")} />

          <DatePicker id="dpEndDate"
            name="endDate"
            selected={moment.isMoment(endDate) ? endDate.toDate() : endDate}
            customInput={<ActionDatePicker text="End Date" />}
            onChange={(date) => this.setDate(date, "endDate")} />

          <ActionButton id="btnSearch"
            text="Load Previous Orders"
            className={`fa-search ${cssClasses}`}
            clickHandler={this.searchPreviousOrders} />

          <ActionButton id="btnExcel"
            text="Export Excel"
            className={`fa-file-excel-o ${cssClasses}`}
            clickHandler={this.retrieveExcel} />

          <ActionButton id="btnPdf"
            text="Export PDF"
            className={`fa-file-pdf-o ${cssClasses}`}
            clickHandler={this.retrievePdf} />
        </ActionBar>

        <Table id="tblPreviousOrders"
          headers={headers}
          items={filteredOrders}
          pageSize={10}
          RowComponent={PreviousOrdersRow}
          callback={this.retrieveDetail}
          wrapperClassName="tableWrapper"
          tableClassName="fixed_header"
          filterTypes={[MessageLocation.HeaderBanner]} />

      </div>
    );
  }
}

PreviousOrders.propTypes = {};

export const mapStateToProps = state => {
  return {
    vendor: selectors.getVendor(state),
    account: selectors.getAccount(state),
    previousOrders: selectors.getPreviousOrders(state),
    previousOrdersStartDate: selectors.getPreviousOrdersStartDate(state),
    previousOrdersEndDate: selectors.getPreviousOrdersEndDate(state),
    previousOrdersPdf: selectors.getPreviousOrdersPdf(state),
    orderDetailPdf: selectors.getOrderDetailPdf(state),
    previousOrdersExcel: selectors.getPreviousOrdersExcel(state),
    orderDetailExcel: selectors.getOrderDetailExcel(state),
    reportDetailPdf: selectors.getReportDetailPdf(state),
    reportDetailExcel: selectors.getReportDetailExcel(state),
    orderGuides: selectors.orderGuides(state),
    orderGuideItems: selectors.getSortedOrderGuide(state),
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    previousOrdersActions: bindActionCreators(previousOrdersActions, dispatch),
    orderGuideActions: bindActionCreators(previousOrdersActions, dispatch),
    authProfileActions: bindActionCreators(authProfileActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousOrders);
