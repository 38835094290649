import gatewayApi from '../apis/mpOnlineGatewayApi';
import {put, call, takeEvery} from 'redux-saga/effects';
import * as actions from '../../actions/documentActions';
import * as actionTypes from '../../actions/actionTypes';
import * as spinner from '../../actions/serviceCallActions';

export function* getOrderGuideItemsPdf(action) {
  yield put(spinner.startServiceCall());

  try {
    const pdf = yield call(gatewayApi.getOrderGuideItemsPdf, action.payload.orderGuideHeaderId, action.payload.accountId);
    yield put(actions.getOrderGuideItemsPdfComplete(pdf));
  } catch (e) {
    yield put(actions.getOrderGuideItemsPdfError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getEditableOrderGuideItemsPdf(action) {
  yield put(spinner.startServiceCall());

  try {
    const {orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId} = action.payload;
    const pdf = yield call(gatewayApi.getEditableOrderGuideItemsPdf, orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId);
    yield put(actions.getEditableOrderGuideItemsPdfComplete(pdf));
  } catch (e) {
    yield put(actions.getEditableOrderGuideItemsPdfError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getPreviousOrdersPdf(action) {
  yield put(spinner.startServiceCall());

  try {
    const pdf = yield call(gatewayApi.getPreviousOrdersPdf, action.payload.accountId, action.payload.startDate, action.payload.endDate);
    yield put(actions.getPreviousOrdersPdfComplete(pdf));
  } catch (e) {
    yield put(actions.getPreviousOrdersPdfError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getReportsPdf(action) {
  yield put(spinner.startServiceCall());

  try {
    const pdf = yield call(gatewayApi.getReportsPdf, action.payload.startDate, action.payload.endDate, action.payload.accountId);
    yield put(actions.getReportsPdfComplete(pdf));
  } catch (e) {
    yield put(actions.getReportsPdfError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getOrderDetailPdf(action) {
  yield put(spinner.startServiceCall());

  try {
    const {accountId, headerId} = action.payload;
    const pdf = yield call(gatewayApi.getOrderDetailPdf, accountId, headerId);
    yield put(actions.getOrderDetailPdfComplete(pdf));
  } catch (e) {
    yield put(actions.getOrderDetailPdfError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getReportDetailPdf(action) {
  yield put(spinner.startServiceCall());

  try {
    const {accountId, invoiceId} = action.payload;
    const pdf = yield call(gatewayApi.getReportDetailPdf, accountId, invoiceId);
    yield put(actions.getReportDetailPdfComplete(pdf));
  } catch (e) {
    yield put(actions.getReportDetailPdfError(e));
  }

  yield put(spinner.endServiceCall());
}

// EXCEL

export function* getOrderGuideItemsExcel(action) {
  yield put(spinner.startServiceCall());

  try {
    const file = yield call(gatewayApi.getOrderGuideItemsExcel, action.payload.orderGuideHeaderId, action.payload.accountId);
    yield put(actions.getOrderGuideItemsExcelComplete(file));
  } catch (e) {
    yield put(actions.getOrderGuideItemsExcelError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getEditableOrderGuideItemsExcel(action) {
  yield put(spinner.startServiceCall());

  try {
    const {orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId} = action.payload;
    const file = yield call(gatewayApi.getEditableOrderGuideItemsExcel, orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId);
    yield put(actions.getEditableOrderGuideItemsExcelComplete(file));
  } catch (e) {
    yield put(actions.getEditableOrderGuideItemsExcelError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getPreviousOrdersExcel(action) {
  yield put(spinner.startServiceCall());

  try {
    const file = yield call(gatewayApi.getPreviousOrdersExcel, action.payload.accountId, action.payload.startDate, action.payload.endDate);
    yield put(actions.getPreviousOrdersExcelComplete(file));
  } catch (e) {
    yield put(actions.getPreviousOrdersExcelError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getReportsExcel(action) {
  yield put(spinner.startServiceCall());

  try {
    const file = yield call(gatewayApi.getReportsExcel, action.payload.startDate, action.payload.endDate, action.payload.accountId);
    yield put(actions.getReportsExcelComplete(file));
  } catch (e) {
    yield put(actions.getReportsExcelError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getOrderDetailExcel(action) {
  yield put(spinner.startServiceCall());

  try {
    const {accountId, headerId} = action.payload;
    const file = yield call(gatewayApi.getOrderDetailExcel, accountId, headerId);
    yield put(actions.getOrderDetailExcelComplete(file));
  } catch (e) {
    yield put(actions.getOrderDetailExcelError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getReportDetailExcel(action) {
  yield put(spinner.startServiceCall());

  try {
    const {accountId, invoiceId, vendorName} = action.payload;
    const file = yield call(gatewayApi.getReportDetailExcel, accountId, invoiceId, vendorName);
    yield put(actions.getReportDetailExcelComplete(file));
  } catch (e) {
    yield put(actions.getReportDetailExcelError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* watchGetOrderGuideItemsPdf() {
  yield takeEvery(actionTypes.GET_ORDER_GUIDE_ITEMS_PDF_STARTED, getOrderGuideItemsPdf);
}

export function* watchGetEditableOrderGuideItemsPdf() {
  yield takeEvery(actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_STARTED, getEditableOrderGuideItemsPdf);
}

export function* watchGetPreviousOrdersPdf() {
  yield takeEvery(actionTypes.GET_PREVIOUS_ORDERS_PDF_STARTED, getPreviousOrdersPdf);
}

export function* watchGetReportsPdf() {
  yield takeEvery(actionTypes.GET_REPORTS_PDF_STARTED, getReportsPdf);
}

export function* watchGetOrderDetailPdf() {
  yield takeEvery(actionTypes.GET_ORDER_DETAIL_PDF_STARTED, getOrderDetailPdf);
}

export function* watchGetReportDetailPdf() {
  yield takeEvery(actionTypes.GET_REPORT_DETAIL_PDF_STARTED, getReportDetailPdf);
}

export function* watchGetOrderGuideItemsExcel() {
  yield takeEvery(actionTypes.GET_ORDER_GUIDE_ITEMS_EXCEL_STARTED, getOrderGuideItemsExcel);
}

export function* watchGetEditableOrderGuideItemsExcel() {
  yield takeEvery(actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_STARTED, getEditableOrderGuideItemsExcel);
}

export function* watchGetPreviousOrdersExcel() {
  yield takeEvery(actionTypes.GET_PREVIOUS_ORDERS_EXCEL_STARTED, getPreviousOrdersExcel);
}

export function* watchGetReportsExcel() {
  yield takeEvery(actionTypes.GET_REPORTS_EXCEL_STARTED, getReportsExcel);
}

export function* watchGetOrderDetailExcel() {
  yield takeEvery(actionTypes.GET_ORDER_DETAIL_EXCEL_STARTED, getOrderDetailExcel);
}

export function* watchGetReportDetailExcel() {
  yield takeEvery(actionTypes.GET_REPORT_DETAIL_EXCEL_STARTED, getReportDetailExcel);
}
