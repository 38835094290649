import * as actionTypes from './actionTypes';

// OrderGuideItems PDF

export function getOrderGuideItemsPdf(orderGuideHeaderId, accountId) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEMS_PDF_STARTED,
    payload: {
      orderGuideHeaderId,
      accountId
    }
  };
}

export function getOrderGuideItemsPdfComplete(pdf) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEMS_PDF_COMPLETE,
    payload: {
      pdf
    }
  };
}

export function getOrderGuideItemsPdfError(error) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEMS_PDF_ERROR,
    payload: {
      error
    }
  };
}

// EditableOrderGuideItems PDF

export function getEditableOrderGuideItemsPdf(orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId) {
  return {
    type: actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_STARTED,
    payload: {
      orderGuideHeaderId,
      deliverySchedule,
      onHandOnReceivedDays,
      accountId,
      vendorId
    }
  };
}

export function getEditableOrderGuideItemsPdfComplete(pdf) {
  return {
    type: actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_COMPLETE,
    payload: {
      pdf
    }
  };
}

export function getEditableOrderGuideItemsPdfError(error) {
  return {
    type: actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_ERROR,
    payload: {
      error
    }
  };
}

// PreviousOrders PDF

export function getPreviousOrdersPdf(accountId, startDate, endDate) {
  return {
    type: actionTypes.GET_PREVIOUS_ORDERS_PDF_STARTED,
    payload: {
      accountId,
      startDate,
      endDate
    }
  };
}

export function getPreviousOrdersPdfComplete(pdf) {
  return {
    type: actionTypes.GET_PREVIOUS_ORDERS_PDF_COMPLETE,
    payload: {
      pdf
    }
  };
}

export function getPreviousOrdersPdfError(error) {
  return {
    type: actionTypes.GET_PREVIOUS_ORDERS_PDF_ERROR,
    payload: {
      error
    }
  };
}

// Reports PDF

export function getReportsPdf(startDate, endDate, accountId) {
  return {
    type: actionTypes.GET_REPORTS_PDF_STARTED,
    payload: {
      startDate,
      endDate,
      accountId
    }
  };
}

export function getReportsPdfComplete(pdf) {
  return {
    type: actionTypes.GET_REPORTS_PDF_COMPLETE,
    payload: {
      pdf
    }
  };
}

export function getReportsPdfError(error) {
  return {
    type: actionTypes.GET_REPORTS_PDF_ERROR,
    payload: {
      error
    }
  };
}

// Previous Orders Detail PDF

export function getOrderDetailPdf(accountId, headerId) {
  return {
    type: actionTypes.GET_ORDER_DETAIL_PDF_STARTED,
    payload: {
      accountId,
      headerId
    }
  };
}

export function getOrderDetailPdfComplete(pdf) {
  return {
    type: actionTypes.GET_ORDER_DETAIL_PDF_COMPLETE,
    payload: {
      pdf
    }
  };
}

export function getOrderDetailPdfError(error) {
  return {
    type: actionTypes.GET_ORDER_DETAIL_PDF_ERROR,
    payload: {
      error
    }
  };
}

// Reports Detail PDF

export function getReportDetailPdf(accountId, invoiceId) {
  return {
    type: actionTypes.GET_REPORT_DETAIL_PDF_STARTED,
    payload: {
      accountId,
      invoiceId
    }
  };
}

export function getReportDetailPdfComplete(pdf) {
  return {
    type: actionTypes.GET_REPORT_DETAIL_PDF_COMPLETE,
    payload: {
      pdf
    }
  };
}

export function getReportDetailPdfError(error) {
  return {
    type: actionTypes.GET_REPORT_DETAIL_PDF_ERROR,
    payload: {
      error
    }
  };
}


//........... EXCEL ..............//

export function getOrderGuideItemsExcel(orderGuideHeaderId, accountId) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEMS_EXCEL_STARTED,
    payload: {
      orderGuideHeaderId,
      accountId
    }
  };
}

export function getOrderGuideItemsExcelComplete(file) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE,
    payload: {
      file
    }
  };
}

export function getOrderGuideItemsExcelError(error) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEMS_EXCEL_ERROR,
    payload: {
      error
    }
  };
}

export function getEditableOrderGuideItemsExcel(orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId) {
  return {
    type: actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_STARTED,
    payload: {
      orderGuideHeaderId,
      deliverySchedule,
      onHandOnReceivedDays,
      accountId, 
      vendorId
    }
  };
}

export function getEditableOrderGuideItemsExcelComplete(file) {
  return {
    type: actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE,
    payload: {
      file
    }
  };
}

export function getEditableOrderGuideItemsExcelError(error) {
  return {
    type: actionTypes.GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_ERROR,
    payload: {
      error
    }
  };
}

export function getPreviousOrdersExcel(accountId, startDate, endDate) {
  return {
    type: actionTypes.GET_PREVIOUS_ORDERS_EXCEL_STARTED,
    payload: {
      accountId,
      startDate,
      endDate
    }
  };
}

export function getPreviousOrdersExcelComplete(file) {
  return {
    type: actionTypes.GET_PREVIOUS_ORDERS_EXCEL_COMPLETE,
    payload: {
      file
    }
  };
}

export function getPreviousOrdersExcelError(error) {
  return {
    type: actionTypes.GET_PREVIOUS_ORDERS_EXCEL_ERROR,
    payload: {
      error
    }
  };
}

export function getReportsExcel(startDate, endDate, accountId) {
  return {
    type: actionTypes.GET_REPORTS_EXCEL_STARTED,
    payload: {
      startDate,
      endDate,
      accountId
    }
  };
}

export function getReportsExcelComplete(file) {
  return {
    type: actionTypes.GET_REPORTS_EXCEL_COMPLETE,
    payload: {
      file
    }
  };
}

export function getReportsExcelError(error) {
  return {
    type: actionTypes.GET_REPORTS_EXCEL_ERROR,
    payload: {
      error
    }
  };
}

export function getOrderDetailExcel(accountId, headerId) {
  return {
    type: actionTypes.GET_ORDER_DETAIL_EXCEL_STARTED,
    payload: {
      accountId,
      headerId
    }
  };
}

export function getOrderDetailExcelComplete(file) {
  return {
    type: actionTypes.GET_ORDER_DETAIL_EXCEL_COMPLETE,
    payload: {
      file
    }
  };
}

export function getOrderDetailExcelError(error) {
  return {
    type: actionTypes.GET_ORDER_DETAIL_EXCEL_ERROR,
    payload: {
      error
    }
  };
}

export function getReportDetailExcel(accountId, invoiceId, vendorName) {
  return {
    type: actionTypes.GET_REPORT_DETAIL_EXCEL_STARTED,
    payload: {
      accountId,
      invoiceId,
      vendorName
    }
  };
}

export function getReportDetailExcelComplete(file) {
  return {
    type: actionTypes.GET_REPORT_DETAIL_EXCEL_COMPLETE,
    payload: {
      file
    }
  };
}

export function getReportDetailExcelError(error) {
  return {
    type: actionTypes.GET_REPORT_DETAIL_EXCEL_ERROR,
    payload: {
      error
    }
  };
}
