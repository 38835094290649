import update from 'immutability-helper';
import initialState from './initialState';
import * as actions from '../actions/actionTypes';

export default function updatePdfs(state = initialState.pdf, action) {
  switch (action.type) {
    case actions.GET_ORDER_GUIDE_ITEMS_PDF_COMPLETE:
      return update(state, { orderGuideItems: { $set: action.payload.pdf }});
    case actions.GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_COMPLETE:
      return update(state, { editableOrderGuideItems: { $set: action.payload.pdf }});
    case actions.GET_PREVIOUS_ORDERS_PDF_COMPLETE:
      return update(state, { previousOrders: { $set: action.payload.pdf }});
    case actions.GET_REPORTS_PDF_COMPLETE:
      return update(state, { reports: { $set: action.payload.pdf }});
    case actions.GET_ORDER_DETAIL_PDF_COMPLETE:
      return update(state, { previousOrderDetail: { $set: action.payload.pdf }});
    case actions.GET_REPORT_DETAIL_PDF_COMPLETE:
      return update(state, { reportDetail: { $set: action.payload.pdf }});
    default:
      return state;
  }
}
