import React      from 'react';
import PropTypes  from 'prop-types';

const ForgotPassword = (props) => {
  return (
    <div className="full-screen grey-background">
      <div className="center30pct">
        <div className="rounded-top-border center-text">
          <h3>Forgot Password</h3>
        </div>

        <div id="forgot-credentials-form" className="center-text">
          <p>
            <br/>
            <br/>You may contact C.H. Robinson for help in restoring <br/> access to your account.
            <br/>
            <br/>Call us at: 888.837.3741
            <br/>- or -
            <br/>Email us at: MPSTeam@chrobinson.com
          </p>
        </div>

        <button className="send-email-button" onClick={() => props.auth.authorize()}>Login</button>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  auth: PropTypes.object
};

export default ForgotPassword;
