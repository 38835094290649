import {MP_GET_ORDER_GUIDE_ITEMS_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.orderGuideItems, action) {
  if (action.type === MP_GET_ORDER_GUIDE_ITEMS_COMPLETE) {
    return action.payload.orderGuideItems;
  } else {
    return state;
  }
}
