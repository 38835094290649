import React from 'react';
import PropTypes from 'prop-types';

export const ClearableSearchBar = ({ filterText, multiFilter, ClearTextField }) => {
  return (
    <div className="text-input-wrapper">
      <input  type="text"
                value={filterText}
                onChange={multiFilter}
                id="txtFilter"
                className="form-control filter-textbox"
                placeholder="Enter text to filter..." />
      <div
        className="text-input-clear"
        title="Clear"
        style={filterText === "" ? {visibility: "hidden"} : {visibility: "visible"}}
        onClick={ClearTextField}><i className="fa fa-times-circle fa-lg"/></div>
    </div>
  );
};

ClearableSearchBar.propTypes = {
  filterText: PropTypes.string.isRequired,
  multiFilter: PropTypes.func.isRequired,
  ClearTextField: PropTypes.func.isRequired
};
