import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// These two containers are siblings in the DOM
let modalRoot;

export default class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.element = document.createElement('div');
  }

  UNSAFE_componentWillMount() {
    modalRoot = document.getElementById('modal-root');
  }

  componentDidMount() {
    modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.element
    );
  }
}
