export const deleteItemWarningMessage = 'Are you sure you want to remove these items?';

export const poSubmitPastCutOffErrorMessage = "The order you are attempting to submit is past the vendor's cut off time.";

export const unidentifiedSystemError = 'An unidentified system error has occurred. Please contact MP Support for help in identifying the issue.';

export const dayNotInDeliveryRoute = "The Requested Arrival Day is not a valid delivery day for the selected order guide's configured route. Please verify that a correct delivery day is selected and re-submit the order.";

export const orderExceedsMaximumQuantity = "Items in the order exceed maximum purchase quantity for this delivery date";

export const belowMinimumQuantityWarningMessage = (minimumOrderQuantity) => {
  return `A minimum order quantity of ${minimumOrderQuantity} is required.`;
};
