import {put, call, takeEvery} from 'redux-saga/effects';
import Api from '../apis/authProfileApi';
import * as spinner from '../../actions/serviceCallActions';

import {
  AUTH_PROFILE_RETRIEVAL_STARTED
} from '../../actions/actionTypes';

import {
  retrieveProfileComplete,
  retrieveProfileError
} from '../../actions/authProfileActions';

export function* retrieveProfileStart(action) {
  yield put(spinner.startServiceCall());
  try {
    const profile = yield call(Api.getAuthProfile, action.payload);
    if (profile.relatedConceptIds){
      profile['relatedConceptIds'] = profile.relatedConceptIds.split(',').map(Number).filter(x => (!isNaN(x) && x > 0));
    }
    if (profile.relatedAccountIds){
      profile['relatedAccountIds'] = profile.relatedAccountIds.split(',').map(Number).filter(x => (!isNaN(x) && x > 0));
    }
    if (profile.relatedConceptOptions){
      profile['relatedConceptOptions'] = JSON.parse(profile.relatedConceptOptions);
    }
    yield put(retrieveProfileComplete(profile));

  } catch (e) {
    yield put(retrieveProfileError(e));
  }
  yield put(spinner.endServiceCall());
}

export function* watchAuthProfileRetrieval() {
  yield takeEvery(AUTH_PROFILE_RETRIEVAL_STARTED, retrieveProfileStart);
}
