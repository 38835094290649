import React      from 'react';
import PropTypes  from 'prop-types';
import OktaSignIn from '@okta/okta-signin-widget';

export class Login extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  };

  componentDidMount() {

    if (!this.props.auth.isAuthenticated()) {
      const authConfig = this.props.auth.authConfig;
      authConfig.el = "#widget-container";

      this.widget = new OktaSignIn(authConfig);

      const tokens = this.widget.showSignInToGetTokens(authConfig);
      tokens.then((tokens) => {
        if (tokens) {
            // possibly use tokenManager in future instead of session tokens
            // would require some refactoring of the gateway calls and possibly of the authservice
            this.props.auth.setSession(tokens);
            window.location.hash = '/';
          }
      });
    }
  }

  componentWillUnmount() {
    if (this.widget){
      this.widget.remove();
    }
  }



  render() {
    return (
      <div>
        <link href="https://global.oktacdn.com/okta-signin-widget/7.14.0/css/okta-sign-in.min.css" type="text/css" rel="stylesheet"/>
        <div id="widget-container"/>
      </div>
    );
  }
}

export default Login;
