import {
  AUTH_PROFILE_RETRIEVAL_STARTED,
  AUTH_PROFILE_RETRIEVAL_COMPLETE,
  AUTH_PROFILE_RETRIEVAL_ERROR
} from './actionTypes';

export function retrieveProfile(accountId) {
  return {
    type: AUTH_PROFILE_RETRIEVAL_STARTED,
    payload: {
      accountId: accountId
    }
  };
}

export function retrieveProfileComplete(profile) {
  return {
    type: AUTH_PROFILE_RETRIEVAL_COMPLETE,
    payload: {
      profile
    }
  };
}

export function retrieveProfileError(error) {
  return {
    type: AUTH_PROFILE_RETRIEVAL_ERROR,
    payload: {
      error
    }
  };
}
