import React        from "react";
import PropTypes    from "prop-types";
import { connect }  from "react-redux";

export class Callback extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.props.auth.crossOriginVerification();
  }

  render() {
    return (
      <div/>
    );
  }
}

export default connect()(Callback);
