import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { Modal }              from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { orderGuides }        from '../../selectors';
import * as actions           from '../../actions/mpOnlineActions';
import * as generalFunctions from "../../common/utils/generalFunctions";

export class DefaultOrderGuideModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    actions: PropTypes.object,
    orderGuides: PropTypes.array.isRequired,
    closeButtonHandler: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      orderGuides: this.props.orderGuides
    };
  }

  onCheckboxChange = (orderGuide) => {
    const {orderGuides} = this.state;
    const updatedOrderGuides = JSON.parse(JSON.stringify(orderGuides));

    updatedOrderGuides.forEach(guide => {
      // This is to make it behave like a radio button
      guide.isDefault = guide.orderGuideHeaderId === orderGuide.orderGuideHeaderId ? true : false;
    });

    this.setState({ orderGuides: updatedOrderGuides });
  };

  setDefaultOrderGuide = () => {
    const {orderGuides} = this.state;
    this.props.actions.setDefaultOrderGuide(orderGuides);
  };

  render() {
    const {orderGuides} = this.state;
    const {closeButtonHandler} = this.props;

    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>Change Order Guide Defaults</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table className="table">
              <thead>
                <tr >
                  <td><b>Order Guide</b></td>
                  <td><b>Is Default</b></td>
                  <td><b>Created Date</b></td>
                </tr>
              </thead>
              <tbody>
                {
                  orderGuides.map((guide, index) => {
                    return (
                    <tr key={index} className="pointer" onClick={() => this.onCheckboxChange(guide)}>
                      <td>{guide.guideName}</td>
                      <td>
                        <div className={`checkmark-circle ${ guide.isDefault ? 'checked' : ''}`}>
                          <div className="checkmark" />
                        </div>
                      </td>
                      <td>{generalFunctions.getFormattedDisplayDate(guide.createdDateDisplay)}</td>
                    </tr>
                    );
                  })
                }
              </tbody>
            </table>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" style={{width: '15%'}} onClick={this.setDefaultOrderGuide}>Save</button>
          <button className="btn btn-primary" style={{width: '15%'}} onClick={closeButtonHandler}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const mapStateToProps = state => {
  return {
    orderGuides: orderGuides(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultOrderGuideModal);
