import React from "react";
import PropTypes from "prop-types";
import * as generalFunctions from "../../common/utils/generalFunctions";

export const OrderReportRow = (props) => {
  const row = props.data;
  const {callback} = props;
  const isVoid = row.isVoid;
  const allowLink = (!row.customerReferenceCode || !row.isProcessed );
  const cellClass = {};
  let i = 0;
  if (props.headers) {
    for(i = 0; i < props.headers.length; i++){
      cellClass[i]={className: props.headers[i].className};
    }
  }

  return (
    <tr>
      <td {...cellClass[0]}>{row.storeNumber}</td>
      <td {...cellClass[1]}>
        {row.customerReferenceCode} &nbsp;
        {allowLink || isVoid ?
        ("") :
        (
         <a onClick={() => callback(row, 'pdf')}>PDF</a>
        )}
      </td>
      <td {...cellClass[2]}>{row.invoiceNumber}</td>
      <td {...cellClass[3]}>{generalFunctions.getFormattedDisplayDate(row.deliveryDate, false, true)}</td>
      <td {...cellClass[4]}>{row.isProcessed ? row.amount.toFixed(2):'pending'}</td>
      <td {...cellClass[5]}>
        <div className={`checkmark-circle ${row.isProcessed && !isVoid ? 'checked' : ''}`} >
          <div className={row.isProcessed && !isVoid ? "checkmark" : ""} />
        </div>
      </td>
      <td {...cellClass[6]}>{generalFunctions.getFormattedDisplayDate(row.processedDateDisplay)}</td>
      <td {...cellClass[7]}>
        <div className={`checkmark-circle ${row.isEdi810Sent ? 'checked' : ''}`} >
          <div className={row.isEdi810Sent ? "checkmark" : ""} />
        </div>
      </td>
      <td {...cellClass[8]}>{generalFunctions.getFormattedDisplayDate(row.edi810SentDate)}</td>
      <td {...cellClass[9]}>
        <div className={`checkmark-circle ${row.hasError ? 'checked' : ''}`} >
          <div className={row.hasError ? "checkmark" : ""} />
        </div>
      </td>
      <td {...cellClass[10]}>
        <div className={`checkmark-circle ${row.isVoid ? 'checked' : ''}`} >
          <div className={row.isVoid ? "checkmark" : ""} />
        </div>
      </td>
      <td {...cellClass[11]}>{generalFunctions.getFormattedDisplayDate(row.createdDate)}</td>
    </tr>
  );
};

OrderReportRow.propTypes = {
  data: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  headers: PropTypes.array
};
