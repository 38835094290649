import {routerReducer} from 'react-router-redux';
import {combineReducers} from 'redux';

import token from './tokenReducer';
import authProfile from './authProfileReducer';
import serviceCallsInProgress from './serviceCallReducer';
import orderGuides from './orderGuideReducer';
import orderGuideItems from './orderGuideItemReducer';
import vendor from './vendorReducer';
import toastrMessage from './toastrMessageReducer';
import concept from './conceptReducer';
import conceptAttributes from './conceptAttributesReducer';
import shoppingCart from "./shoppingCartReducer";
import elementPresentation from "./elementPresentationReducer";
import previousOrders from "./previousOrdersReducer";
import previousOrdersStartDate from "./previousOrdersStartDateReducer";
import previousOrdersEndDate from "./previousOrdersEndDateReducer";
import purchaseOrder from "./purchaseOrderReducer";
import reports from "./reportsReducer";
import reportsStartDate from "./reportsStartDateReducer";
import reportsEndDate from "./reportsEndDateReducer";
import vendorItems from "./vendorItemsReducer";
import editableOrderGuideItems from "./editableOrderGuideItemsReducer";
import pdf from './pdfReducer';
import excel from './excelReducer';
import vendorLocation from './vendorLocationReducer';
import userCredentials from './userCredentialsReducer';
import conceptVendorCode from './conceptVendorCodeReducer';
import relatedAccounts from './accountReducer';
import accountId from './accountIdReducer';
import messages from './messagesReducer';
import maxPurchaseQuantityDetails from './orderGuideItemMaxPurchaseQuantityReducer';

const rootReducer = combineReducers({
  token,
  authProfile,
  serviceCallsInProgress,
  routing: routerReducer,
  orderGuides,
  orderGuideItems,
  vendor,
  toastrMessage,
  concept,
  conceptAttributes,
  shoppingCart,
  elementPresentation,
  previousOrders,
  previousOrdersStartDate,
  previousOrdersEndDate,
  purchaseOrder,
  reports,
  reportsStartDate,
  reportsEndDate,
  vendorItems,
  editableOrderGuideItems,
  pdf,
  excel,
  vendorLocation,
  userCredentials,
  conceptVendorCode,
  relatedAccounts,
  accountId,
  messages,
  maxPurchaseQuantityDetails
});

export default rootReducer;
