import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from 'lodash';

export class Pagination extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
  };

  static defaultProps = {
    initialPage: 1
  };

  state = {
    pager: {}
  };

  UNSAFE_componentWillMount() {
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.items) !== JSON.stringify(prevProps.items)) {
      this.setPage(this.state.pager.currentPage);
    }
  }

  setPage = (page) => {
    let items = this.props.items;
    let pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items.length, page);

    // get new page of items from items array
    let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(pageOfItems, pager);
  };

  getPager = (totalItems, currentPage) => {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    const pageSize = this.props.pageSize || 10;

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  };

  render() {
    const { pager } = this.state;

    if (!pager.pages || pager.pages.length < 1) {
      return null;
    }

    return (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className={pager.currentPage === 1 ? "disabled" : ""}>
            <a role="button" style={{userSelect: 'none'}} aria-label="Previous" id="previous" onClick={() => this.setPage(1)}>
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {pager.pages && pager.pages.map((page, index) => (
            <li
              key={index}
              className={pager.currentPage === page ? "active" : ""}
            >
              <a role="button" style={{userSelect: 'none'}} id={"pageNumber" + page} onClick={() => this.setPage(page)}>{page}</a>
            </li>
          ))}
          <li
            className={pager.currentPage === pager.totalPages ? "disabled" : ""}
          >
            <a role="button" style={{userSelect: 'none'}}  aria-label="Next" id="next" onClick={() => this.setPage(pager.totalPages)}>
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
