import React from "react";
import PropTypes from "prop-types";

function getTotalAmount(rows) {
  let totalAmount = 0;

  rows.forEach(row => {
    totalAmount += (row.isProcessed ? row.amount : 0);
  });

  return totalAmount.toFixed(2);
}

export const TotalRow = (props) => {

  const totalAmount = getTotalAmount(props.rows);
  const cellClass = {};
  let i = 0;
  if (props.headers) {
    for(i = 0; i < props.headers.length; i++){
      cellClass[i]={className: props.headers[i].className};
    }
  }

  return (
    <tr>
      <td {...cellClass[0]}/>
      <td {...cellClass[1]}/>
      <td {...cellClass[2]}/>
      <td {...cellClass[3]}/>
      <td {...cellClass[4]}>{totalAmount}</td>
      <td {...cellClass[5]}/>
      <td {...cellClass[6]}/>
      <td {...cellClass[7]}/>
      <td {...cellClass[8]}/>
      <td {...cellClass[9]}/>
      <td {...cellClass[10]}/>
      <td {...cellClass[11]}/>
    </tr>
  );
};

TotalRow.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array
};
