import React        from "react";
import PropTypes    from "prop-types";
import { connect }  from "react-redux";
import errorImage from "./../../assets/images-60/error.png";

export class PageNotFound extends React.Component {
  static propTypes = {
    history: PropTypes.object
  };

  render() {
    return (
      <div className="col-md-12 text-center">

        {/* SOME EMPTY SPACE */}
        <br/><br/><br/><br/><br/>

        <h1>404</h1>
        <img alt="Error" src={errorImage} height="100px" width="100px" />
        <h3>The requested page was not found!</h3>

        <br/>

        <a onClick={() => this.props.history.push('/')}>Return to Home</a>
      </div>
    );
  }
}

export default connect()(PageNotFound);
