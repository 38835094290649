import {put, call, takeEvery} from 'redux-saga/effects';
import * as spinner from '../../actions/serviceCallActions';
import GatewayApi from '../apis/mpOnlineGatewayApi';

import * as actionTypes from '../../actions/actionTypes';
import * as actions from '../../actions/mpOnlineActions';

export function* getOrderGuideHeader(action) {
  yield put(spinner.startServiceCall());

  try {
    const orderGuide = yield call(GatewayApi.readOrderGuideHeader, action.payload.accountId);
    yield put(actions.orderGuideComplete(orderGuide));

  } catch (e) {
    yield put(actions.orderGuideError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getOrderGuideItems(action) {
  yield put(spinner.startServiceCall());

  try {
    const orderGuideItems = yield call(GatewayApi.getOrderGuideItemsById, action.payload.orderGuideHeaderId, action.payload.requestedDeliveryDate, action.payload.requestedShipDate);
    yield put(actions.orderGuideItemsComplete(orderGuideItems));
  } catch(e) {
    yield put(actions.orderGuideItemsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getVendor(action) {
  yield put(spinner.startServiceCall());

  try {
    const vendor = yield call(GatewayApi.getVendor, action.payload.vendorId, action.payload.conceptId);
    yield put(actions.getVendorComplete(vendor));
  } catch(e) {
    yield put(actions.getVendorError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getConcept(action) {
  yield put(spinner.startServiceCall());

  try {
    const concept = yield call(GatewayApi.getConcept, action.payload.username);
    yield put(actions.getConceptComplete(concept));
  } catch(e) {
    yield put(actions.getConceptError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* createPurchaseOrder(action) {
  yield put(spinner.startServiceCall());

  try {
    const headerId = yield call(GatewayApi.createPurchaseOrder, action.payload.purchaseOrder);
    yield put(actions.createPurchaseOrderComplete(headerId));
  } catch(e) {
    yield put(actions.createPurchaseOrderError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* searchPreviousOrders(action) {
  yield put(spinner.startServiceCall());

  try {
    const {accountId, startDate, endDate} = action.payload;
    const previousOrders = yield call(GatewayApi.readPreviousOrders, accountId, startDate, endDate);
    yield put(actions.searchPreviousOrdersComplete(previousOrders, accountId, startDate, endDate));
  } catch(e) {
    yield put(actions.searchPreviousOrdersError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* searchReports(action) {
  yield put(spinner.startServiceCall());

  try {
    const {accountId, startDate, endDate} = action.payload;
    const reports = yield call(GatewayApi.readReports, accountId, startDate, endDate);
    yield put(actions.searchReportsComplete(reports, accountId, startDate, endDate));
  } catch(e) {
    yield put(actions.searchReportsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getVendorItems(action) {
  yield put(spinner.startServiceCall());

  try {
    const {orderGuideHeaderId, conceptId, vendorId} = action.payload;
    const vendorItems = yield call(GatewayApi.readVendorItems, orderGuideHeaderId, conceptId, vendorId);
    yield put(actions.getVendorItemsComplete(vendorItems));
  } catch(e) {
    yield put(actions.getVendorItemsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* addSelectedItems(action) {
  yield put(spinner.startServiceCall());

  try {
    const {selectedItems, headerId} = action.payload;
    yield call(GatewayApi.createOrderGuideItems, selectedItems);
    yield getOrderGuideItems({payload: {orderGuideHeaderId: headerId}});
  } catch(e) {
    yield put(actions.addSelectedItemsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getEditableOrderGuideItems(action) {
  yield put(spinner.startServiceCall());

  try {
    const orderGuideItems = yield call(GatewayApi.readEditableOrderGuideItems, action.payload.orderGuideHeaderId, action.payload.requestedDeliveryDate, action.payload.requestedShipDate);
    yield put(actions.getEditableOrderGuideItemsComplete(orderGuideItems));
  } catch(e) {
    yield put(actions.getEditableOrderGuideItemsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* updateOrderGuideItems(action) {
  yield put(spinner.startServiceCall());

  try {
    const {headerId} = action.payload;
    yield call(GatewayApi.updateOrderGuideItems, action.payload.orderGuideItems);
    yield getEditableOrderGuideItems({payload: {orderGuideHeaderId: headerId}});
  } catch(e) {
    yield put(actions.updateOrderGuideItemsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* updateOrderGuideHeaders(action) {
  yield put(spinner.startServiceCall());

  try {
    yield call(GatewayApi.updateOrderGuideHeaders, action.payload.orderGuideHeaders);
  } catch(e) {
    yield put(actions.updateOrderGuideItemsError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getConceptAttributes(action) {
  yield put(spinner.startServiceCall());

  try {
    const conceptAttributes = yield call(GatewayApi.readConceptAttributes, action.payload.conceptId, action.payload.accountId);
    yield put(actions.getConceptAttributesComplete(conceptAttributes));
  } catch(e) {
    yield put(actions.getConceptAttributesError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* deleteSelectedItems(action) {
  yield put(spinner.startServiceCall());

  try {
    yield call(GatewayApi.deleteSelectedItems, action.payload.orderGuideItems); //.itemIds);
    yield getEditableOrderGuideItems({payload: {orderGuideHeaderId: action.payload.headerId}});
  } catch(e) {
    yield put(actions.getConceptAttributesError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getVendorLocation(action) {
  yield put(spinner.startServiceCall());

  try{
    const vendorLocation = yield call(GatewayApi.getVendorLocation, action.payload.warehouseCode, action.payload.accountId);
    yield put(actions.getVendorLocationComplete(vendorLocation));
  } catch(e) {
      yield put(actions.getVendorLocationError(e));
  }
  yield put(spinner.endServiceCall());
}

export function* getConceptVendorCode(action) {
  yield put(spinner.startServiceCall());

  try {
    const conceptVendorCode = yield call(GatewayApi.getConceptVendorCode, action.payload.conceptId, action.payload.vendorId);
    yield put(actions.getConceptVendorCodeComplete(conceptVendorCode));
  } catch(e) {
    yield put(actions.getConceptVendorCodeError(e));
  }
  yield put(spinner.endServiceCall());
}

export function* getAccountIdsAndNames(action) {
  yield put(spinner.startServiceCall());

  try {
    const {conceptId, conceptCode, relatedAccountIds} = action.payload;
    const accounts = yield call(GatewayApi.getAccountIdsAndNames, conceptId, conceptCode);

    yield put(actions.getAccountIdsAndNamesComplete(accounts, relatedAccountIds));
  } catch (e) {
    yield put(actions.getAccountIdsAndNamesError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getActiveMessages(action) {
  yield put(spinner.startServiceCall());

  try {
    const messages = yield call(GatewayApi.readActiveMessages, action.payload.vendorId, action.payload.accountId, action.payload.messageDate);
    yield put(actions.getActiveMessagesComplete(messages));
  } catch(e) {
    yield put(actions.getActiveMessagesError(e));
  }

  yield put(spinner.endServiceCall());
}

export function* getOrderGuideItemMaxPurchaseQuantity(action) {
  const itemNo = action.payload.orderGuideItem !== null ? action.payload.orderGuideItem.itemNumber : null;
  try {
    const response = yield call(GatewayApi.readOrderGuideItemMaxQuantity, action.payload.accountId, action.payload.vendorId, action.payload.deliveryDate, action.payload.orderGuideItem);
    yield put(actions.getOrderGuideItemMaxPurchaseQuantityComplete(itemNo, response.maxPurchaseQuantity, response.message));
  } catch(e) {
    yield put(actions.getOrderGuideItemMaxPurchaseQuantityError(e));
  }
}

export function* watchGetOrderGuideHeader() {
  yield takeEvery(actionTypes.MP_GET_ORDER_GUIDE_HEADER_STARTED, getOrderGuideHeader);
}

export function* watchGetOrderGuideItems() {
  yield takeEvery(actionTypes.MP_GET_ORDER_GUIDE_ITEMS_STARTED, getOrderGuideItems);
}

export function* watchGetVendor() {
  yield takeEvery(actionTypes.MP_GET_VENDOR_STARTED, getVendor);
}

export function* watchGetConcept() {
  yield takeEvery(actionTypes.MP_GET_CONCEPT_STARTED, getConcept);
}

export function* watchCreatePurchaseOrder() {
  yield takeEvery(actionTypes.MP_CREATE_PURCHASE_ORDER_STARTED, createPurchaseOrder);
}

export function* watchSearchPreviousOrders() {
  yield takeEvery(actionTypes.MP_SEARCH_PREVIOUS_ORDERS_STARTED, searchPreviousOrders);
}

export function* watchSearchReports() {
  yield takeEvery(actionTypes.MP_SEARCH_REPORTS_STARTED, searchReports);
}

export function* watchGetVendorItems() {
  yield takeEvery(actionTypes.MP_GET_VENDOR_ITEMS_STARTED, getVendorItems);
}

export function* watchAddSelectedItems() {
  yield takeEvery(actionTypes.MP_ADD_SELECTED_ITEMS_STARTED, addSelectedItems);
}

export function* watchGetEditableOrderGuideItems() {
  yield takeEvery(actionTypes.MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_STARTED, getEditableOrderGuideItems);
}

export function* watchUpdateOrderGuideItems() {
  yield takeEvery(actionTypes.MP_UPDATE_ORDER_GUIDE_ITEMS_STARTED, updateOrderGuideItems);
}

export function* watchSetDefaultOrderGuide() {
  yield takeEvery(actionTypes.MP_SET_DEFAULT_ORDER_GUIDE_STARTED, updateOrderGuideHeaders);
}

export function* watchGetConceptAttributes() {
  yield takeEvery(actionTypes.GET_CONCEPT_ATTRIBUTES_STARTED, getConceptAttributes);
}

export function* watchDeleteSelectedItems() {
  yield takeEvery(actionTypes.DELETE_SELECTED_ITEMS_STARTED, deleteSelectedItems);
}

export function* watchGetVendorLocation() {
  yield takeEvery(actionTypes.GET_VENDORLOCATION_STARTED, getVendorLocation);
}

export function* watchGetConceptVendorCode() {
  yield takeEvery(actionTypes.GET_CONCEPTVENDORCODE_STARTED, getConceptVendorCode);
}

export function* watchGetAccountIdsAndNames() {
  yield takeEvery(actionTypes.GET_ACCOUNTIDS_NAMES_STARTED, getAccountIdsAndNames);
}

export function* watchGetActiveMessages() {
  yield takeEvery(actionTypes.GET_ACTIVE_MESSAGES_STARTED, getActiveMessages);
}

export function* watchGetOrderGuideItemMaxPurchaseQuantity() {
  yield takeEvery(actionTypes.GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_STARTED, getOrderGuideItemMaxPurchaseQuantity);
}
