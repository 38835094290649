import React from "react";
import PropTypes from "prop-types";

export const ActionButton = ({ className, text, id, clickHandler }) => {
  return (
    <a role="button" id={id} onClick={clickHandler}>
      <span className={className} />
      <span className="vertical-align-middle pad-left-5">{text}</span>
    </a>
  );
};

ActionButton.propTypes = {
  clickHandler: PropTypes.func,
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string
};
