import * as actionTypes from './actionTypes';

export function getOrderGuideItems(orderGuideHeaderId, requestedDeliveryDate, requestedShipDate) {
  return {
    type: actionTypes.MP_GET_ORDER_GUIDE_ITEMS_STARTED,
    payload: {
      orderGuideHeaderId,
      requestedDeliveryDate,
      requestedShipDate
    }
  };
}

export function orderGuide(accountId) {
  return {
    type: actionTypes.MP_GET_ORDER_GUIDE_HEADER_STARTED,
    payload: {
      accountId
    }
  };
}

export function orderGuideComplete(orderGuide) {
  return {
    type: actionTypes.MP_GET_ORDER_GUIDE_HEADER_COMPLETE,
    payload: {
      orderGuide
    }
  };
}

export function orderGuideError(error) {
  return {
    type: actionTypes.MP_GET_ORDER_GUIDE_HEADER_ERROR,
    payload: {
      error
    }
  };
}

export function setSelectedOrderGuide(selectedOrderGuide) {
  return {
    type: actionTypes.SET_SELECTED_ORDER_GUIDE,
    payload: {
      selectedOrderGuide
    }
  };
}

export function orderGuideItemsComplete(orderGuideItems) {
  return {
    type: actionTypes.MP_GET_ORDER_GUIDE_ITEMS_COMPLETE,
    payload: {
      orderGuideItems
    }
  };
}

export function orderGuideItemsError(error) {
  return {
    type: actionTypes.MP_GET_ORDER_GUIDE_ITEMS_ERROR,
    payload: {
      error
    }
  };
}

// Vendor Actions //

export function getVendor(vendorId, conceptId) {
  return {
    type: actionTypes.MP_GET_VENDOR_STARTED,
    payload: {
      vendorId,
      conceptId
    }
  };
}

export function getVendorComplete(vendor) {
  return {
    type: actionTypes.MP_GET_VENDOR_COMPLETE,
    payload: {
      vendor
    }
  };
}

export function getVendorError(error) {
  return {
    type: actionTypes.MP_GET_VENDOR_ERROR,
    payload: {
      error
    }
  };
}

export function showToastrMessage(message) {
  return {
    type: actionTypes.MP_SHOW_TOASTR_MESSAGE,
    payload: {
      message
    }
  };
}

// CONCEPT ACTIONS //

export function getConcept(username) {
  return {
    type: actionTypes.MP_GET_CONCEPT_STARTED,
    payload: {
      username
    }
  };
}

export function getConceptComplete(concept) {
  return {
    type: actionTypes.MP_GET_CONCEPT_COMPLETE,
    payload: {
      concept
    }
  };
}

export function getConceptError(error) {
  return {
    type: actionTypes.MP_GET_CONCEPT_ERROR,
    payload: {
      error
    }
  };
}

// SHOPPING CART ACTIONS //

export function updateShoppingCart(item) {
  return {
    type: actionTypes.MP_UPDATE_SHOPPING_CART,
    payload: {
      item
    }
  };
}

export function addToShoppingCart(item) {
  return {
    type: actionTypes.MP_ADD_TO_SHOPPING_CART,
    payload: {
      item
    }
  };
}

export function removeFromShoppingCart(item) {
  return {
    type: actionTypes.MP_REMOVE_FROM_SHOPPING_CART,
    payload: {
      item
    }
  };
}

export function clearShoppingCart() {
  return {
    type: actionTypes.MP_CLEAR_SHOPPING_CART
  };
}

export function togglePreviewOrderButtonVisibility(isVisible) {
  return {
    type: actionTypes.MP_TOGGLE_PREVIEW_ORDER_BUTTON_VISIBILITY,
    payload: {
      isVisible
    }
  };
}

export function createPurchaseOrder(purchaseOrder) {
  return {
    type: actionTypes.MP_CREATE_PURCHASE_ORDER_STARTED,
    payload: {
      purchaseOrder
    }
  };
}

export function createPurchaseOrderComplete(headerId) {
  return {
    type: actionTypes.MP_CREATE_PURCHASE_ORDER_COMPLETE,
    payload: {
      headerId
    }
  };
}

export function createPurchaseOrderError(error) {
  return {
    type: actionTypes.MP_CREATE_PURCHASE_ORDER_ERROR,
    payload: {
      error
    }
  };
}

export function searchPreviousOrders(accountId, startDate, endDate) {
  return {
    type: actionTypes.MP_SEARCH_PREVIOUS_ORDERS_STARTED,
    payload: {
      accountId,
      startDate,
      endDate
    }
  };
}

export function searchPreviousOrdersComplete(previousOrders, accountId, startDate, endDate) {
  return {
    type: actionTypes.MP_SEARCH_PREVIOUS_ORDERS_COMPLETE,
    payload: {
      previousOrders,
      accountId,
      startDate,
      endDate
    }
  };
}

export function searchPreviousOrdersError(error) {
  return {
    type: actionTypes.MP_SEARCH_PREVIOUS_ORDERS_ERROR,
    payload: {
      error
    }
  };
}

export function searchReports(accountId, startDate, endDate) {
  return {
    type: actionTypes.MP_SEARCH_REPORTS_STARTED,
    payload: {
      accountId,
      startDate,
      endDate
    }
  };
}

export function searchReportsComplete(reports, accountId, startDate, endDate) {
  return {
    type: actionTypes.MP_SEARCH_REPORTS_COMPLETE,
    payload: {
      reports,
      accountId,
      startDate,
      endDate
    }
  };
}

export function searchReportsError(error) {
  return {
    type: actionTypes.MP_SEARCH_REPORTS_ERROR,
    payload: {
      error
    }
  };
}

export function getVendorItems(orderGuideHeaderId, conceptId, vendorId) {
  return {
    type: actionTypes.MP_GET_VENDOR_ITEMS_STARTED,
    payload: {
      orderGuideHeaderId,
      conceptId,
      vendorId
    }
  };
}

export function getVendorItemsComplete(vendorItems) {
  return {
    type: actionTypes.MP_GET_VENDOR_ITEMS_COMPLETE,
    payload: {
      vendorItems
    }
  };
}

export function getVendorItemsError(error) {
  return {
    type: actionTypes.MP_GET_VENDOR_ITEMS_ERROR,
    payload: {
      error
    }
  };
}

export function addSelectedItems(selectedItems, headerId) {
  return {
    type: actionTypes.MP_ADD_SELECTED_ITEMS_STARTED,
    payload: {
      selectedItems,
      headerId
    }
  };
}

export function addSelectedItemsError(error) {
  return {
    type: actionTypes.MP_ADD_SELECTED_ITEMS_ERROR,
    payload: {
      error
    }
  };
}

// GET [EDIT] ORDER GUIDE ITEMS

export function getEditableOrderGuideItems(orderGuideHeaderId, requestedDeliveryDate, requestedShipDate) {
  return {
    type: actionTypes.MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_STARTED,
    payload: {
      orderGuideHeaderId,
      requestedDeliveryDate,
      requestedShipDate
    }
  };
}

export function getEditableOrderGuideItemsComplete(orderGuideItems) {
  return {
    type: actionTypes.MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_COMPLETE,
    payload: {
      orderGuideItems
    }
  };
}

export function getEditableOrderGuideItemsError(error) {
  return {
    type: actionTypes.MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_ERROR,
    payload: {
      error
    }
  };
}

// UPDATE [EDIT] ORDER GUIDE ITEMS

export function updateOrderGuideItems(orderGuideItems, headerId) {
  return {
    type: actionTypes.MP_UPDATE_ORDER_GUIDE_ITEMS_STARTED,
    payload: {
      orderGuideItems,
      headerId
    }
  };
}

export function updateOrderGuideItemsComplete() {
  return {
    type: actionTypes.MP_UPDATE_ORDER_GUIDE_ITEMS_COMPLETE
  };
}

export function updateOrderGuideItemsError(error) {
  return {
    type: actionTypes.MP_UPDATE_ORDER_GUIDE_ITEMS_ERROR,
    payload: {
      error
    }
  };
}

// UPDATE ORDER GUIDE HEADERS

export function setDefaultOrderGuide(orderGuideHeaders) {
  return {
    type: actionTypes.MP_SET_DEFAULT_ORDER_GUIDE_STARTED,
    payload: {
      orderGuideHeaders
    }
  };
}

export function setDefaultOrderGuideComplete() {
  return {
    type: actionTypes.MP_SET_DEFAULT_ORDER_GUIDE_COMPLETE
  };
}

export function setDefaultOrderGuideError(error) {
  return {
    type: actionTypes.MP_SET_DEFAULT_ORDER_GUIDE_ERROR,
    payload: {
      error
    }
  };
}

// GET CONCEPT ATTRIBUTES

export function getConceptAttributes(conceptId, accountId) {
  return {
    type: actionTypes.GET_CONCEPT_ATTRIBUTES_STARTED,
    payload: {
      conceptId,
      accountId
    }
  };
}

export function getConceptAttributesComplete(conceptAttributes) {
  return {
    type: actionTypes.GET_CONCEPT_ATTRIBUTES_COMPLETE,
    payload: {
      conceptAttributes
    }
  };
}

export function getConceptAttributesError(error) {
  return {
    type: actionTypes.GET_CONCEPT_ATTRIBUTES_ERROR,
    payload: {
      error
    }
  };
}

// Delete Order Guide Items

export function deleteSelectedItems(orderGuideItems, headerId) { //itemIds, headerId) {
  return {
    type: actionTypes.DELETE_SELECTED_ITEMS_STARTED,
    payload: {
      //itemIds,
      orderGuideItems,
      headerId
    }
  };
}

export function deleteSelectedItemsError(error) {
  return {
    type: actionTypes.DELETE_SELECTED_ITEMS_ERROR,
    payload: {
      error
    }
  };
}

// Get Vendor Location Actions
export function getVendorLocation(warehouseCode, accountId) {
  return {
    type: actionTypes.GET_VENDORLOCATION_STARTED,
    payload: {
      warehouseCode,
      accountId
    }
  };
}

export function getVendorLocationComplete(vendorLocation) {
  return {
    type: actionTypes.GET_VENDORLOCATION_COMPLETE,
    payload: {
      vendorLocation
    }
  };
}

export function getVendorLocationError(error) {
  return {
    type: actionTypes.GET_VENDORLOCATION_ERROR,
    payload: {
      error
    }
  };
}

//  Get Concept Vendor Code Actions
export function getConceptVendorCode(conceptId, vendorId) {
  return {
    type: actionTypes.GET_CONCEPTVENDORCODE_STARTED,
    payload: {
      conceptId, vendorId
    }
  };
}

export function getConceptVendorCodeComplete(conceptVendorCode) {
  return {
    type: actionTypes.GET_CONCEPTVENDORCODE_COMPLETE,
    payload: {
      conceptVendorCode
    }
  };
}

export function getConceptVendorCodeError(error) {
  return {
    types: actionTypes.GET_CONCEPTVENDORCODE_ERROR,
    payload: {
      error
    }
  };
}

// Set Ship Date

export function setShipDate(shipDate) {
  return {
    type: actionTypes.SET_SHIP_DATE,
    payload: {
      shipDate
    }
  };
}

// Set Delivery Date

export function setDeliveryDate(deliveryDate) {
  return {
    type: actionTypes.SET_DELIVERY_DATE,
    payload: {
      deliveryDate
    }
  };
}

// SET ON-HAND AND ON-RECEIVED DAYS

export function setOnHandOnReceivedDays(deliveryDays) {
  return {
    type: actionTypes.SET_OH_OR_DAYS,
    payload: {
      deliveryDays
    }
  };
}

// Get AccountId and Name using ConceptId

export function getAccountIdsAndNamesStart(conceptId, conceptCode, relatedAccountIds) {
  return {
    type: actionTypes.GET_ACCOUNTIDS_NAMES_STARTED,
    payload: {
      conceptId,
      conceptCode,
      relatedAccountIds
    }
  };
}

export function getAccountIdsAndNamesComplete(relatedAccounts, relatedAccountIds) {
  return {
    type: actionTypes.GET_ACCOUNTIDS_NAMES_COMPLETE,
    payload: {
      relatedAccounts,
      relatedAccountIds
    }
  };
}

export function getAccountIdsAndNamesError(error) {
  return {
    type: actionTypes.GET_ACCOUNTIDS_NAMES_ERROR,
    payload: {
      error
    }
  };
}

// Set AccountId

export function setAccountId(accountId) {
  return {
    type: actionTypes.SET_ACCOUNTID,
    payload: {
      accountId
    }
  };
}

// GET ACTIVE MESSAGES

export function getActiveMessages(vendorId, accountId, messageDate) {
  return {
    type: actionTypes.GET_ACTIVE_MESSAGES_STARTED,
    payload: {
      vendorId,
      accountId,
      messageDate
    }
  };
}

export function getActiveMessagesComplete(messages) {
  return {
    type: actionTypes.GET_ACTIVE_MESSAGES_COMPLETE,
    payload: {
      messages
    }
  };
}

export function getActiveMessagesError(error) {
  return {
    type: actionTypes.GET_ACTIVE_MESSAGES_ERROR,
    payload: {
      error
    }
  };
}

export function getOrderGuideItemMaxPurchaseQuantity(accountId, vendorId, deliveryDate, orderGuideItem) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_STARTED,
    payload: {
      accountId,
      vendorId,
      deliveryDate,
      orderGuideItem
    }
  };
}

export function getOrderGuideItemMaxPurchaseQuantityComplete(itemNumber,maxPurchaseQuantity, message) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_COMPLETE,
    payload: {
      itemNumber,
      maxPurchaseQuantity,
      message
    }
  };
}

export function getOrderGuideItemMaxPurchaseQuantityError(error) {
  return {
    type: actionTypes.GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_ERROR,
    payload: {
      error
    }
  };
}
