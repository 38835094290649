import React from "react";
import { getCssMessageClass, getCssIconClass } from './../../common/utils/generalFunctions';
import PropTypes from "prop-types";

export const Message = (props) => {

    const messageClassName = getCssMessageClass(props.message.priority.toString());
    const iconClassName = getCssIconClass(props.message.priority.toString());
    
    return (
        <div className={`messageBanner ${messageClassName}`}>
            <div className={`${iconClassName}`}/>
            <div className="messageValue">{props.message.message}</div>
        </div>
    );
};

Message.propTypes = {
  message: PropTypes.object
};