import {AUTH_PROFILE_RETRIEVAL_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.profile, action) {
  if (action.type === AUTH_PROFILE_RETRIEVAL_COMPLETE) {
    return action.payload.profile;
  } else {
    return state;
  }
}
