import {MP_CREATE_PURCHASE_ORDER_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.purchaseOrder, action) {
  if (action.type === MP_CREATE_PURCHASE_ORDER_COMPLETE) {
    return {
      ...state,
      headerId: action.payload.headerId
    };
  } else {
    return state;
  }
}
