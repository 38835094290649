import {MP_SEARCH_PREVIOUS_ORDERS_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';
import moment                 from 'moment';

export default function update(state = initialState.previousOrdersEndDate, action) {
  if (action.type === MP_SEARCH_PREVIOUS_ORDERS_COMPLETE) {
    return moment(action.payload.endDate);
  } else {
    return state;
  }
}
