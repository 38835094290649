export const AUTH_PROFILE_RETRIEVAL_STARTED = 'AUTH_PROFILE_RETRIEVAL_STARTED';
export const AUTH_PROFILE_RETRIEVAL_COMPLETE = 'AUTH_PROFILE_RETRIEVAL_COMPLETE';
export const AUTH_PROFILE_RETRIEVAL_ERROR = 'AUTH_PROFILE_RETRIEVAL_ERROR';

export const TOKEN_IS_EXPIRED = 'TOKEN_IS_EXPIRED';

export const SERVICE_CALL_STARTED = 'SERVICE_CALL_STARTED';
export const SERVICE_CALL_COMPLETE = 'SERVICE_CALL_COMPLETE';

export const MP_GET_ORDER_GUIDE_HEADER_STARTED = 'MP_GET_ORDER_GUIDE_HEADER_STARTED';
export const MP_GET_ORDER_GUIDE_HEADER_COMPLETE = 'MP_GET_ORDER_GUIDE_HEADER_COMPLETE';
export const MP_GET_ORDER_GUIDE_HEADER_ERROR = 'MP_GET_ORDER_GUIDE_HEADER_ERROR';

export const MP_GET_ORDER_GUIDE_ITEMS_STARTED = 'MP_GET_ORDER_GUIDE_ITEMS_STARTED';
export const MP_GET_ORDER_GUIDE_ITEMS_COMPLETE = 'MP_GET_ORDER_GUIDE_ITEMS_COMPLETE';
export const MP_GET_ORDER_GUIDE_ITEMS_ERROR = 'MP_GET_ORDER_GUIDE_ITEMS_ERROR';

// The following 3 actions behave similar to the above 3 actions: They deal with OrderGuideItems
// It will be ideal if the [Order Guide] page and [Edit Order Guide] page retrieve their
// data from the same endpoint and stored procedure.

export const MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_STARTED = 'MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_STARTED';
export const MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_COMPLETE = 'MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_COMPLETE';
export const MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_ERROR = 'MP_GET_EDITABLE_ORDER_GUIDE_ITEMS_ERROR';

export const MP_GET_VENDOR_STARTED = "MP_GET_VENDOR_STARTED";
export const MP_GET_VENDOR_COMPLETE = "MP_GET_VENDOR_COMPLETE";
export const MP_GET_VENDOR_ERROR = "MP_GET_VENDOR_ERROR";

export const MP_GET_CONCEPT_STARTED = "MP_GET_CONCEPT_STARTED";
export const MP_GET_CONCEPT_COMPLETE = "MP_GET_CONCEPT_COMPLETE";
export const MP_GET_CONCEPT_ERROR = "MP_GET_CONCEPT_ERROR";

export const MP_SHOW_TOASTR_MESSAGE = "MP_SHOW_TOASTR_MESSAGE";

export const MP_UPDATE_SHOPPING_CART = "MP_UPDATE_SHOPPING_CART";
export const MP_ADD_TO_SHOPPING_CART = "MP_ADD_TO_SHOPPING_CART";
export const MP_REMOVE_FROM_SHOPPING_CART = "MP_REMOVE_FROM_SHOPPING_CART";
export const MP_CLEAR_SHOPPING_CART = "MP_CLEAR_SHOPPING_CART";

export const MP_TOGGLE_PREVIEW_ORDER_BUTTON_VISIBILITY = "MP_TOGGLE_PREVIEW_ORDER_BUTTON_VISIBILITY";

export const MP_CREATE_PURCHASE_ORDER_STARTED = "MP_CREATE_PURCHASE_ORDER_STARTED";
export const MP_CREATE_PURCHASE_ORDER_COMPLETE = "MP_CREATE_PURCHASE_ORDER_COMPLETE";
export const MP_CREATE_PURCHASE_ORDER_ERROR = "MP_CREATE_PURCHASE_ORDER_ERROR";

export const MP_SEARCH_PREVIOUS_ORDERS_STARTED = "MP_SEARCH_PREVIOUS_ORDERS_STARTED";
export const MP_SEARCH_PREVIOUS_ORDERS_COMPLETE = "MP_SEARCH_PREVIOUS_ORDERS_COMPLETE";
export const MP_SEARCH_PREVIOUS_ORDERS_ERROR = "MP_SEARCH_PREVIOUS_ORDERS_ERROR";

export const MP_SEARCH_REPORTS_STARTED = "MP_SEARCH_REPORTS_STARTED";
export const MP_SEARCH_REPORTS_COMPLETE = "MP_SEARCH_REPORTS_COMPLETE";
export const MP_SEARCH_REPORTS_ERROR = "MP_SEARCH_REPORTS_ERROR";

export const MP_GET_VENDOR_ITEMS_STARTED = "MP_GET_VENDOR_ITEMS_STARTED";
export const MP_GET_VENDOR_ITEMS_COMPLETE = "MP_GET_VENDOR_ITEMS_COMPLETE";
export const MP_GET_VENDOR_ITEMS_ERROR = "MP_GET_VENDOR_ITEMS_ERROR";

export const MP_ADD_SELECTED_ITEMS_STARTED = "MP_ADD_SELECTED_ITEMS_STARTED";
export const MP_ADD_SELECTED_ITEMS_COMPLETE = "MP_ADD_SELECTED_ITEMS_COMPLETE";
export const MP_ADD_SELECTED_ITEMS_ERROR = "MP_ADD_SELECTED_ITEMS_ERROR";

export const MP_UPDATE_ORDER_GUIDE_ITEMS_STARTED = 'MP_UPDATE_ORDER_GUIDE_ITEMS_STARTED';
export const MP_UPDATE_ORDER_GUIDE_ITEMS_COMPLETE = 'MP_UPDATE_ORDER_GUIDE_ITEMS_COMPLETE';
export const MP_UPDATE_ORDER_GUIDE_ITEMS_ERROR = 'MP_UPDATE_ORDER_GUIDE_ITEMS_ERROR';

export const MP_SET_DEFAULT_ORDER_GUIDE_STARTED = 'MP_SET_DEFAULT_ORDER_GUIDE_STARTED';
export const MP_SET_DEFAULT_ORDER_GUIDE_COMPLETE = 'MP_SET_DEFAULT_ORDER_GUIDE_COMPLETE';
export const MP_SET_DEFAULT_ORDER_GUIDE_ERROR = 'MP_SET_DEFAULT_ORDER_GUIDE_ERROR';

export const SET_SELECTED_ORDER_GUIDE = 'SET_SELECTED_ORDER_GUIDE';
export const SET_SHIP_DATE = 'SET_SHIP_DATE';
export const SET_DELIVERY_DATE = "SET_DELIVERY_DATE";
export const SET_OH_OR_DAYS = 'SET_OH_OR_DAYS';

export const GET_CONCEPT_ATTRIBUTES_STARTED = 'GET_CONCEPT_ATTRIBUTES_STARTED';
export const GET_CONCEPT_ATTRIBUTES_COMPLETE = 'GET_CONCEPT_ATTRIBUTES_COMPLETE';
export const GET_CONCEPT_ATTRIBUTES_ERROR = 'GET_CONCEPT_ATTRIBUTES_ERROR';

export const GET_ORDER_GUIDE_ITEMS_PDF_STARTED = 'GET_ORDER_GUIDE_ITEMS_PDF_STARTED';
export const GET_ORDER_GUIDE_ITEMS_PDF_COMPLETE = 'GET_ORDER_GUIDE_ITEMS_PDF_COMPLETE';
export const GET_ORDER_GUIDE_ITEMS_PDF_ERROR = 'GET_ORDER_GUIDE_ITEMS_PDF_ERROR';

export const GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_STARTED = 'GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_STARTED';
export const GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_COMPLETE = 'GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_COMPLETE';
export const GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_ERROR = 'GET_EDITABLE_ORDER_GUIDE_ITEMS_PDF_ERROR';

export const GET_PREVIOUS_ORDERS_PDF_STARTED = 'GET_PREVIOUS_ORDERS_PDF_STARTED';
export const GET_PREVIOUS_ORDERS_PDF_COMPLETE = 'GET_PREVIOUS_ORDERS_PDF_COMPLETE';
export const GET_PREVIOUS_ORDERS_PDF_ERROR = 'GET_PREVIOUS_ORDERS_PDF_ERROR';

export const GET_REPORTS_PDF_STARTED = 'GET_REPORTS_PDF_STARTED';
export const GET_REPORTS_PDF_COMPLETE = 'GET_REPORTS_PDF_COMPLETE';
export const GET_REPORTS_PDF_ERROR = 'GET_REPORTS_PDF_ERROR';

export const DELETE_SELECTED_ITEMS_STARTED = "DELETE_SELECTED_ITEMS_STARTED";
export const DELETE_SELECTED_ITEMS_COMPLETE = "DELETE_SELECTED_ITEMS_COMPLETE";
export const DELETE_SELECTED_ITEMS_ERROR = "DELETE_SELECTED_ITEMS_ERROR";

export const GET_ORDER_DETAIL_PDF_STARTED = 'GET_ORDER_DETAIL_PDF_STARTED';
export const GET_ORDER_DETAIL_PDF_COMPLETE = 'GET_ORDER_DETAIL_PDF_COMPLETE';
export const GET_ORDER_DETAIL_PDF_ERROR = 'GET_ORDER_DETAIL_PDF_ERROR';

export const GET_REPORT_DETAIL_PDF_STARTED = 'GET_REPORT_DETAIL_PDF_STARTED';
export const GET_REPORT_DETAIL_PDF_COMPLETE = 'GET_REPORT_DETAIL_PDF_COMPLETE';
export const GET_REPORT_DETAIL_PDF_ERROR = 'GET_REPORT_DETAIL_PDF_ERROR';

export const GET_VENDORLOCATION_STARTED = "GET_VENDORLOCATION_STARTED";
export const GET_VENDORLOCATION_COMPLETE = "GET_VENDORLOCATION_COMPLETE";
export const GET_VENDORLOCATION_ERROR = "GET_VENDORLOCATION_ERROR";

export const GET_CONCEPTVENDORCODE_STARTED = "GET_CONCEPTVENDORCODE_STARTED";
export const GET_CONCEPTVENDORCODE_COMPLETE = "GET_CONCEPTVENDORCODE_COMPLETE";
export const GET_CONCEPTVENDORCODE_ERROR = "GET_CONCEPTVENDORCODE_ERROR";

export const GET_ORDER_GUIDE_ITEMS_EXCEL_STARTED = 'GET_ORDER_GUIDE_ITEMS_EXCEL_STARTED';
export const GET_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE = 'GET_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE';
export const GET_ORDER_GUIDE_ITEMS_EXCEL_ERROR = 'GET_ORDER_GUIDE_ITEMS_EXCEL_ERROR';

export const GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_STARTED = 'GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_STARTED';
export const GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE = 'GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE';
export const GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_ERROR = 'GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_ERROR';

export const GET_PREVIOUS_ORDERS_EXCEL_STARTED = 'GET_PREVIOUS_ORDERS_EXCEL_STARTED';
export const GET_PREVIOUS_ORDERS_EXCEL_COMPLETE = 'GET_PREVIOUS_ORDERS_EXCEL_COMPLETE';
export const GET_PREVIOUS_ORDERS_EXCEL_ERROR = 'GET_PREVIOUS_ORDERS_EXCEL_ERROR';

export const GET_REPORTS_EXCEL_STARTED = 'GET_REPORTS_EXCEL_STARTED';
export const GET_REPORTS_EXCEL_COMPLETE = 'GET_REPORTS_EXCEL_COMPLETE';
export const GET_REPORTS_EXCEL_ERROR = 'GET_REPORTS_EXCEL_ERROR';

export const GET_ORDER_DETAIL_EXCEL_STARTED = 'GET_ORDER_DETAIL_EXCEL_STARTED';
export const GET_ORDER_DETAIL_EXCEL_COMPLETE = 'GET_ORDER_DETAIL_EXCEL_COMPLETE';
export const GET_ORDER_DETAIL_EXCEL_ERROR = 'GET_ORDER_DETAIL_EXCEL_ERROR';

export const GET_REPORT_DETAIL_EXCEL_STARTED = 'GET_REPORT_DETAIL_EXCEL_STARTED';
export const GET_REPORT_DETAIL_EXCEL_COMPLETE = 'GET_REPORT_DETAIL_EXCEL_COMPLETE';
export const GET_REPORT_DETAIL_EXCEL_ERROR = 'GET_REPORT_DETAIL_EXCEL_ERROR';

export const SEND_FORGOT_USERNAME_EMAIL = 'SEND_FORGOT_USERNAME_EMAIL';
export const SEND_FORGOT_USERNAME_EMAIL_COMPLETE = 'SEND_FORGOT_USERNAME_EMAIL_COMPLETE';
export const SEND_FORGOT_USERNAME_EMAIL_ERROR = 'SEND_FORGOT_USERNAME_EMAIL_ERROR';

export const GET_ACCOUNTIDS_NAMES_STARTED = "GET_ACCOUNTIDS_NAMES_STARTED";
export const GET_ACCOUNTIDS_NAMES_COMPLETE = "GET_ACCOUNTIDS_NAMES_COMPLETE";
export const GET_ACCOUNTIDS_NAMES_ERROR = "GET_ACCOUNTIDS_NAMES_ERROR";

export const SET_ACCOUNTID = "SET_ACCOUNTID";

export const GET_ACTIVE_MESSAGES_STARTED = "GET_ACTIVE_MESSAGES_STARTED";
export const GET_ACTIVE_MESSAGES_COMPLETE = "GET_ACTIVE_MESSAGES_COMPLETE";
export const GET_ACTIVE_MESSAGES_ERROR = "GET_ACTIVE_MESSAGES_ERROR";

export const GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_STARTED = "GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_STARTED";
export const GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_COMPLETE = "GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_COMPLETE";
export const GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_ERROR = "GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_STARTED";