import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import * as generalFunctions from "../../common/utils/generalFunctions";

export class OrderDetailRow extends React.Component {

  getSubTotal = (row) => {
    if(!row || !row.price) {
      return 'MKT';
    }
    else {
      return generalFunctions.roundFixed(row.price, 2);
    }
  };

  render() {
    const row = this.props.data;
    const deleteItem = this.props.deleteItem;
    const updateLineNote = this.props.updateLineNote;
    const requestedShipDateIndex = (this.props.headers) ? (this.props.headers.findIndex(x => x.name === 'shipDate')) : -1;
    const requestedDeliveryDateIndex = (this.props.headers) ? (this.props.headers.findIndex(x => x.name === 'deliveryDate')) : -1;
    const price = generalFunctions.getPrice(row.price, this.props.conceptAttributes);
    const subTotal = this.getSubTotal(row);
    const cellClass = {};
    if (this.props.headers) {
      for(let i = 0; i < this.props.headers.length; i++){
        cellClass[i]={className: this.props.headers[i].className};
      }
    }

    const shipDateOnly = (requestedShipDateIndex > -1 && requestedDeliveryDateIndex === -1);
    const shipDateFirst = shipDateOnly || ((requestedShipDateIndex > -1 && requestedDeliveryDateIndex > -1) && (requestedShipDateIndex < requestedDeliveryDateIndex));
    const requestedDateCol1 = (shipDateFirst) => {
      const requestedDate = (shipDateFirst) ? row.shipDate : row.deliveryDate;
      return <td {...cellClass[8]}>{generalFunctions.getFormattedDisplayDate(requestedDate, false, true)}</td>;
    };
    const requestedDateCol2 = (shipDateFirst) => {
      const requestedDate = (shipDateFirst) ? row.deliveryDate : row.shipDate;
      return <td {...cellClass[9]}>{generalFunctions.getFormattedDisplayDate(requestedDate, false, true)}</td>;
    };

    return (
      <tr>
        <td {...cellClass[0]}>{row.order}<br/>{this.props.itemOptions && this.props.itemOptions.canDeleteItems
          ? <a onClick={() => deleteItem(row)}>Delete</a>
          : ''}</td>
        <td {...cellClass[1]}>{row.itemNumber}</td>
        <td {...cellClass[2]}>{row.description}</td>
        <td {...cellClass[3]}>{row.unit}</td>
        <td {...cellClass[4]}>{row.pack}</td>
        <td {...cellClass[5]}>{price}</td>
        <td {...cellClass[6]}>{subTotal}</td>
        <td {...cellClass[7]}>
          <input type="text" className="form-control textbox" value={row.lineNote} onChange={(event) => updateLineNote(event, row)} />
        </td>
        {(requestedShipDateIndex > -1 || requestedDeliveryDateIndex > -1) ? requestedDateCol1(shipDateFirst) : null}
        {(requestedShipDateIndex > -1 && requestedDeliveryDateIndex > -1) ? requestedDateCol2(shipDateFirst) : null}
      </tr>
    );
  }
}

OrderDetailRow.propTypes = {
  data: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  updateLineNote: PropTypes.func.isRequired,
  headers: PropTypes.array,
  itemOptions: PropTypes.object.isRequired,
  conceptAttributes: PropTypes.array
};


export const mapStateToProps = state => {
  return {
    conceptAttributes: state.conceptAttributes
  };
};

export default connect(mapStateToProps)(OrderDetailRow);
