import moment from 'moment';

const initialState = {
  serviceCallsInProgress: 0,
  profile: {
  },
  relatedAccounts: [],
  accountId: 0,
  orderGuides: [],
  orderGuideItems: [],
  shoppingCart: [],
  vendor: {},
  concept: {},
  conceptVendorCode: {
    id: 0,
    conceptId: 0,
    vendorId: 0,
    dateCreated: '',
    ediCode: '',
    shipperCode: '',
    tCode: ''
  },
  conceptAttributes: [],
  elementPresentation: {
    selectedOrderGuide: {},
    selectedShipDate: moment().format(),
    selectedDeliveryDate: '',
    selectedOnHandOnReceivedDays: [],
    isPreviewOrderButtonVisible: false
  },
  reports: [],
  reportsStartDate: '',
  reportsEndDate: '',
  reportsFilteredOrders: [],
  reportsFilterText: "",
  previousOrders: [],
  previousOrdersStartDate: '',
  previousOrdersEndDate: '',
  previousOrdersFilteredOrders: [],
  previousOrdersFilterText: "",
  purchaseOrder: {
    headerId: 0
  },
  vendorItems: [],
  editableOrderGuideItems: [],
  pdf: {
    orderGuideItems: "",
    editableOrderGuideItems: "",
    previousOrders: "",
    reports: "",
    previousOrderDetail: "",
    reportDetail: ""
  },
  excel: {
    orderGuideItems: "",
    editableOrderGuideItems: "",
    previousOrders: "",
    reports: "",
    previousOrderDetail: "",
    reportDetail: ""
  },
  vendorLocation: {
    name: "Not Found",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    eMail: "",
    phone: ""
  },
  userCredentials: {
    isUsernameFound: false
  },
  messages: [],
  maxPurchaseQuantityDetails: []
};

export default initialState;
