import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Modal} from 'react-bootstrap';
import {Table} from '../common/table';
import {bindActionCreators} from 'redux';
import {getVendorItems} from "../../selectors";
import AddItemsRow from '../order-guide/add-items-row';
import * as actions from '../../actions/mpOnlineActions';
import {filterItemsV2} from './../../common/utils/generalFunctions';

const getTableHeaders = () => {
  return [
    {header: "Item",        className: "setWidth120",                               name:"itemNumber"},
    {header: "Description",                                                         name:"description"},
    {header: "Unit",        className: "table-cell-center setWidth200",             name:"unit"},
    {header: "Pack",        className: "table-cell-center setWidth200",             name:"packSize"},
    {header: "Price",       className: "table-cell-center setWidth120 isCurrency",  name:"price"}
  ];
};

export class AddItemsModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    actions: PropTypes.object,
    headerId: PropTypes.number.isRequired,
    vendorItems: PropTypes.array,
    vendor: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    closeButtonHandler: PropTypes.func.isRequired
  };

  state = {
    filterText: '',
    vendorItems: [],
    filteredItems: [],
    isSelectAllChecked: false
  };

  componentDidMount() {
    const {conceptId} = this.props.account;
    const {vendorId}  = this.props.vendor;
    const {headerId}  = this.props;
    this.props.actions.getVendorItems(headerId, conceptId, vendorId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.vendorItems.length > 0) {
      this.setState({
        vendorItems: nextProps.vendorItems,
        filteredItems: nextProps.vendorItems
      });
    }
  }

  onSelectAll = () => {
    let items = this.state.filteredItems;
    items.forEach(x => {
      return (x.isSelected = !this.state.isSelectAllChecked);
    });
    this.setState({
      filteredItems: items,
      isSelectAllChecked: !this.state.isSelectAllChecked
    });
  };

  onRowSelection = (row) => {
    const items = this.state.filteredItems;
    const index = items.findIndex(x => x.conceptItemId === row.conceptItemId);
    row.isSelected = !row.isSelected;

    this.setState({
      filteredItems: [
        ...items.slice(0, index),
        row,
        ...items.slice(index + 1)
      ]
    });
  };

  addSelectedItems = () => {
    const {headerId} = this.props;
    const selectedItems = this.state.filteredItems.filter(x => {
      return x.isSelected;
    });

    selectedItems.forEach(item => {
      // Here, VendorGuideId is synonymous with HeaderId (short for OrderGuideHeaderId)
      // When adding/inserting a new item, we have to associate it with a specific OrderGuide
      item.vendorGuideId = headerId;
      item.lineNote = "";
    });

    this.props.actions.addSelectedItems(selectedItems, headerId);
  };

  filter = (event) => {
    const {vendorItems} = this.props;
    const inputValue = event.target.value.toLowerCase().trim();

    if (inputValue === "") {
      this.setState({
        filterText: event.target.value,
        filteredItems: vendorItems
      });
      return;
    }

    const matchedItems = filterItemsV2(vendorItems, inputValue);

    this.setState({
      filterText: event.target.value,
      filteredItems: matchedItems
    });
  };

  render() {
    const headers = getTableHeaders();
    const {filteredItems, filterText} = this.state;
    const {closeButtonHandler} = this.props;

    return (
      <Modal show={this.props.show} id="addItemsModal">
        <Modal.Header>
          <Modal.Title>
            <div className="col-md-1">Add Items</div>
            <div className="col-md-5">
              <input type="text"
                    value={filterText}
                    onChange={this.filter}
                    id="vendorItemsFilter"
                    className="form-control filter-textbox"
                    placeholder="Enter text to search..." />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table id="addItemsTable"
                 headers={headers}
                 onSelectAll={this.onSelectAll}
                 items={filteredItems}
                 pageSize={10}
                 RowComponent={AddItemsRow}
                 callback={this.onRowSelection}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" style={{width: '15%'}} onClick={this.addSelectedItems}>Add Selected Items</button>
          <button className="btn btn-primary" style={{width: '10%'}} onClick={closeButtonHandler}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const mapStateToProps = state => {
  return {
    vendorItems: getVendorItems(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemsModal);

