import axios from 'axios';
import {getAccessToken} from '../../auth/auth0Utilities';

let robinsonRegex = /.*.chrobinson.com/i;
let localhostRegex = /localhost/i;


let mpOnlineInstance = axios.create({
  baseURL: CONFIG.apis.mpOnlineGatewayEndpoint //eslint-disable-line no-undef
});

mpOnlineInstance.interceptors.request.use( config => {
  let newConfig = { ...config };
  const authToken = sessionStorage.getItem('access_token');

  

  // URL Regex test
  if (authToken) { 
      const accessToken = authToken; 
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return newConfig;
});

export const authProfileApiAdapter = axios.create({
  baseURL: CONFIG.apis.mpOnlineGatewayEndpoint //eslint-disable-line no-undef
});

export const mpOnlineGatewayApiAdapter = mpOnlineInstance;
