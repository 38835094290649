import _ from 'lodash';
import moment from 'moment';
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as documentActions from '../../actions/documentActions';
import * as previewOrderActions from '../../actions/mpOnlineActions';
import * as documentHelpers from '../../common/utils/documentHelpers';
import { ActionBar } from "../common/action-bar";
import * as helper from "../common/order-helper";
import { ClearableSearchBar } from '../common/searchBoxWithClear';
import * as modalMessages from '../modals/modalMessages';
import { filterItemsV2 } from "./../../common/utils/generalFunctions";
import { MessageType, ModalTypes, MessageLocation } from './../../common/utils/stringConstants';
import Table  from "./../../components/common/table";
import * as selectors from "./../../selectors";
import Modal from './../modals/modal';
import { PromptModal } from './../modals/prompt-modal';
import { OrderConfirmation } from './order-confirmation';
import OrderDetailRow from "./order-detail-row";
import { OrderSummary } from './order-summary';
import { TotalRow } from './total-row';

let headers = [
  //{ header: "#",                      className: "setWidth60",    name: "#" },
  { header: "Qty", className: "setWidth60 table-cell-center", name: "quantity", disableSort: true },
  { header: "Item Number", className: "setWidth100", name: "itemNumber" },
  { header: "Description", className: "setWidth300", name: "description" },
  { header: "Unit", className: "setWidth60 table-cell-center", name: "unit" },
  { header: "Pack", className: "setWidth60 table-cell-center", name: "pack" },
  { header: "Price", className: "setWidth80 table-cell-center isCurrency", name: "price" },
  { header: "SubTotal", className: "setWidth80 table-cell-center isCurrency", name: "subTotal" },
  { header: "Line Note", className: "setWidth180", name: "lineNote" }
];

export class PreviewOrder extends React.Component {
  static propTypes = {
    vendor: PropTypes.object,
    account: PropTypes.object,
    history: PropTypes.object,
    headerId: PropTypes.number,
    shoppingCartItems: PropTypes.array,
    documentActions: PropTypes.object,
    previewOrderActions: PropTypes.object,
    orderDetailPdf: PropTypes.string,
    selectedOrderGuide: PropTypes.object,
    selectedShipDate: PropTypes.string,
    selectedDeliveryDate: PropTypes.string,
    conceptAttributes: PropTypes.array,
    deliverDateVisible: PropTypes.bool,
    shipDateVisible: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      modalMessage: '',
      messageType: '',
      modalErrorType: '',
      canDelete: true,
      deletedItems: [],
      itemToRemove: {},
      filterText: "",
      filteredShoppingCartItems: props.shoppingCartItems ? props.shoppingCartItems : [],
      orderNumber: "",
      orderNote: "",
      canShowOrderConfirmation: false
    };

    this.ClearTextField = this.ClearTextField.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (helper.canSeeRequestedShipDate(this.props.conceptAttributes)) {
      const requestedShipDateHeader = { header: "Requested Ship Date", className: "setWidth100 table-cell-center", name: "shipDate" };
      if (headers.findIndex(x => x.header === "Requested Ship Date") === -1) {
        const deliveryIndex = headers.findIndex(x => x.header === "Requested Arrival Date");
        if (deliveryIndex > -1) {
          headers.splice(deliveryIndex, 0, requestedShipDateHeader);
        } else {
          headers[headers.length] = requestedShipDateHeader;
        }
      }
    } else {
      const requestedShipDateHeaderIndex = headers.findIndex(x => x.header === "Requested Ship Date");
      if ( requestedShipDateHeaderIndex > -1) {
        headers.splice(requestedShipDateHeaderIndex, 1);
      }
    }

    if (helper.canSeeRequestedDeliveryDate(this.props.conceptAttributes)) {
      const requestedDeliveryDateHeader = { header: "Requested Arrival Date", className: "setWidth100 table-cell-center", name: "deliveryDate" };
      if (headers.findIndex(x => x.header === "Requested Arrival Date") === -1) {
        headers[headers.length] = requestedDeliveryDateHeader;
      }
    } else {
      const requestedDeliveryDateHeaderIndex = headers.findIndex(x => x.header === "Requested Arrival Date");
      if ( requestedDeliveryDateHeaderIndex > -1) {
        headers.splice(requestedDeliveryDateHeaderIndex, 1);
      }
    }
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.shoppingCartItems !== nextProps.shoppingCartItems) {
      this.setState({ filteredShoppingCartItems: nextProps.shoppingCartItems });
    }
    if (this.props.headerId !== nextProps.headerId && nextProps.headerId > 0) {
      this.setState({ canShowOrderConfirmation: !this.state.canShowOrderConfirmation });
    }
    if (this.props.headerId !== nextProps.headerId && nextProps.headerId < 1) {
      switch (nextProps.headerId) {
        case -5:
          this.setState({ isModalVisible: true, modalErrorType: ModalTypes.OrderPastCutOff, modalMessage: `${modalMessages.poSubmitPastCutOffErrorMessage}`, messageType: MessageType.WARNING});
          break;
        case -10:
          this.setState({ isModalVisible: true, modalErrorType: ModalTypes.DayNotInDeliveryRoute, modalMessage: `${modalMessages.dayNotInDeliveryRoute}`, messageType: MessageType.WARNING});
          break;
        case -15:
          this.setState({ isModalVisible: true, modalErrorType: ModalTypes.OrderExceedsMaximumQuantity, modalMessage: `${modalMessages.orderExceedsMaximumQuantity}`, messageType: MessageType.WARNING});
          break;
        default:
          this.setState({ isModalVisible: true, modalErrorType: ModalTypes.SystemError, modalMessage: `${modalMessages.unidentifiedSystemError}`, messageType: MessageType.ERROR});
          break;
      }
    }
    this.downloadDetailPdf(nextProps);
  }

  componentWillUnmount() {
    if (this.props.headerId > 0) {
      this.props.previewOrderActions.clearShoppingCart();
    }
    this.props.previewOrderActions.createPurchaseOrderComplete(0);
  }

  filter = (event) => {
    const { shoppingCartItems } = this.props;
    const inputValue = event.target.value.toLowerCase().trim();

    if (inputValue === "") {
      this.setState({
        filterText: event.target.value,
        filteredShoppingCartItems: shoppingCartItems
      });
      return;
    }

    const matchedItems = filterItemsV2(shoppingCartItems, inputValue);

    this.setState({
      filterText: event.target.value,
      filteredShoppingCartItems: matchedItems
    });
  };

  toggleModal = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible
    });
  };

  deleteItem = (item) => {
    let shouldDeleteItems = true;
    this.handleModalDisplay(item, shouldDeleteItems);
  };

  confirmDelete = () => {
    const { itemToRemove } = this.state;
    this.setState({ deletedItems: this.state.deletedItems.concat(itemToRemove), shouldDeleteItems: false });
    this.props.previewOrderActions.removeFromShoppingCart(itemToRemove);
    this.toggleModal();
  };

  isOrderDateAfterVendorCutOff = () => {
    const earliestDeliveryDate = helper.getNearestDeliveryDate(new Date(), this.props.selectedOrderGuide, this.props.vendor, this.props.conceptAttributes);
    const isGoodDeliveryDateTime = (earliestDeliveryDate.startOf('day') <= moment(this.props.selectedDeliveryDate).startOf('day'));

    return isGoodDeliveryDateTime;
  };

  updateLineNote = (event, item) => {
    let updatedItem = _.cloneDeep(item);
    updatedItem.lineNote = event.target.value;
    this.props.previewOrderActions.updateShoppingCart(updatedItem);
  };

  canSubmitOrder = () => {
    const { shoppingCartItems } = this.props;
    let canSubmit = shoppingCartItems && shoppingCartItems.length > 0 ? true : false;
    canSubmit = canSubmit && shoppingCartItems && !shoppingCartItems.some(x => x.maxPurchaseQuantity > 0 &&  x.order > x.remainingOrderQuantity);
    return canSubmit;
  };

  onOrderSummaryChange = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    this.setState({ [key]: value });
  };

  submitPurchaseOrder = () => {
    if (this.handleModalDisplay()) {
      return;
    }

    const purchaseOrder = {
      purchaseOrderHeader: helper.getPurchaseOrderHeader(this.props, this.state),
      purchaseOrderDetails: helper.getPurchaseOrderDetails(this.props)
    };

    this.setState({ canDelete: false });
    this.props.previewOrderActions.createPurchaseOrder(purchaseOrder);
  };

  orderQuantityIsBelowThreshold = () => {
    const { shoppingCartItems } = this.props;
    let minimumOrderQuantity = this.getMinimumOrderQuantity();
    const actualOrderQuantity = shoppingCartItems.reduce((total, item) => {
      return total + parseInt(item.order);
    }, 0);

    return actualOrderQuantity < minimumOrderQuantity;
  };

  getMinimumOrderQuantity = () => {
    try {
      const attributes = this.props.conceptAttributes;
      const attribute = attributes.find(x => x.name === "MinimumOrderQTY");
      let minimumOrderQuantity = parseInt(attribute.value);
      return minimumOrderQuantity;
    } catch (e) {
      return 0;
    }
  };

  handleModalDisplay = (item, shouldDeleteItems) => {
    let modalMessage = '';
    let messageType = '';
    let modalErrorType = '';
    let shouldHaltSubmit = false;
    let incomingItem = (item) ? item : null;
    let incomingShouldDeleteItems = (shouldDeleteItems) ? shouldDeleteItems : false;


    if (this.orderQuantityIsBelowThreshold()) {
      modalMessage = `${modalMessages.belowMinimumQuantityWarningMessage(this.getMinimumOrderQuantity())}`;
      messageType = MessageType.ERROR;
      modalErrorType = ModalTypes.BelowQuantityThreshold;
      shouldHaltSubmit = true;
    }
    else if (!this.isOrderDateAfterVendorCutOff()) {
      modalMessage = `${modalMessages.poSubmitPastCutOffErrorMessage}`;
      messageType = MessageType.WARNING;
      modalErrorType = ModalTypes.OrderPastCutOff;
      shouldHaltSubmit = true;
    }
    else if (incomingShouldDeleteItems) {
      modalMessage = modalMessages.deleteItemWarningMessage;
      messageType = MessageType.WARNING;
      modalErrorType = ModalTypes.ItemDeleteWarning;
      shouldHaltSubmit = true;
    }

    if (shouldHaltSubmit) {
      this.setState({
        modalMessage: modalMessage,
        messageType: messageType,
        modalErrorType: modalErrorType,
        isModalVisible: !this.state.isModalVisible,
        itemToRemove: incomingItem
      });
    }

    return shouldHaltSubmit;
  };

  retrieveDetailPdf = (headerId) => {
    const { account } = this.props;
    this.props.documentActions.getOrderDetailPdf(account.accountId, headerId);
  };

  downloadDetailPdf = (nextProps) => {
    if (nextProps.orderDetailPdf !== this.props.orderDetailPdf) {
      const pdf = nextProps.orderDetailPdf;
      documentHelpers.download(pdf, 'OrderDetail.pdf');
    }
  };

  ClearTextField = (e) => {
    if (e.currentTarget.onclick) {
      const { shoppingCartItems } = this.props;
      this.setState({
        filterText: '',
        filteredShoppingCartItems: shoppingCartItems
      });
      return;
    }
  };

  buildModal(modalMessage, messageType, modalErrorType, filteredShoppingCartItems, shouldHaltSubmit) {
    let modal = (<Modal>
      <PromptModal
        show={false}
        message={''}
        messageType={''} />
    </Modal>);

    if (modalErrorType === ModalTypes.ItemDeleteWarning) {
      modal = (<Modal>
        <PromptModal show
          message={modalMessage}
          messageType={messageType}
          noButtonHandler={this.toggleModal}
          yesButtonHandler={this.confirmDelete} />
      </Modal>);
    }
    else if (modalErrorType) { //(modalErrorType === ModalTypes.OrderPastCutOff || modalErrorType === ModalTypes.BelowQuantityThreshold) {
      modal = (<Modal>
        <PromptModal show
          message={modalMessage}
          messageType={messageType}
          okButtonHandler={() => this.props.history.push({ pathname: "/Order-Guide", state: { deletedItems: filteredShoppingCartItems } })} />
      </Modal>);
    }

    return modal;
  }

  render() {
    const isSubmitButtonDisabled = !this.canSubmitOrder();
    const { vendor, account, shoppingCartItems, selectedShipDate, selectedDeliveryDate } = this.props;
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const orderTotal = helper.getOrderTotal(shoppingCartItems);
    const { isModalVisible, deletedItems, filterText, filteredShoppingCartItems, canShowOrderConfirmation, modalMessage, messageType, modalErrorType } = this.state;
    const canDelete = { canDeleteItems: this.state.canDelete };
    const shipDateVisible = helper.canSeeRequestedShipDate(this.props.conceptAttributes);
    const deliveryDateVisible = helper.canSeeRequestedDeliveryDate(this.props.conceptAttributes);

    if (_.isEmpty(account)) {
      return (<Redirect to="/" />);
    }

    filteredShoppingCartItems.forEach((item) => {
      item.shipDate = this.props.selectedShipDate;
      item.deliveryDate = this.props.selectedDeliveryDate;
    });

    return (
      <div className="container-fluid overflow_x_auto overflow_y_hidden">
        <ActionBar conceptColor={color}>
          <ClearableSearchBar
            filterText={filterText}
            multiFilter={this.filter}
            ClearTextField={this.ClearTextField} />
          {/*<input type="text"
              value={filterText}
              onChange={this.filter}
              id="txtFilter"
              className="form-control filter-textbox"
              placeholder="Enter text to search..." />*/}

          {!canShowOrderConfirmation &&
            <a id="correctOrder" role="button" onClick={() => this.props.history.push({ pathname: "/Order-Guide", state: { deletedItems: deletedItems } })}>
              <span className="fa fa-undo fa-2x vertical-align-middle" />
              <span className=" pad-left-5">Correct Order</span>
            </a>
          }

          {canShowOrderConfirmation &&
            <a id="nextOrder" role="button" onClick={() => this.props.history.push({ pathname: "/Order-Guide", state: { deletedItems: filteredShoppingCartItems } })}>
              <span className="fa fa-undo fa-2x vertical-align-middle" />
              <span className=" pad-left-5">Next Order</span>
            </a>
          }
        </ActionBar>

        {/* Order Details & Order Summary */}
        <div className="col-sm-12 pad-left-0 min-width-950 height_100percent">
          <div className="col-sm-9 pad-left-0 pad-right-0 height_100percent">
            <Table id="tblShoppingCartItems"
              headers={headers}
              items={filteredShoppingCartItems}
              RowComponent={OrderDetailRow}
              deleteItem={this.deleteItem}
              itemOptions={canDelete}
              updateLineNote={this.updateLineNote}
              TotalRow={TotalRow}
              wrapperClassName="tableWrapper"
              tableClassName="fixed_header"
              filterTypes={[MessageLocation.HeaderBanner]} />
          </div>

          <div className="col-sm-3 height_100percent">
            {!canShowOrderConfirmation &&
              <OrderSummary vendor={vendor}
                account={account}
                orderTotal={orderTotal}
                shipDate={selectedShipDate}
                deliveryDate={selectedDeliveryDate}
                shipDateVisible={shipDateVisible}
                deliveryDateVisible={deliveryDateVisible}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                submitButtonHandler={this.submitPurchaseOrder}
                inputChangeHandler={this.onOrderSummaryChange} />
            }

            {canShowOrderConfirmation &&
              <OrderConfirmation headerId={this.props.headerId}
                deliveryDate={selectedDeliveryDate}
                shipDate={selectedShipDate}
                deliveryDateVisible={deliveryDateVisible}
                shipDateVisible={shipDateVisible}
                printButtonHandler={this.retrieveDetailPdf} />
            }
          </div>
        </div>

        {/* Prompt Modal */}
        {isModalVisible ?
          this.buildModal(modalMessage, messageType, modalErrorType, filteredShoppingCartItems)
          :
          null};
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    vendor: selectors.getVendor(state),
    account: selectors.getAccount(state),
    headerId: selectors.getHeaderId(state),
    shoppingCartItems: selectors.getSortedShoppingCart(state),
    orderDetailPdf: selectors.getOrderDetailPdf(state),
    selectedOrderGuide: selectors.getSelectedOrderGuide(state),
    selectedShipDate: selectors.getSelectedShipDate(state),
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state),
    conceptAttributes: selectors.getConceptAttributes(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    previewOrderActions: bindActionCreators(previewOrderActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewOrder);
