import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.elementPresentation, action) {
  switch (action.type) {
    case actionTypes.MP_TOGGLE_PREVIEW_ORDER_BUTTON_VISIBILITY:
      return {
        ...state,
        isPreviewOrderButtonVisible: action.payload.isVisible
      };
    case actionTypes.SET_SELECTED_ORDER_GUIDE:
      return {
        ...state,
        selectedOrderGuide: action.payload.selectedOrderGuide
      };
    case actionTypes.SET_SHIP_DATE:
      return {
        ...state,
        selectedShipDate: action.payload.shipDate
      };
    case actionTypes.SET_DELIVERY_DATE:
      return {
        ...state,
        selectedDeliveryDate: action.payload.deliveryDate
      };
    case actionTypes.SET_OH_OR_DAYS:
      return {
        ...state,
        selectedOnHandOnReceivedDays: action.payload.deliveryDays
      };
    default:
      return state;
  }
}
