import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import * as generalFunctions from "../../common/utils/generalFunctions";

export class AddItemsRow extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    headers: PropTypes.array,
    conceptAttributes: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      isRowSelected: false
    };
  }

  render() {
    const row = this.props.data;
    const onRowSelection = this.props.callback;
    const price = generalFunctions.getPrice(row.price, this.props.conceptAttributes);
    const cellClass = {};
    let i = 0;
    if (this.props.headers) {
      for(i = 0; i < this.props.headers.length; i++){
        cellClass[i]={className: this.props.headers[i].className};
      }
    }

    return (
      <tr className="add-items-row" onClick={() => onRowSelection(row)}>
        <td width="40px">
          <div className={`checkmark-circle ${ row.isSelected ? 'checked' : ''}`} >
            <div className="checkmark" />
          </div>
        </td>
        <td {...cellClass[0]}>{row.itemNumber}</td>
        <td {...cellClass[1]}>{row.description}</td>
        <td {...cellClass[2]}>{row.unit}</td>
        <td {...cellClass[3]}>{row.packSize}</td>
        <td {...cellClass[4]}>{price}</td>
      </tr>
    );
  }
}

export const mapStateToProps = state => {
  return {
    conceptAttributes: state.conceptAttributes
  };
};

export default connect(mapStateToProps)(AddItemsRow);
