import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Header } from './header';
import { Pagination } from './pagination';
import * as selectors from "../../../selectors";
import { getCssClassHeightCalc } from './../../../common/utils/generalFunctions';
import moment from 'moment';

export class Table extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    headers: PropTypes.array,
    children: PropTypes.array,
    selectedDeliveryDate: PropTypes.string,
    onSelectAll: PropTypes.func,
    selectAllChecked: PropTypes.bool,
    pageSize: PropTypes.number,
    RowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    items: PropTypes.array,
    itemOptions: PropTypes.object,
    callback: PropTypes.func,
    deleteItem: PropTypes.func,
    updateLineNote: PropTypes.func,
    showOrderDetailPdf: PropTypes.func,
    deliveryDays: PropTypes.array,
    TotalRow: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    wrapperClassName: PropTypes.string,
    tableClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    filterTypes: PropTypes.array,
    messages: PropTypes.array
  };

  constructor(props) {
    super(props);
    const items = props.items ? props.items : [];

    this.state = {
      items: items,
      rows: (props.pageSize && props.pageSize > 0) ? [] : items,
      sortedColumn: "",
      isAscendingOrder: true,
      pager: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.items) !== JSON.stringify(this.props.items)) {
      this.setState({
        items: nextProps.items,
        rows: this.getPageItems( this.state.currentPage, nextProps.items ),
        sortedColumn: "",
        isAscendingOrder: true
      });
    }
  }

  getParItemName = () => {
    const {selectedDeliveryDate} = this.props;
    const day = moment(selectedDeliveryDate).day();
    return 'par'.concat(day);
  };

  getPageItems = (page, itemList) => {
    let items = (itemList) ? itemList : this.state.items;
    let pager = this.state.pager;
    let pageSize = this.props.pageSize;
    let startIndex = 0;
    let endIndex = (pageSize > 0 && items && items.length > 0) ? pageSize - 1 : items.length;

    if (pager) {
      if (pager.startIndex) {
        startIndex = pager.startIndex;
        endIndex = pager.endIndex;
      }
    } else {
      return items;
    }

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new page of items from items array
    let pageOfItems = items.slice(startIndex, endIndex + 1);

    return pageOfItems;
  };

  sort = (field1, field2) => {
    if (field1 < field2) {
      return -1;
    }
    if (field1 > field2) {
      return 1;
    }

    return 0;
  };

  getFieldValue = (fieldName, fieldObject) => {
    const headerObject = this.props.headers.find(v => (v.name === fieldName));
    const checkProcessed = (headerObject) ? headerObject.checkProcessed : false;

    if ((fieldName === "isProcessed" || fieldName === "isApproved") && fieldObject["isVoid"] === true) {
      return false;
    }

    if (fieldName === "par") {
      return fieldObject[this.getParItemName()];
    }

    if (fieldName === "amount" && checkProcessed) {
      if (!fieldObject["isProcessed"]) {
        return 9999999999; // use absurdly high number to sort non-processed items to end of list for asc and beginning for desc
      }
    }

    if (fieldName === "subTotal") {
      let calcValue = 0;
      if((typeof fieldObject["price"] !== "undefined") && (typeof fieldObject["order"] !== "undefined")){
        calcValue = fieldObject["price"] * fieldObject["order"];
      }

      return calcValue === 0 ? 9999999999 : calcValue;
    }

    if (fieldName === "price") {
      let calcValue = (typeof fieldObject[fieldName] !== "undefined") ? fieldObject[fieldName] : 0;
      return calcValue === 0 ? 9999999999 : calcValue;
    }

    if (fieldName.toLowerCase().indexOf("datedisplay") > -1){
      let initialValue = (typeof fieldObject[fieldName] !== "undefined") ? fieldObject[fieldName] : "";

      if (initialValue.indexOf("(") === -1){
        return moment(initialValue);
      } else {
        initialValue = initialValue.substr(0,initialValue.indexOf("("));
        return moment(initialValue, 'MM/DD/YYYY HH:mm');
      }
    }

    return fieldObject[fieldName];
  };

  onColumnSort = e => {
    const field = e.target.id;
    const sortDirection = (this.state.sortedColumn === field) ? this.state.isAscendingOrder : true;
    const sorted = this.state.items.sort((a, b) => {
      let field1 = this.getFieldValue(field, a);
      let field2 = this.getFieldValue(field, b);

      if (typeof field1 === "string") {
        field1 = field1.toLowerCase();
      } else if (typeof field1 === "undefined") {
        field1 = "";
      }
      if (typeof field2 === "string") {
        field2 = field2.toLowerCase();
      } else if (typeof field2 === "undefined") {
        field2 = "";
      }

      if (!sortDirection) {
        //descending
        return this.sort(field2, field1);
      } else {
        //ascending
        return this.sort(field1, field2);
      }
    });

    this.setState({
      items: sorted,
      rows: this.getPageItems( this.state.currentPage, sorted ),
      isAscendingOrder: !sortDirection,
      sortedColumn: field
    });
  };

  onChangePage = (rows, pager) => {
    this.setState({ rows: rows, pager: pager });
 };

  render() {
    const {id, headers, callback, RowComponent, onSelectAll, selectAllChecked, pageSize, deleteItem, updateLineNote, showOrderDetailPdf, deliveryDays, TotalRow, onCheckboxChange, itemOptions, filterTypes, messages} = this.props;
    const {items, rows, sortedColumn, isAscendingOrder} = this.state;
    const isSelectAll = onSelectAll ? true : false;
    const wrapperParams = {
      className: "pad-left-5 pad-right-5"
    };
    const tableParams = {
      className: "table table-responsive table-hover",
      id: id
    };
    const headerParams = {};
    const bodyParams = {};

    if (this.props.wrapperClassName) {
      wrapperParams['className'] = wrapperParams['className'] + " " + this.props.wrapperClassName;
    }
    if (this.props.tableClassName) {
      tableParams['className'] = tableParams['className'] + " " + this.props.tableClassName;
    }
    if (this.props.headerClassName) {
      headerParams['className'] = this.props.headerClassName;
    }
    if (this.props.bodyClassName) {
      bodyParams['className'] = this.props.bodyClassName;
    }
    if(filterTypes !== undefined)
    {
      let cssClassHeight = getCssClassHeightCalc(messages, filterTypes, pageSize, TotalRow);
      if(cssClassHeight !== "")
      {
        if(bodyParams.className){
          bodyParams['className'] = bodyParams['className'] + " " + cssClassHeight;
        } else {
          bodyParams['className'] = cssClassHeight;
        }
      }
      else if(!pageSize || pageSize <= 0){
        const styleName = "noPaging" + (TotalRow ? "-totalRow" : "");
  
        if(bodyParams.className){
          bodyParams['className'] = bodyParams['className'] + " " + styleName;
        } else {
          bodyParams['className'] = styleName;
        }
      }
    }        

    return (
      <div {...wrapperParams}>
        <table {...tableParams}>
          <thead {...headerParams}>
            <tr>
              {isSelectAll ? (
                <th width="40px" style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    className="pull-left"
                    style={{ width: "22px" }}
                    checked={selectAllChecked}
                    onChange={onSelectAll}
                  />
                </th>
              ) : null}
              {headers.map((header, index) => {
                return (
                  <Header key={index}
                          th={header}
                          onColumnSort={this.onColumnSort}
                          sortedColumn={sortedColumn}
                          isAscendingOrder={isAscendingOrder}/>
                );
              })}
            </tr>
          </thead>
          <tbody {...bodyParams}>
            {rows && rows.length > 0 ? (
              rows.map((row, index) => {
                let rowProc = row;
                rowProc['rowIndex'] = index;
                return (
                  <RowComponent
                    key={index}
                    data={rowProc}
                    callback={callback}
                    deleteItem={deleteItem}
                    updateLineNote={updateLineNote}
                    showOrderDetailPdf={showOrderDetailPdf}
                    deliveryDays={deliveryDays}
                    onCheckboxChange={onCheckboxChange}
                    headers={headers}
                    itemOptions={itemOptions}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan="4" className="noItemsCell">No Items to display</td>
              </tr>
            )}

            {
              rows && rows.length > 0 && TotalRow ? (
                <TotalRow rows={rows} headers={headers} />
              ) : null
            }
          </tbody>
        </table>
        {pageSize > 0 && items && items.length > 0
        ? <Pagination items={items} pageSize={pageSize} onChangePage={this.onChangePage}/>
        : null}
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state),
    messages: selectors.getActiveMessages(state)
  };
};

export default connect(mapStateToProps)(Table);
