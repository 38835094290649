
import { createSelector } from 'reselect';
import { sortItems, isNullorUndefined } from '../common/utils/generalFunctions';
import moment                 from 'moment';

export const orderGuides = (state) => {
  if(state && state.orderGuides){
    return state.orderGuides;
  }
  return [];
};

export const orderGuideItems = (state) => {
  if(state && state.orderGuideItems){
    return state.orderGuideItems;
  }
  return [];
};

export const getEditableOrderGuideItems = (state) => {
  if (state && state.editableOrderGuideItems) {
    return state.editableOrderGuideItems;
  }
  return [];
};

export const getShoppingCartItems = (state) => {
  if (state && state.shoppingCart) {
    const {shoppingCart, orderGuideItems} = state;
    const checkedItems = shoppingCart.map(x => ({...x}));
    if(!isNullorUndefined(orderGuideItems)){
        checkedItems.forEach(function(checkItem){
        let foundItem = orderGuideItems.find(function(el){
          return (el.masterRecordId === checkItem.masterRecordId) && (el.sequenceNumber !== checkItem.sequenceNumber);
        });
        if(!isNullorUndefined(foundItem)){
          checkItem.sequenceNumber = foundItem.sequenceNumber;
        }
      });
    }
    return checkedItems; //state.shoppingCart;
  }
  return [];
};

export const getVendor = (state) => {
  if (state && state.vendor) {
    return state.vendor;
  }
  return {};
};

export const getElementPresentationProps = (state) => {
  if (state && state.elementPresentation) {
    return state.elementPresentation;
  }
  return {};
};

export const getSelectedOrderGuide = (state) => {
  if (state && state.elementPresentation) {
    return state.elementPresentation.selectedOrderGuide;
  }
  return {};
};

export const getAccount = (state) => {
  if (state && state.authProfile) {
    return state.authProfile;
  }
  return {};
};

export const getPreviousOrders = (state) => {
  if (state && state.previousOrders && state.previousOrders.length > 0) {
    return state.previousOrders;
  }
  return [];
};

export const getPreviousOrdersStartDate = (state) => {
  if (state && state.previousOrdersStartDate && state.previousOrdersStartDate.toString().length > 0) {
    return state.previousOrdersStartDate;
  }

  return moment();
};

export const getPreviousOrdersEndDate = (state) => {
  if (state && state.previousOrdersEndDate && state.previousOrdersEndDate.toString().length > 0) {
    return state.previousOrdersEndDate;
  }

  return moment();
};

export const getPreviousOrdersFilteredOrders = (state) => {
  if (state && state.previousOrdersFilteredOrders && state.previousOrdersFilteredOrders.length > 0){
    return state.previousOrdersFilteredOrders;
  }

  return state.previousOrders;
};

export const getPreviousOrdersFilterText = (state) => {
  if (state && state.previousOrdersFilterText && state.previousOrdersFilterText.length > 0) {
    return state.previousOrdersFilterText;
  }

  return "";
};

export const getHeaderId = (state) => {
  if (state && state.purchaseOrder) {
    return state.purchaseOrder.headerId;
  }
  return 0;
};

export const getReports = (state) => {
  if (state && state.reports && state.reports.length > 0) {
    return state.reports;
  }
  return [];
};

export const getReportsStartDate = (state) => {
  if (state && state.reportsStartDate && state.reportsStartDate.toString().length > 0) {
    return state.reportsStartDate;
  }

  return moment();
};

export const getReportsEndDate = (state) => {
  if (state && state.reportsEndDate && state.reportsEndDate.toString().length > 0) {
    return state.reportsEndDate;
  }

  return moment();
};

export const getVendorItems = (state) => {
  if (state && state.vendorItems && state.vendorItems.length > 0) {
    return state.vendorItems;
  }
  return [];
};

export const getOrderGuideItemsPdf = (state) => {
  if (state && state.pdf && state.pdf.orderGuideItems) {
    return state.pdf.orderGuideItems;
  }
  return '';
};

export const getEditableOrderGuideItemsPdf = (state) => {
  if (state && state.pdf && state.pdf.editableOrderGuideItems) {
    return state.pdf.editableOrderGuideItems;
  }
  return '';
};

export const getPreviousOrdersPdf = (state) => {
  if (state && state.pdf && state.pdf.previousOrders) {
    return state.pdf.previousOrders;
  }
  return '';
};

export const getReportsPdf = (state) => {
  if (state && state.pdf && state.pdf.reports) {
    return state.pdf.reports;
  }
  return '';
};

export const getConceptId = (state) => {
  if (state && state.authProfile && state.authProfile.conceptId) {
    return state.authProfile.conceptId;
  }
  return 0;
};

export const getConceptAttributes = (state) => {
  if (state && state.conceptAttributes && state.conceptAttributes.length > 0) {
    return state.conceptAttributes;
  }

  return [];
};

export const getOrderDetailPdf = (state) => {
  if (state && state.pdf && state.pdf.previousOrderDetail) {
    return state.pdf.previousOrderDetail;
  }
  return '';
};

export const getReportDetailPdf = (state) => {
  if (state && state.pdf && state.pdf.reportDetail) {
    return state.pdf.reportDetail;
  }
  return '';
};

export const getConceptVendorCode = (state) => {
  if (state && state.conceptVendorCode) {
    return state.vendorLocation;
  }
  return {};
};

export const getVendorId = (state) => {
  if (state && state.elementPresentation && state.elementPresentation.selectedOrderGuide && state.elementPresentation.selectedOrderGuide.vendorId) {
    return state.elementPresentation.selectedOrderGuide.vendorId;
  }
  return 0;
};

export const getShipperCode = (state) => {
  if (state && state.conceptVendorCode) {
    return state.conceptVendorCode.shipperCode;
  }
  return '';
};

export const getVendorLocation = (state) => {
  if (state && state.vendorLocation) {
    return state.vendorLocation;
  }
  return {};
};

export const getSelectedShipDate = (state) => {
  if (state && state.elementPresentation) {
    return state.elementPresentation.selectedShipDate;
  }
  return '';
};

export const getSelectedDeliveryDate = (state) => {
  if (state && state.elementPresentation) {
    return state.elementPresentation.selectedDeliveryDate;
  }
  return '';
};

export const getSelectedOnHandOnReceivedDays = (state) => {
  if (state && state.elementPresentation) {
    return state.elementPresentation.selectedOnHandOnReceivedDays;
  }
  return [];
};

export const getOrderGuideItemsExcel = (state) => {
  if (state && state.excel && state.excel.orderGuideItems) {
    return state.excel.orderGuideItems;
  }
  return '';
};

export const getEditableOrderGuideItemsExcel = (state) => {
  if (state && state.excel && state.excel.editableOrderGuideItems) {
    return state.excel.editableOrderGuideItems;
  }
  return '';
};

export const getPreviousOrdersExcel = (state) => {
  if (state && state.excel && state.excel.previousOrders) {
    return state.excel.previousOrders;
  }
  return '';
};

export const getReportExcel = (state) => {
  if (state && state.excel && state.excel.reports) {
    return state.excel.reports;
  }
  return '';
};

export const getReportDetailExcel = (state) => {
  if (state && state.excel && state.excel.reportDetail) {
    return state.excel.reportDetail;
  }
  return '';
};

export const getOrderDetailExcel = (state) => {
  if (state && state.excel && state.excel.previousOrderDetail) {
    return state.excel.previousOrderDetail;
  }
  return '';
};

export const getUserCredentials = (state) => {
  if (state && state.userCredentials) {
    return state.userCredentials;
  }
  return undefined;
};

export const getSortedOrderGuide = createSelector(
  [ orderGuideItems ],
  (guideItems) => {
    let sortConfig = [{field:'sequenceNumber', direction:'asc-int-1'}, {field:'description', direction:'asc'}];

    let returnObj = sortItems( guideItems, sortConfig );

    return returnObj;
  }
);

export const getSortedEditableOrderGuide = createSelector(
  [ getEditableOrderGuideItems ],
  (guideItems) => {
    let sortConfig = [{field:'sequenceNumber', direction:'asc-int-1'}, {field:'description', direction:'asc'}];

    let returnObj = sortItems( guideItems, sortConfig );

    return returnObj;
  }
);

export const getSortedShoppingCart = createSelector(
  [ getShoppingCartItems ],
  (guideItems) => {
    let sortConfig = [{field:'sequenceNumber', direction:'asc-int-1'}, {field:'description', direction:'asc'}];

    let returnObj = sortItems( guideItems, sortConfig );

    return returnObj;
  }
);

export const getAccountId = (state) => {
  if (state && state.accountId) {
    return state.accountId;
  }
  return 0;
};

export const getRelatedAccounts = (state) => {
  if (state && state.relatedAccounts) {
    return state.relatedAccounts;
  }
  return undefined;
};

export const getActiveMessages = (state) => {
  if (state && state.messages && state.messages.length > 0) {
    return state.messages;
  }

  return [];
};
