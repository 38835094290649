import React from "react";
import PropTypes from 'prop-types';

export const ActionBar = ({ children, conceptColor }) => {
  return (
    <div className="action-bar" style={{borderTop: `1px solid ${conceptColor}`, borderBottom: `1px solid ${conceptColor}`}}>
        <ul className="action-list">
          {children &&
            children.map((child, index) => {
              if( child !== null ){
                return (
                  <li key={index} className="action-list-item">
                    {child}
                  </li>
                );
              }
              return null;
            })}
        </ul>
    </div>
  );
};

ActionBar.propTypes = {
  children: PropTypes.array,
  conceptColor: PropTypes.string
};

export * from './action-button';
export * from './action-date-picker';
