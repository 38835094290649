import * as actionTypes from '../actions/actionTypes';
import initialState     from './initialState';

export default function update(state = initialState.userCredentials, action) {
  if (action.type === actionTypes.SEND_FORGOT_USERNAME_EMAIL_COMPLETE) {
    return {
      ...state.userCredentials,
      isUsernameFound: action.payload.isUsernameFound
    };
  } else {
    return state;
  }
}
