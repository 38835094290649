import update from 'immutability-helper';
import initialState from './initialState';
import * as actions from '../actions/actionTypes';

export default function updateExcelFiles(state = initialState.excel, action) {
  switch (action.type) {
    case actions.GET_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE:
      return update(state, { orderGuideItems: { $set: action.payload.file }});
    case actions.GET_EDITABLE_ORDER_GUIDE_ITEMS_EXCEL_COMPLETE:
      return update(state, { editableOrderGuideItems: { $set: action.payload.file }});
    case actions.GET_PREVIOUS_ORDERS_EXCEL_COMPLETE:
      return update(state, { previousOrders: { $set: action.payload.file }});
    case actions.GET_REPORTS_EXCEL_COMPLETE:
      return update(state, { reports: { $set: action.payload.file }});
    case actions.GET_ORDER_DETAIL_EXCEL_COMPLETE:
      return update(state, { previousOrderDetail: { $set: action.payload.file }});
    case actions.GET_REPORT_DETAIL_EXCEL_COMPLETE:
      return update(state, { reportDetail: { $set: action.payload.file }});
    default:
      return state;
  }
}
