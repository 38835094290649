import React                from 'react';
import PropTypes            from 'prop-types';
import { Route, Redirect }  from 'react-router-dom';
import authInit             from '../../auth/authInit';
import Header               from './Header';
import Footer               from './Footer';
import { useSelector } from 'react-redux';
import { getStyleHeightCalcMainContent } from './../../common/utils/generalFunctions';

export const AuthenticatedRoute = ({Component, ...rest}) => {
  const messages = useSelector(state => state.messages) || [];
  const cssClassheightCalc = getStyleHeightCalcMainContent(messages);

  return (
    <React.Fragment>
      <Header {...rest} />
        <div className={`${cssClassheightCalc}`}>

          <Route {...rest} render={(props) => authInit.isAuthenticated() === true
              ? <Component {...props} {...rest} />
              : <Redirect to="/Login" />} />

        </div>
      <Footer {...rest}/>
    </React.Fragment>
  );
};

AuthenticatedRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  authenticated: PropTypes.bool
};
