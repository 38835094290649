import {MP_SHOW_TOASTR_MESSAGE} from '../actions/actionTypes';
import {displayMessage} from '../common/utils/toastrFunctions';

export default function update(state = {}, action) {
  switch (action.type) {
    case MP_SHOW_TOASTR_MESSAGE:
      displayMessage(action.payload.message);
      break;
    default:
      break;
  }

  return state;
}
