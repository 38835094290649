import {TOKEN_IS_EXPIRED} from '../actions/actionTypes';

const initialState = {
  isExpired: false,
  reAuthHandler: () => {
    return;
  }
};

export default function update(state = initialState, action) {

  if (action.type === TOKEN_IS_EXPIRED) {

    return Object.assign({}, state, {
      isExpired: true,
      reAuthHandler: action.payload.reAuthHandler
    });

  } else {
    return state;
  }
}
