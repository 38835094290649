import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect }             from 'react-router-dom';
import { ActionBar } from "../common/action-bar";
import * as selectors from "./../../selectors";
import * as supportActions from '../../actions/mpOnlineActions';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';

export class Support extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    supportActions: PropTypes.object,
    vendorLocation: PropTypes.object,
    selectOrderGuideHeader: PropTypes.object,
    conceptVendorCode: PropTypes.object,
    conceptId: PropTypes.number,
    vendorId: PropTypes.number,
    shipperCode: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      vendorLocation: {
        name: this.props.vendorLocation.name,
        addressLine1: this.props.vendorLocation.addressLine1,
        addressLine2: this.props.vendorLocation.addressLine2,
        city: this.props.vendorLocation.city,
        state: this.props.vendorLocation.state,
        zip: this.props.vendorLocation.zip,
        eMail: this.props.vendorLocation.eMail,
        phone: this.props.vendorLocation.phone
      }
    };
  }

  componentDidMount()  {
    this.props.supportActions.getConceptVendorCode(this.props.conceptId, this.props.vendorId);
  }

  //  Deprecated - May need an update in the future
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.shipperCode !== nextProps.shipperCode && nextProps.shipperCode !== '') {
      this.props.supportActions.getVendorLocation(nextProps.shipperCode, this.props.account.accountId);
    }
    if (this.props.vendorLocation !== nextProps.vendorLocation && nextProps.vendorLocation.name !== "") {
      this.setState({
        vendorLocation: nextProps.vendorLocation
      });
    }
  }

  render() {
    const {account} = this.props;
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const vendorLocation =  this.state.vendorLocation;

    const showComma = (vendorLocation !== null && vendorLocation.city !== null && vendorLocation.city.length > 0);
    const showPeriod = (vendorLocation !== null &&vendorLocation.state !== null && vendorLocation.state.length > 0);
    const vendorLocationCity = `${vendorLocation.city}${showComma ? ',' : ''} ${vendorLocation.state}${showPeriod ? '.' : ''} ${vendorLocation.zip}`;

    if (isEmpty(account)) {
      return (<Redirect to="/"/>);
    }

    return (
      <div className="container-fluid">
        <ActionBar conceptColor={color}>
          <span style={{color: "#f3f3f3"}}>.</span>
          <span style={{color: "#f3f3f3"}}>.</span>
        </ActionBar>

      {vendorLocation ?

        <div className="col-md-12">
          <h3 style={{paddingLeft: "14px"}}>Managed Procurement</h3>
          {/* SEPARATOR LINE */}

          <span className="col-md-12"><hr /></span>
          <div className="col-md-3">
            <h4>Vendor Mail</h4>
            <p>{vendorLocation.name}</p>
            <p>{vendorLocation.addressLine1}</p>
            <p>{vendorLocationCity}</p>
          </div>

          <div className="col-md-3">
            <h4>Vendor Email</h4>
            <p><a href="mailto:{vendorLocation.eMail}">{vendorLocation.eMail}</a></p>
          </div>

          <div className="col-md-3">
            <h4>Vendor Phone</h4>
            <p>{vendorLocation.phone}</p>
          </div>

          <div className="col-md-3">
            <h4>CH Robinson Contact</h4>
            <p>Call us at: 559-741-2115</p>
            <p>Email us at: <a href="mailto:MPSTeam@chrobinson.com">MPSTeam@chrobinson.com</a></p>
          </div>

          {/* SEPARATOR LINE */}
          <span className="col-md-12"><hr /></span>
        </div>: null
    }
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    account: selectors.getAccount(state),
    selectOrderGuideHeader: selectors.getSelectedOrderGuide(state),
    vendorLocation: selectors.getVendorLocation(state),
    conceptVendorCode: selectors.getConceptVendorCode(state),
    conceptId: selectors.getConceptId(state),
    vendorId: selectors.getVendorId(state),
    shipperCode: selectors.getShipperCode(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    supportActions: bindActionCreators(supportActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
