import {GET_CONCEPTVENDORCODE_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.conceptVendorCode, action) {
  let conceptVendorCode;
  if (action.type === GET_CONCEPTVENDORCODE_COMPLETE) {
      conceptVendorCode = action.payload.conceptVendorCode ? action.payload.conceptVendorCode[0] : [...state];
  }

  return conceptVendorCode ? conceptVendorCode : {...state};
}
