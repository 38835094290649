import {MP_GET_VENDOR_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.vendor, action) {
  if (action.type === MP_GET_VENDOR_COMPLETE) {
    return action.payload.vendor;
  } else {
    return state;
  }
}
