export function download(data, fileName) {
  const mediaType = getMediaType(fileName);

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    downloadFromInternetExplorer(data, mediaType, fileName);
  } else {
    downloadFile(data, mediaType, fileName);
  }
}

/*
  The following snippet is the only working example I could find
  for downloading PDFs in Internet Explorer; There maybe a better way:
  https://stackoverflow.com/questions/43070627/internet-explorer-fails-opening-a-pdf-string-file

  Documentations for Blob, atob, and Uint8Array
  https://developer.mozilla.org/en-US/docs/Web/API/Blob
  https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Uint8Array
*/

function downloadFromInternetExplorer(data, mediaType, fileName) {
  const byteCharacters = atob(data);
  const byteNumbers = [];

  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: mediaType});
  window.navigator.msSaveOrOpenBlob(blob, fileName);
}

function downloadFile(data, mediaType, fileName) {
  let element = document.createElement('a');
  element.setAttribute('id', 'downloadLink');
  element.setAttribute('download', fileName);
  element.setAttribute('href', `data:${mediaType};base64,${data}`);
  element.click();
}

function getMediaType(fileName) {
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1)
                            .toLowerCase();

  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'xlsx':
      return 'application/vnd.ms-excel';
    default:
      return 'UNKNOWN MEDIA TYPE';
  }
}
