import React                from 'react';
import PropTypes            from 'prop-types';
import { Route, Redirect }  from 'react-router-dom';
import authInit             from '../../auth/authInit';
import HeaderNoMenu         from './HeaderNoMenu';
import Footer               from './Footer';

export const AuthenticatedRouteNoHeader = ({Component, ...rest}) => {
  return (
    <React.Fragment>
      <HeaderNoMenu {...rest} />
      <div className="main-content">
        <Route {...rest} render={(props) => authInit.isAuthenticated()
            ? <Component {...props} {...rest} />
            : <Redirect to="/Login" />} />

      </div>
      <Footer {...rest}/>
    </React.Fragment>
  );
};

AuthenticatedRouteNoHeader.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  authenticated: PropTypes.bool
};
