import React                  from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { Redirect }             from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import moment                 from 'moment';
import DatePicker             from 'react-datepicker';

import { ActionBar, ActionButton, ActionDatePicker }  from '../common/action-bar';
import { TotalRow }                                   from './total-row';
import { OrderReportRow }                             from './order-report-row';
import * as selectors                                 from './../../selectors';
import * as reportsActions                            from '../../actions/mpOnlineActions';
import * as documentActions                           from '../../actions/documentActions';
import * as authProfileActions                        from '../../actions/authProfileActions';
import * as documentHelpers                           from '../../common/utils/documentHelpers';
import { MessageType, MessageLocation }                                from './../../common/utils/stringConstants';
import { filterItemsV2, getFormattedSearchDate }      from './../../common/utils/generalFunctions';
import Table                                          from './../../components/common/table';
import { ClearableSearchBar } from '../common/searchBoxWithClear';

const headers = [
  { header: "Store #",          className: "setWidth80",                                name: "storeNumber" },
  { header: "CHR Ref. Number",  className: "setWidth300",                               name: "customerReferenceCode" },
  { header: "Vendor Number",    className: "setWidth200",                               name: "invoiceNumber" },
  { header: "Delivery Date",    className: "table-cell-center setWidth140",             name: "deliveryDate" },
  { header: "Amount",           className: "table-cell-center setWidth100 isCurrency",  name: "amount",                   checkProcessed: true},
  { header: "Processed",        className: "table-cell-center setWidth120",              name: "isProcessed" },
  { header: "Processed Date",   className: "table-cell-center setWidth160",             name: "processedDateDisplay" },
  { header: "Invoice",          className: "table-cell-center setWidth90",              name: "isEdi810Sent" },
  { header: "Invoice Date",     className: "table-cell-center setWidth140",             name: "edi810SentDate" },
  { header: "Error",            className: "table-cell-center setWidth90",              name: "hasError" },
  { header: "Void",             className: "table-cell-center setWidth90",              name: "isVoid" },
  { header: "Received",         className: "table-cell-center setWidth140",             name: "createdDate" }
];

export class Reports extends React.Component {
  static propTypes = {
    vendor: PropTypes.object,
    reports: PropTypes.array,
    reportsStartDate: PropTypes.object,
    reportsEndDate: PropTypes.object,
    reportsFilterText: PropTypes.string,
    reportsFilteredReports: PropTypes.array,
    account: PropTypes.object,
    reportsPdf: PropTypes.string,
    reportDetailPdf: PropTypes.string,
    reportsExcel: PropTypes.string,
    reportDetailExcel: PropTypes.string,
    reportsActions: PropTypes.object,
    documentActions: PropTypes.object,
    authProfileActions: PropTypes.object,
    orderGuides: PropTypes.array,
    orderGuideItems: PropTypes.array,
    orderGuideActions: PropTypes.object,
    selectedOrderGuide: PropTypes.object,
    selectedDeliveryDate: PropTypes.string,
    setParentState: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      filterText: (props.reportsFilterText) ? props.reportsFilterText : '',
      endDate: (props.reportsEndDate) ? props.reportsEndDate : moment().format(),
      startDate: (props.reportsStartDate),
      filteredReports: (props.reportsFilteredReports && props.reportsFilteredReports.length > 0) ? props.reportsFilteredReports : props.reports,
      deliveryDate: moment().format()
    };

    this.ClearTextField = this.ClearTextField.bind(this);
  }

  componentDidMount() {
    if (!this.props.account || !this.props.account.accountId) {
      if(this.props.authProfileActions){
        this.props.authProfileActions.retrieveProfile();
      }
    }

    if (this.orderGuideHasBeenSelected()) {
      this.setDeliveryDate();
      const {orderGuideHeaderId} = this.props.selectedOrderGuide;
      this.props.orderGuideActions.getOrderGuideItems(orderGuideHeaderId);
    }

    this.searchReports();
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account && nextProps.account.accountId) {
      this.props.orderGuideActions.orderGuide(nextProps.account.accountId);
    }

    if (nextProps.orderGuides !== this.props.orderGuides) {
      const defaultOrderGuide = this.getDefaultOrderGuide(nextProps.orderGuides);

      if (isUndefined(defaultOrderGuide)) {
        return;
      }

      let conceptId = undefined;
      if ((this.props.account && this.props.account.conceptId !== nextProps.account.conceptId) || nextProps.vendor) {
        if(!isUndefined(nextProps.account.conceptId)) {
          conceptId = nextProps.account.conceptId;
        }
      }

      this.props.orderGuideActions.getOrderGuideItems(defaultOrderGuide.orderGuideHeaderId);
      this.props.orderGuideActions.getVendor(defaultOrderGuide.vendorId, conceptId);
      this.props.orderGuideActions.setSelectedOrderGuide(defaultOrderGuide);
      this.props.orderGuideActions.setDeliveryDate(defaultOrderGuide.deliveryDate);

      this.setState({
        orderGuides: nextProps.orderGuides,
        selectedOption: defaultOrderGuide
      });
    }

    if (this.props.reports !== nextProps.reports) {
      this.setState({filteredReports: nextProps.reports});
    }

    this.downloadPdf(nextProps);
    this.downloadDetailPdf(nextProps);
    this.downloadExcel(nextProps);
    this.downloadDetailExcel(nextProps);
  }

  filter = (event) => {
    const {reports} = this.props;
    const inputValue = event.target.value.toLowerCase().trim();

    if (inputValue === "") {
      this.setState({
        filterText: event.target.value,
        filteredReports: reports
      });
      return;
    }

    const matchedItems = filterItemsV2(reports, inputValue);

    this.setState({
      filterText: event.target.value,
      filteredReports: matchedItems
    });

    this.props.setParentState({reportsFilterText: event.target.value});
    this.props.setParentState({reportsFilteredReports: matchedItems});
  };

  setDate = (date, key) => {
    this.setState({[key]: date});
  };

  searchReports = () => {
    const {accountId} = this.props.account;
    const endDate = getFormattedSearchDate(this.state.endDate, "end"); //.format("MM-DD-YYYY");
    const startDate = getFormattedSearchDate(this.state.startDate, "start"); //.format("MM-DD-YYYY");

    if (moment(startDate) > moment(endDate)) {
      this.displayToastrMessage(MessageType.ERROR, 'Start date cannot be after end date');
      return;
    }

    this.props.reportsActions.searchReports(accountId, startDate, endDate);
  };

  displayToastrMessage = (type, text) => {
    const message = { type, text };
    this.props.reportsActions.showToastrMessage(message);
  };

  retrievePdf = () => {
    const {startDate, endDate} = this.state;
    this.props.documentActions.getReportsPdf(getFormattedSearchDate(startDate, "start"), getFormattedSearchDate(endDate, "end"), this.props.account.accountId);
  };

  downloadPdf = (nextProps) => {
    if (nextProps.reportsPdf !== this.props.reportsPdf) {
      const pdf = nextProps.reportsPdf;
      documentHelpers.download(pdf, 'Reports.pdf');
    }
  };

  retrieveDetail = (row, fileType) => {
    const {account, vendor} = this.props;

    if (fileType === 'pdf') {
      this.props.documentActions.getReportDetailPdf(account.accountId, row.invoiceId);
    } else {
      this.props.documentActions.getReportDetailExcel(account.accountId, row.invoiceId, vendor.vendorName);
    }
  };

  downloadDetailPdf = (nextProps) => {
    if (nextProps.reportDetailPdf !== this.props.reportDetailPdf) {
      const pdf = nextProps.reportDetailPdf;
      documentHelpers.download(pdf, 'ReportDetail.pdf');
    }
  };

  retrieveExcel = (row) => {
    const {startDate, endDate} = this.state;
    this.props.documentActions.getReportsExcel(getFormattedSearchDate(startDate, "start"), getFormattedSearchDate(endDate, "end"), this.props.account.accountId);
  };

  downloadExcel = (nextProps) => {
    if (nextProps.reportsExcel !== this.props.reportsExcel) {
      const excel = nextProps.reportsExcel;
      documentHelpers.download(excel, 'Reports.xlsx');
    }
  };

  downloadDetailExcel = (nextProps) => {
    if (nextProps.reportDetailExcel !== this.props.reportDetailExcel) {
      const excel = nextProps.reportDetailExcel;
      documentHelpers.download(excel, 'ReportDetail.xlsx');
    }
  };

  ClearTextField = (e) => {
    if(e.currentTarget.onclick){
      const {reports} = this.props;
      this.setState({
        filterText: '',
        filteredReports: reports
      });

      this.props.setParentState({reportsFilterText: ''});
      this.props.setParentState({reportsFilteredReports: reports});
    }
  };

  // Following (and related config/props/states) should get refactored from each guide page to a central location/call
  setDeliveryDate = () => {
    const {selectedDeliveryDate} = this.props;
    this.setState({deliveryDate: moment(selectedDeliveryDate)});
  };

  orderGuideHasBeenSelected = () => {
    return !isEmpty(this.props.selectedOrderGuide);
  };

  getDefaultOrderGuide = (orderGuides) => {
    if (orderGuides) {
      const index = orderGuides.findIndex(x => x.isDefault);
      return index >= 0 ? orderGuides[index] : orderGuides[0];
    }
  };

  render() {
    const {account} = this.props;
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const {endDate, startDate, filteredReports, filterText} = this.state;
    const cssClasses = "fa fa-2x vertical-align-middle";

    if (isEmpty(account)) {
      return (<Redirect to="/"/>);
    }

    return (
      <div className="container-fluid">
        <ActionBar conceptColor={color}>
          <ClearableSearchBar
                filterText={filterText}
                multiFilter={this.filter}
                ClearTextField={this.ClearTextField} />

          <DatePicker   id="dpStartDate"
                        name="startDate"
                        selected={moment.isMoment(startDate) ? startDate.toDate() : startDate}
                        customInput={<ActionDatePicker text="Start Date" />}
                        onChange={(date) => this.setDate(date, "startDate")} />

          <DatePicker   id="dpEndDate"
                        name="endDate"
                        selected={moment.isMoment(endDate) ? endDate.toDate() : endDate}
                        customInput={<ActionDatePicker text="End Date"/>}
                        onChange={(date) => this.setDate(date, "endDate")} />

          <ActionButton id="btnSearch"
                        text="Load Reports"
                        className={`fa-search ${cssClasses}`}
                        clickHandler={this.searchReports} />

          <ActionButton id="btnExcel"
                        text="Export Excel"
                        className={`fa-file-excel-o ${cssClasses}`}
                        clickHandler={this.retrieveExcel} />

          <ActionButton id="btnPdf"
                        text="Export PDF"
                        className={`fa-file-pdf-o ${cssClasses}`}
                        clickHandler={this.retrievePdf} />
        </ActionBar>

        <Table id="tblReports"
                headers={headers}
                items={filteredReports}
                pageSize={10}
                TotalRow={TotalRow}
                RowComponent={OrderReportRow}
                callback={this.retrieveDetail}
                wrapperClassName="tableWrapper"
                tableClassName="fixed_header"
                filterTypes={[MessageLocation.HeaderBanner]} />
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    vendor: selectors.getVendor(state),
    account: selectors.getAccount(state),
    reports: selectors.getReports(state),
    reportsStartDate: selectors.getReportsStartDate(state),
    reportsEndDate: selectors.getReportsEndDate(state),
    reportsPdf: selectors.getReportsPdf(state),
    reportDetailPdf: selectors.getReportDetailPdf(state),
    reportsExcel: selectors.getReportExcel(state),
    reportDetailExcel: selectors.getReportDetailExcel(state),
    orderGuides: selectors.orderGuides(state),
    orderGuideItems: selectors.getSortedOrderGuide(state),
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    reportsActions: bindActionCreators(reportsActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
    orderGuideActions: bindActionCreators(reportsActions, dispatch),
    authProfileActions: bindActionCreators(authProfileActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
