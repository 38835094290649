import {SET_ACCOUNTID} from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function update(state = initialState.accountId, action) {
  let newState = state;

  if (action.type === SET_ACCOUNTID) {
    newState = action.payload.accountId;
  }

  return newState;
}
