import React from "react";
import PropTypes from "prop-types";
import * as generalFunctions from "../../common/utils/generalFunctions";

export const OrderConfirmation = (props) => {
  const { headerId, shipDate, deliveryDate, printButtonHandler, shipDateVisible, deliveryDateVisible } = props;

  return (
    <div className="panel panel-default height_100percent" id="orderConfirmation">
      <div className="panel-heading">Order Confirmation</div>
      <div className="panel-body order-summary">

        <div className="col-sm-12">
          <p id="confirmation">
            Order No. { headerId } has been created.
          </p>
          {shipDateVisible &&
            <div>Your requested ship date is { generalFunctions.getFormattedDisplayDate(shipDate) }.</div>}
          {deliveryDateVisible &&
            <p>Your requested arrival date is { generalFunctions.getFormattedDisplayDate(deliveryDate) }.</p>}
          <p>
            Please click the Print Order buttton to print or export the current order.
          </p>
        </div>

        {/* SEPARATOR LINE */}
        <span className="col-md-12"><hr/></span>

        <div className="pull-right">
          <button className="btn btn-primary" id="printOrder" onClick={() => printButtonHandler(headerId)}>Print Order</button>
        </div>

      </div>
  </div>
  );
};

OrderConfirmation.propTypes = {
  headerId: PropTypes.number.isRequired,
  shipDate: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
  shipDateVisible: PropTypes.bool.isRequired,
  deliveryDateVisible: PropTypes.bool.isRequired,
  printButtonHandler: PropTypes.func.isRequired
};
