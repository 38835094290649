import React        from "react";
import PropTypes    from "prop-types";
import { connect }  from "react-redux";
import auth         from '../../auth/authInit';
import defaultImage from '../../assets/images-60/default.png';
import { MessageLocation } from './../../common/utils/stringConstants';
import { Banner } from '../messages/banner';
import _ from "lodash";

export class Header extends React.Component {
  static propTypes = {
    vendor: PropTypes.object,
    account: PropTypes.object,
    history: PropTypes.object,
    messages : PropTypes.array,
    logout: PropTypes.func
  };

  state = {
    image: defaultImage
  };

  componentDidMount(){
    this.getImage();
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.account, this.props.account)) {
      this.getImage();
    }
  }

  getImage = async () => {
    let image;
    const imageName = this.getImageName();
    try {
      image = await import("../../assets/images-60/".concat(imageName));
    } catch (e) {
      console.error(e);
      image = await import("../../assets/images-60/".concat('default.png'));
    }
    this.setState({
      image: image.default
    });
  };

  getImageName = () => {
    const {hostUrl} = this.props.account;
    return hostUrl !== undefined ? hostUrl.toLowerCase().concat('-header.png') : 'default.png';
  };

  isRouteActive = route => {
    const  hash = window.location.hash.replace('#/', '');
    return hash === route || (hash === '' && route === 'Order-Guide') ? "active" : "";
  };

  getMenuItems = () => {
    return [
      {
        route: "Order-Guide",
        display: "Order Guide"
      },
      {
        route: "Edit-Order-Guide",
        display: "Edit Order Guide"
      },
      {
        route: "Previous-Orders",
        display: "Previous Orders"
      },
      {
        route: "Reports",
        display: "Reports"
      },
      {
        route: "Support",
        display: "Support"
      }
    ];
  };

  render() {
    const { image } = this.state;
    const menuItems = this.getMenuItems();
    const {vendor, history} = this.props;
    const cutOffTime = vendor && vendor.orderCutoffTimeDisplay ? vendor.orderCutoffTimeDisplay : '';

    return (
      <header>
        <div id="header-container-div" className="header-container">
          <Banner filterType={MessageLocation.HeaderBanner}/>
          {/* LOGO & LINKS */}
          <div>
          <div className="col-md-6 header-col-2">
            <img className="header-logo" src={image} alt="CustomerLogo" />
          </div>
          <div className="col-md-6 header-col-2">
            <span className="pull-right header-links">
                <span id="username">Welcome { this.props.account.name }!</span>
                <span className="pad-left-10">|</span>
                <a href="http://blog.robinsonfresh.com/fresh-from-the-field/" target="_blank" rel="noreferrer" id="marketReport" className="pad-left-10">Market Report</a>
                <span className="pad-left-10">|</span>
                <a id="logout" className="pad-left-10" onClick={this.props.logout}>Logout</a>
            </span>
          </div>
          </div>
        </div>

        {/* MENU-BAR */}
        <div className="col-md-12 menu-bar">
          <div className="col-md-8 pad-left-10">
            <ul className="nav nav-pills">
              {
                menuItems.map((item, index) => {
                  return [
                    <li key={index} className={`${this.isRouteActive(item.route)}`}>
                      <a  id={item.route} onClick={() => history.push(item.route)}>{item.display}</a>
                    </li>
                  ];
                })
              }
            </ul>
          </div>
          <div className="col-md-4 pad-right-0">
            <span className="pull-right order-cutoff-time" id="vendorCutoffTime">Order Cutoff Time: {cutOffTime}</span>
          </div>
        </div>

      </header>
    );
  }
}

export const mapStateToProps = state => {
  return {
    account: state.authProfile
  };
};

export default connect(mapStateToProps)(Header);
