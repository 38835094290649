import React from "react";
import PropTypes from 'prop-types';

export const Header = ({th, sortedColumn, isAscendingOrder, onColumnSort}) => {
  let spanStyle = { userSelect: "none" };
  let headerSettings = {};

  if (th.width) {
    headerSettings['width'] = th.width;
  }
  if (th.className) {
    headerSettings['className'] = th.className;
  }

  if (th.cellStyle) {
    spanStyle = {
      ...spanStyle,
      ...th.cellStyle
    };
  }

  return (
    <th {...headerSettings}>
      <span role={(th.disableSort) ? "" : "button"} style={spanStyle}>
        <span onClick={(th.disableSort) ? null : onColumnSort} id={th.name}>
          {th.header}
        </span>
        {sortedColumn === th.name ? (
          <span
            style={{ marginLeft: "5px" }}
            className={
              isAscendingOrder ? "fa fa-lg fa-chevron-down" : "fa fa-lg fa-chevron-up"
            }
          />
        ) : null}
      </span>
    </th>
  );
};

Header.propTypes = {
  th: PropTypes.object,
  sortedColumn: PropTypes.string,
  isAscendingOrder: PropTypes.bool,
  onColumnSort: PropTypes.func,
  disableSort: PropTypes.bool
};
