import moment from 'moment';
import { isEmptyObject, getConceptAttributeValue, round } from '../../common/utils/generalFunctions';
import { isUndefined } from 'lodash';

export function getPurchaseOrderHeader(props, state) {
  return {
    shipDate: moment(props.selectedShipDate).format('YYYY-MM-DD'),
    deliveryDate: moment(props.selectedDeliveryDate).format('YYYY-MM-DD'),
    orderNote: state.orderNote,
    vendorId: props.vendor.vendorId,
    accountId: props.account.accountId,
    purchaseOrderNumber: state.orderNumber,
    vendorCustomerNumber: props.selectedOrderGuide.vendorCustomerNumber,
    vendorLocationNumber: props.selectedOrderGuide.vendorLocationNumber,
    guideName: props.selectedOrderGuide.guideName
  };
}

export function getPurchaseOrderDetails(props) {
  let purchaseOrderDetails = [];

  props.shoppingCartItems.forEach(item => {
    purchaseOrderDetails.push({
      unit: item.unit,
      price: item.price,
      packSize: item.packSize,
      lineNote: item.lineNote,
      vendorId: item.vendorId,
      itemNumber: item.itemNumber,
      description: item.description,
      quantity: parseFloat(item.order),
      masterRecordId: item.masterRecordId,
      maxPurchaseQuantity: item.maxPurchaseQuantity,
      maxPurchaseQuantityStartDate: item.maxPurchaseQuantityStartDate,
      maxPurchaseQuantityEndDate: item.maxPurchaseQuantityEndDate
    });
  });

  return purchaseOrderDetails;
}

export function getOrderTotal(shoppingCartItems) {
  let total = 0;

  if (shoppingCartItems && shoppingCartItems.length > 0) {
    for (const item of shoppingCartItems) {
      total += round(item.price * item.order, 2);
    }

    return parseFloat(total.toFixed(2));
  }

  return 0;
}

export const isAfterOrderCutoffTime = (dateTimeValue, vendor) => {
  if (isEmptyObject(vendor)) {
    return false;
  }

  const localDateTime = (dateTimeValue) && (dateTimeValue instanceof Date && !isNaN(dateTimeValue)) ? dateTimeValue : new Date();
  const todayDate = localDateTime.getFullYear() + "-" + (localDateTime.getMonth() + 1) + "-" + localDateTime.getDate();
  const timeZoneValueTemp = localDateTime.toString().split('(')[1].split(')')[0].split(' ');
  const localTimeZone = timeZoneValueTemp.map(i => i.charAt(0).toUpperCase()).join('');
  const isDaylightSavings = (localDateTime.toString().indexOf('Daylight') !== -1);
  const vendorTimeZone = isDaylightSavings ? vendor.timeZone.replace('S', 'D') : vendor.timeZone;
  const vendorCutoffTime = vendor.orderCutoffTime;
  const usOffsets = {
    EDT: -4,
    EST: -5,
    CDT: -5,
    CST: -6,
    MDT: -6,
    MST: -7,
    PDT: -7,
    PST: -8,
    AKST: -9,
    AKDT: -8,
    HST: -10,
    HDT: -10
  };

  const baseCutoffTime = moment.utc(moment.duration(vendorCutoffTime).asMilliseconds()).format('HH:mm');

  let vendorCalculatedCutoff = moment(`${todayDate} ${baseCutoffTime}`, "YYYY-MM-DD HH:mm").utcOffset(usOffsets[vendorTimeZone] * 60, true);
  let localCalculatedTime;

  if (localTimeZone === vendorTimeZone) {
    localCalculatedTime = moment((dateTimeValue) && (dateTimeValue instanceof Date && !isNaN(dateTimeValue)) ? dateTimeValue : new Date());
  } else {
    localCalculatedTime = moment().utcOffset(usOffsets[vendorTimeZone] * 60);
  }

  const diffValue = localCalculatedTime.diff(vendorCalculatedCutoff);

  return (diffValue >= 0);
};

export const isDeliveryDay = (date, selectedOrderGuide, conceptAttributes) => {
  const day = date.day();
  const deliveryDays = [];
  const { deliveryDaySchedule } = selectedOrderGuide;

  if (isUndefined(deliveryDaySchedule) || ((conceptAttributes) && getConceptAttributeValue(conceptAttributes, 'MPOIgnoreDelivRoute') === 'true')) {
    // datepicker selection should be available for all days.
    return true;
  }

  // deliveryDaySchedule is a string value that
  // represents the delivery days of the week: e.g. "246", "0135" ...
  deliveryDaySchedule.split("").forEach(x => deliveryDays.push(parseInt(x)));

  // Example for filterDates prop
  // https://reactdatepicker.com/#example-17
  return deliveryDays.includes(day);
};

export const hasSameDayDelivery = (conceptAttributes) => {
  const attributeValue = getConceptAttributeValue(conceptAttributes, 'MPOSameDayDelivery');
  const sameDayDelivery = (isUndefined(attributeValue)) ? false : (attributeValue.toLowerCase() === 'true');

  return sameDayDelivery;
};

export const getNearestDeliveryDate = (incomingDate, orderGuide, vendor, conceptAttributes) => {
  const today = moment();
  let localDate = moment(incomingDate);
  let currentDayIndex = -1;
  let nextDayAppend;
  const deliveryDays = [false, false, false, false, false, false, false];
  const selectedOrderGuide = orderGuide;
  let i;
  let dayIndex = -1;

  if (getConceptAttributeValue(conceptAttributes, 'MPOIgnoreDelivRoute') === 'true') {
    return localDate;
  }

  if (isUndefined(selectedOrderGuide) ||
    isUndefined(selectedOrderGuide.deliveryDaySchedule) ||
    isEmptyObject(conceptAttributes)) {
    // datepicker selection should be available for all days.
    localDate.add(1, 'days');
    return localDate;
  }

  //using startOf each day value because time is checked elsewhere, we only want to compare dates here.
  if (isAfterOrderCutoffTime(localDate, vendor)) {
    today.add(1, 'days');
  }
  if (localDate.startOf('day') <= today.startOf('day')) {
    localDate = moment(today);
    if (!hasSameDayDelivery(conceptAttributes)) {
      localDate.add(1, 'days');
    }
  }

  if (isDeliveryDay(localDate, selectedOrderGuide)) {
    return localDate;
  }

  selectedOrderGuide.deliveryDaySchedule.split("").forEach(x => deliveryDays[parseInt(x)] = true);
  currentDayIndex = localDate.day();

  for (i = currentDayIndex; i < deliveryDays.length; i++) {
    if (deliveryDays[i] === true) {
      dayIndex = i;
      break;
    }
  }

  if (dayIndex === -1) {
    dayIndex = deliveryDays.findIndex(x => x === true);
  }

  if (dayIndex > currentDayIndex) {
    nextDayAppend = dayIndex - currentDayIndex;
  } else if (dayIndex < currentDayIndex) {
    nextDayAppend = 7 - currentDayIndex + dayIndex;
  } else {
    nextDayAppend = 7;
  }

  return localDate.add(nextDayAppend, 'days');
};

export const canSeeRequestedShipDate = (conceptAttributes) => {
  const attributeValue = getConceptAttributeValue(conceptAttributes, 'MPOViewRqstShipDate');

  return isUndefined(attributeValue) || attributeValue.toLowerCase() === "false"
    ? false
    : true;
};

export const canSeeRequestedDeliveryDate = (conceptAttributes) => {
  const attributeValue = getConceptAttributeValue(conceptAttributes, 'MPOVwRqstDeliverDate');

  return isUndefined(attributeValue) || attributeValue.toLowerCase() === "true"
    ? true
    : false;
};
