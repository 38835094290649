import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { orderGuides } from "../../../selectors";

export class Dropdown extends React.Component {
  static propTypes = {
    dropdownId: PropTypes.string,
    selectedOption: PropTypes.object,
    options: PropTypes.array.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    addOnButtonClass: PropTypes.string,
    addOnOptionClass: PropTypes.string
  };

  constructor(props) {
    super(props);

    const emptyOption = {
      key: 0,
      text: '--- Empty ---'
    };

    this.state = {
      options: props.options,
      selectedOption: props.selectedOption ? props.selectedOption : emptyOption,
      isPopupVisible: false,
      isFromSelectChange: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.options && this.props.selectedOption !== nextProps.selectedOption) {
      this.setState({
        options: nextProps.options,
        selectedOption: nextProps.selectedOption
      });
    }
  }

  togglePopup = () => {
    this.setState({isPopupVisible: !this.state.isPopupVisible}, () => this.dropdown.focus());
  };

  blurHandler = () => {
    if( !this.state.isFromSelectChange ){
      this.setState({isFromSelectChange: false, isPopupVisible: false});
    }
  };

  onSelectChange = (option) => {
    this.setState({isFromSelectChange: true, isPopupVisible: false});
    this.props.onSelectionChange(option);
  };

  render() {
    const {isPopupVisible, selectedOption} = this.state;
    const options = this.props.options ? this.props.options : [];
    const {dropdownId} = this.props;

    return (
      <div id={dropdownId} className="dropdown">
        <button type="button"
                id={`${dropdownId}-button`}
                onClick={this.togglePopup}
                className={`btn btn-sm btn-primary-outline dropdown-toggle ${this.props.addOnButtonClass}`}>
          <span className="pad-right-15">{selectedOption.text}</span>
          <span className="caret"/>
        </button>
        <ul tabIndex="0"
            onBlur={this.blurHandler}
            ref={dropdown => this.dropdown = dropdown}
            className={`dropdown-menu${isPopupVisible ? ' expanded' : ''} ${this.props.addOnOptionClass}`} >
            {
              options.map(option => {
                return (
                  <li key={option.key}>
                    <a onClick={() => this.onSelectChange(option)}>{option.text}</a>
                  </li>
                );
              })
            }
        </ul>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    options: orderGuides(state)
  };
};

export default connect(mapStateToProps)(Dropdown);
