
import {authProfileApiAdapter} from '../utils/apiAdapters'; //eslint-disable-line import/named
import {getAccessToken} from '../../auth/auth0Utilities';

class AuthProfileApi {
  static getAuthProfile(incomingPayload) {
    const token = getAccessToken();

    let payload = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {...incomingPayload}
    };

    //TODO: Is there were we can change the call for profile info?
    return new Promise((resolve, reject) => {
      authProfileApiAdapter.get('/Profile', payload)
        .then(response => {
          resolve(response.data.profile);
        })
        .catch((_e) => {
          reject('Error getting auth profile information');
        });
    });
  }
}

export default AuthProfileApi;
