import React from "react";
import PropTypes from 'prop-types';

export class ActionDatePicker extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    text: PropTypes.string
  };

  render() {
    return (
      <button className="btn btn-primary datepicker-button" onClick={this.props.onClick}>
        <span className="fa fa-calendar fa-2x vertical-align-middle" />
        <span className="pad-left-5">{this.props.text} {this.props.value}</span>
      </button>
    );
  }
}
