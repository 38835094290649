import * as at from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.shoppingCart, action) {

  switch (action.type) {
    case at.MP_UPDATE_SHOPPING_CART: {
      const {item} = action.payload;
      const index = state.findIndex(x => x.masterRecordId === item.masterRecordId);
      return [...state.slice(0, index), item, ...state.slice(index + 1)];
    }
    case at.MP_ADD_TO_SHOPPING_CART: {
      const {item} = action.payload;
      return state.concat(item);
    }
    case at.MP_REMOVE_FROM_SHOPPING_CART: {
      const {item} = action.payload;
      return state.filter(x => x.masterRecordId !== item.masterRecordId);
    }
    case at.MP_CLEAR_SHOPPING_CART:
      return [];
    default:
      return state;
  }

}
