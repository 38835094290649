import {GET_CONCEPT_ATTRIBUTES_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.conceptAttributes, action) {
  if (action.type === GET_CONCEPT_ATTRIBUTES_COMPLETE) {
    return action.payload.conceptAttributes;
  } else {
    return state;
  }
}
