import {MP_GET_CONCEPT_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.concept, action) {
  if (action.type === MP_GET_CONCEPT_COMPLETE) {
    return action.payload.concept;
  } else {
    return state;
  }
}
