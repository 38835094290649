import React                              from 'react';
import PropTypes                          from 'prop-types';
import { connect }                        from 'react-redux';
import { Redirect }                       from 'react-router-dom';
import { bindActionCreators }             from 'redux';
import { isEmpty, intersectionBy, isUndefined }        from 'lodash';
import moment                             from 'moment';
import update                             from 'immutability-helper';

import Modal                              from './../modals/modal';
import Table                              from '../common/table';
import { Dropdown }                       from '../common/dropdown';
import { PromptModal }                    from './../modals/prompt-modal';
import { ActionBar, ActionButton }        from '../common/action-bar';
import { FileType, MessageType, MessageLocation }          from './../../common/utils/stringConstants';
import { filterItemsV2, isValidInteger }  from './../../common/utils/generalFunctions';
import * as documentActions               from '../../actions/documentActions';
import * as editOrderGuideActions         from '../../actions/mpOnlineActions';
import * as documentHelpers               from '../../common/utils/documentHelpers';
import * as selectors                     from '../../selectors';
import DefaultOrderGuideModal             from './DefaultOrderGuideModal';
import SetDaysModal                       from './SetDaysModal';
import Row                                from './Row';
import { ClearableSearchBar } from '../common/searchBoxWithClear';

const defaultHeaders = [
  { header: "Seq",            className: "setWidth70",    name: "sequenceNumber", disableSort : true },
  { header: "Item Number",    className: "setWidth120",   name: "itemNumber" },
  { header: "Description",    className: "width300",                                   name: "description" },
  { header: "Unit",           className: "table-cell-center setWidth70",    name: "unit" },
  { header: "Pack",           className: "table-cell-center setWidth70",    name: "packSize" },
  { header: "Price",          className: "table-cell-center setWidth80 isCurrency",    name: "price" },
  { header: "Line Note",      className: "setWidth200",   name: "lineNote" }
];

export class EditOrderGuide extends React.Component {
  static propTypes = {
    orderGuides: PropTypes.array,
    editOrderGuideItems: PropTypes.array,
    documentActions: PropTypes.object,
    editOrderGuideActions: PropTypes.object,
    selectedOrderGuide: PropTypes.object,
    conceptAttributes: PropTypes.array,
    editableOrderGuideItemsPdf: PropTypes.string,
    editableOrderGuideItemsExcel: PropTypes.string,
    selectedOnHandOnReceivedDays: PropTypes.array,
    selectedDeliveryDate: PropTypes.string,
    account: PropTypes.object,
    vendor: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      deletedItems: [],
      isModalVisible: false,
      pageHasChanges: false,
      isSelectAllChecked: false,
      isSetDaysModalVisible: false,
      isDeleteItemPropmptModalVisible: false,
      isDefaultOrderGuideModalVisible: false,
      orderGuides: props.orderGuides ? props.orderGuides : [],
      filteredItems: props.editOrderGuideItems ? props.editOrderGuideItems : [],
      editOrderGuideItems: props.editOrderGuideItems ? props.editOrderGuideItems : [],
      selectedOption: props.selectedOrderGuide
    };

    this.ClearTextField = this.ClearTextField.bind(this);
  }

  componentDidMount() {
    const {orderGuideHeaderId} = this.state.selectedOption;
    this.props.editOrderGuideActions.getEditableOrderGuideItems(orderGuideHeaderId, moment(this.props.selectedDeliveryDate));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.editOrderGuideItems !== nextProps.editOrderGuideItems) {
      this.setState({
        editOrderGuideItems: nextProps.editOrderGuideItems,
        filteredItems: nextProps.editOrderGuideItems
      });
    }

    this.downloadPdf(nextProps);
    this.downloadExcel(nextProps);
  }

  constructHeaders = () => {
    let headers = JSON.parse(JSON.stringify(defaultHeaders));
    const {deliveryDays} = this.state.selectedOption;

    if (deliveryDays && deliveryDays.length > 0) {
      deliveryDays.forEach((day, index) => {
        headers.splice(6 + index, 0, {
          header: day.value,
          //width: "5%",
          className: "table-cell-center setWidth80",
          name: day.value.toLowerCase(),
          disableSort: true
        });
        if (index === 3){
          headers[6+index].cellStyle = { "marginLeft": "-5px" };
        }
      });
    }

    return headers;
  };

  filter = event => {
    const {editOrderGuideItems} = this.state;
    const inputValue = event.target.value.toLowerCase().trim();

    if (inputValue === "") {
      this.setState({
        filterText: event.target.value,
        filteredItems: this.state.editOrderGuideItems
      });
      return;
    }

    const matched = filterItemsV2(editOrderGuideItems, inputValue);

    this.setState({
      filterText: event.target.value,
      filteredItems: matched
    });
  };

  onOrderGuideChange = (option) => {
    this.setState({selectedOption: option});
    this.props.editOrderGuideActions.getEditableOrderGuideItems(option.orderGuideHeaderId, moment(this.props.selectedDeliveryDate));
    this.props.editOrderGuideActions.getVendor(option.vendorId,this.getConceptId(this.props.account));
    this.props.editOrderGuideActions.setSelectedOrderGuide(option);
  };

  onRowValueChange = (event, row) => {
    const key = event.target.name;
    const value = event.target.value;
    const {filteredItems} = this.state;
    const index = filteredItems.findIndex(x => x.masterRecordId === row.masterRecordId);

    if (index < 0) return;

    if (key === 'lineNote') {
      this.updateItem(key, value, index);
      return;
    }

    if (isValidInteger(value)) {
      this.updateItem(key, value, index);
    }
  };

  updateItem = (key, value, index) => {
    const {filteredItems} = this.state;
    let updatedItem = {...filteredItems[index]};

    updatedItem[key] = value;
    updatedItem.hasChanges = true;

    this.setState({
      filteredItems: update(filteredItems, {[index]: {$set: updatedItem}}),
      pageHasChanges: true
    });
  };

  getConceptId = (account) => {
    if(account && !isUndefined(account.conceptId))
    {
      return account.conceptId;
    }
    return undefined;
  };

  save = () => {
    this.toggleModal();
  };

  confirmSave = () => {
    const {filteredItems, selectedOption} = this.state;
    const modifiedItems = filteredItems.filter(x =>  x.hasChanges);
    this.props.editOrderGuideActions.updateOrderGuideItems(modifiedItems, selectedOption.orderGuideHeaderId);
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible});
  };

  cancelChanges = () => {
    const {filteredItems, editOrderGuideItems} = this.state;
    const items = intersectionBy(editOrderGuideItems, filteredItems, 'masterRecordId');

    this.setState({
      filteredItems: items
    });
  };

  showDefaultOrderGuideModal = () => {
    this.setState({ isDefaultOrderGuideModalVisible: !this.state.isDefaultOrderGuideModalVisible});
  };

  canDeleteItems = () => {
    const {conceptAttributes} = this.props;
    const ATTRIBUTE_NAME = 'MPODeleteOrderGuide';

    if (conceptAttributes && conceptAttributes.length > 0) {
      const index = conceptAttributes.findIndex(x => x.name.toLowerCase() === ATTRIBUTE_NAME.toLowerCase());
      return (index >= 0 && this.hasDeleteFeature(index)) ? true : false;
    } else {
      return false;
    }
  };

  hasDeleteFeature = (index) => {
    if (index < 0) return false;
    const {conceptAttributes} = this.props;
    return conceptAttributes[index].value.toLowerCase() === 'true' ? true : false;
  };

  onSelectAll = () => {
    const {filteredItems, isSelectAllChecked} = this.state;
    let updatedItems = filteredItems.map(x => ({...x}));

    updatedItems.forEach(x => x.isSelected = !isSelectAllChecked);

    this.setState({
      deletedItems: isSelectAllChecked ? [] : updatedItems,
      filteredItems: updatedItems,
      isSelectAllChecked: !isSelectAllChecked
    });
  };

  onCheckboxChange = (item) => {
    const {filteredItems} = this.state;
    const index = filteredItems.findIndex(x => x.masterRecordId === item.masterRecordId);

    if (index < 0) return;

    if (this.existsInDeletedItems(item)) {
      this.removeFromDeletedItems(item);
    } else {
      this.addToDeletedItems(item);
    }

    this.updateSelectedItem(item, index);
  };

  existsInDeletedItems = (item) => {
    const {deletedItems} = this.state;
    const index = deletedItems.findIndex(x => x.orderGuideItemId === item.orderGuideItemId);
    return index > -1 ? true : false;
  };

  removeFromDeletedItems = (item) => {
    const {deletedItems} = this.state;
    const index = deletedItems.findIndex(x => x.orderGuideItemId === item.orderGuideItemId);

    this.setState({
      deletedItems: [
        ...deletedItems.slice(0, index),
        ...deletedItems.slice(index + 1)
      ]
    });
  };

  addToDeletedItems = (item) => {
    const {deletedItems} = this.state;
    this.setState({deletedItems: deletedItems.concat(item)});
  };

  updateSelectedItem = (item, index) => {
    item.isSelected = !item.isSelected;
    const {filteredItems} = this.state;

    const updatedItems = [
      ...filteredItems.slice(0, index),
      item,
      ...filteredItems.slice(index + 1)
    ];

    this.setState({
      filteredItems: updatedItems,
      editOrderGuideItems: updatedItems
    });
  };

  toggleDeleteItemPromptModal = () => {
    this.setState({ isDeleteItemPromptModalVisible: !this.state.isDeleteItemPromptModalVisible});
  };

  confirmDelete = () => {
    const {deletedItems, selectedOption} = this.state;
    const headerId = selectedOption.orderGuideHeaderId;
    this.toggleDeleteItemPromptModal();
    this.props.editOrderGuideActions.deleteSelectedItems(deletedItems, headerId);
  };

  extractOnHandOnReceivedDays = () => {
    let onHandOnReceivedDays = [];
    const {selectedDeliveryDate, selectedOnHandOnReceivedDays} = this.props;

    if (selectedOnHandOnReceivedDays && selectedOnHandOnReceivedDays.length > 0) {
      onHandOnReceivedDays = selectedOnHandOnReceivedDays.filter(x => x.isSelected).map(x => x.value);
    } else {
      const deliveryDay = moment(selectedDeliveryDate).format('dddd');
      onHandOnReceivedDays.push(deliveryDay);
    }

    return onHandOnReceivedDays;
  };

  retrieveFile = (fileType) => {
    const {selectedOrderGuide, vendor, account} = this.props;
    const {editOrderGuideItems, selectedOption} = this.state;
    const deliverySchedule = selectedOption.deliveryDaySchedule;
    const onHandOnReceivedDays = this.extractOnHandOnReceivedDays();

    // let accountData = {...account};
    // accountData.vendorName = vendor.vendorName;
    // accountData.name = account.accountName + " (" + selectedOption.guideName + ")";

    if (fileType === FileType.PDF) {
      this.props.documentActions.getEditableOrderGuideItemsPdf(selectedOrderGuide.orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, account.accountId, vendor.vendorId);
    } else {
      this.props.documentActions.getEditableOrderGuideItemsExcel(selectedOrderGuide.orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, account.accountId, vendor.vendorId);
    }
  };

  retrieveExcelFile = () => {
    this.retrieveFile(FileType.Excel);
  };

  retrievePDFFile = () => {
    this.retrieveFile(FileType.PDF);
  };

  downloadPdf = (nextProps) => {
    if (nextProps.editableOrderGuideItemsPdf !== this.props.editableOrderGuideItemsPdf) {
      const pdf = nextProps.editableOrderGuideItemsPdf;
      documentHelpers.download(pdf, 'EditOrderGuide.pdf');
    }
  };

  downloadExcel = (nextProps) => {
    if (nextProps.editableOrderGuideItemsExcel !== this.props.editableOrderGuideItemsExcel) {
      const excel = nextProps.editableOrderGuideItemsExcel;
      documentHelpers.download(excel, 'EditOrderGuide.xlsx');
    }
  };

  toggleSetDaysModal = (deliveryDays) => {
    this.setState({ isSetDaysModalVisible: !this.state.isSetDaysModalVisible});
    if (deliveryDays && deliveryDays.length > 0) {
      this.props.editOrderGuideActions.setOnHandOnReceivedDays(deliveryDays);
    }
  };

  ClearTextField = (e) => {
    if(e.currentTarget.onclick){
      const {editOrderGuideItems} = this.state;
      this.setState({
        filterText: '',
        filteredItems: editOrderGuideItems
      });
      return;
    }
  };

  render() {
    const {account} = this.props;
    const headers = this.constructHeaders();
    const canDeleteItems = this.canDeleteItems();
    const message = 'Are you sure you want to save your changes?';
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const {orderGuides, filteredItems, filterText, isModalVisible, isDefaultOrderGuideModalVisible, isDeleteItemPromptModalVisible, selectedOption, isSelectAllChecked} = this.state;
    const {isSetDaysModalVisible} = this.state;
    const {selectedOnHandOnReceivedDays} = this.props;
    const deliveryDays = isEmpty(selectedOption) ? [] : selectedOption.deliveryDays;
    const cssClasses = "fa fa-2x vertical-align-middle";

    if (isEmpty(account)) {
      return (<Redirect to="/"/>);
    }

    return (
      <div className="container-fluid">
        <ActionBar conceptColor={color}>
        <ClearableSearchBar
          filterText={filterText}
          multiFilter={this.filter}
          ClearTextField={this.ClearTextField} />

          <Dropdown     id="ddOrderGuide"
                        options={orderGuides}
                        selectedOption={selectedOption}
                        onSelectionChange={this.onOrderGuideChange} />

          <ActionButton id="btnSave"
                        text="Save"
                        className={`fa-save ${cssClasses}`}
                        clickHandler={this.save} />

          <ActionButton id="btnCancel"
                        text="Cancel"
                        className={`fa-undo ${cssClasses}`}
                        clickHandler={this.cancelChanges} />

          {
          canDeleteItems &&
          <ActionButton id="btnDelete"
                        text="Delete"
                        className={`fa-trash ${cssClasses}`}
                        clickHandler={this.toggleDeleteItemPromptModal} />
          }

          <ActionButton id="btnChange"
                        text="Change Defaults"
                        className={`fa-folder ${cssClasses}`}
                        clickHandler={this.showDefaultOrderGuideModal}/>

          <ActionButton id="btnSet"
                        text="Set OH / OR"
                        className={`fa-check-circle ${cssClasses}`}
                        clickHandler={this.toggleSetDaysModal} />

          <ActionButton id="btnExcel"
                        text="Export Excel"
                        className={`fa-file-excel-o ${cssClasses}`}
                        clickHandler={this.retrieveExcelFile} />

          <ActionButton id="btnPdf"
                        text="Export PDF"
                        className={`fa-file-pdf-o ${cssClasses}`}
                        clickHandler={this.retrievePDFFile} />
        </ActionBar>

        <Table  id="tblEditOrderGuideItems"
                headers={headers}
                items={filteredItems}
                RowComponent={Row}
                callback={this.onRowValueChange}
                onSelectAll={this.onSelectAll}
                selectAllChecked={isSelectAllChecked}
                deliveryDays={deliveryDays}
                onCheckboxChange={this.onCheckboxChange}
                wrapperClassName="tableWrapper"
                tableClassName="fixed_header"
                filterTypes={[MessageLocation.HeaderBanner]}
                pageSize={0} />

        {/* Prompt Modal */}
        { isModalVisible
          ? <Modal>
              <PromptModal  show
                            message={message}
                            messageType={MessageType.WARNING}
                            noButtonHandler={this.toggleModal}
                            yesButtonHandler={this.confirmSave} />
            </Modal>
          : null
        }

        {/* Delete Item Prompt Modal */}
        { isDeleteItemPromptModalVisible
          ? <Modal>
              <PromptModal  show
                            message={'Are you sure you want to delete the selected items?'}
                            messageType={MessageType.WARNING}
                            noButtonHandler={this.toggleDeleteItemPromptModal}
                            yesButtonHandler={this.confirmDelete} />
            </Modal>
          : null
        }

        {/* Change Default OrderGuide Modal */}
        { isDefaultOrderGuideModalVisible
          ? <Modal>
              <DefaultOrderGuideModal show
                                      orderGuides={orderGuides}
                                      closeButtonHandler={this.showDefaultOrderGuideModal} />
            </Modal>
          : null
        }

        {/* Set Days Modal */}
        { isSetDaysModalVisible
          ? <Modal>
              <SetDaysModal show
                            deliveryDays={deliveryDays}
                            selectedOnHandOnReceivedDays={selectedOnHandOnReceivedDays}
                            closeButtonHandler={this.toggleSetDaysModal} />
            </Modal>
          : null
        }
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    vendor: selectors.getVendor(state),
    account: selectors.getAccount(state),
    orderGuides: selectors.orderGuides(state),
    editOrderGuideItems: selectors.getSortedEditableOrderGuide(state),
    selectedOrderGuide: selectors.getSelectedOrderGuide(state),
    conceptAttributes: selectors.getConceptAttributes(state),
    editableOrderGuideItemsPdf: selectors.getEditableOrderGuideItemsPdf(state),
    editableOrderGuideItemsExcel: selectors.getEditableOrderGuideItemsExcel(state),
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state),
    selectedOnHandOnReceivedDays: selectors.getSelectedOnHandOnReceivedDays(state)
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    editOrderGuideActions: bindActionCreators(editOrderGuideActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderGuide);
