import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { isEmpty, isUndefined } from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import update from 'immutability-helper';

import { ActionBar, ActionButton, ActionDatePicker } from '../common/action-bar';
import * as helpers from './../../common/utils/generalFunctions';
import * as documentActions from '../../actions/documentActions';
import * as orderGuideActions from '../../actions/mpOnlineActions';
import * as authProfileActions from '../../actions/authProfileActions';
import * as documentHelpers from '../../common/utils/documentHelpers';
import * as selectors from '../../selectors';
import { Dropdown } from '../common/dropdown';
import Table  from '../common/table';
import OrderGuideRow from '../order-guide/orderguide-row';
import Modal from './../modals/modal';
import AddItemsModal from '../order-guide/add-items-modal';
import { PromptModal } from './../modals/prompt-modal';
import { FileType, MessageType, MessageLocation } from './../../common/utils/stringConstants';
import { getNearestDeliveryDate, canSeeRequestedShipDate, canSeeRequestedDeliveryDate } from '../common/order-helper';
import { ClearableSearchBar } from '../common/searchBoxWithClear';
import { Banner } from '../messages/banner';

const getHeaders = () => {
  return [
    { header: "Order", className: "setWidth90", name: "order" },
    { header: "Info", className: "setWidth50", name: "info" },
    { header: "Item", className: "setWidth120", name: "itemNumber" },
    { header: "Description", className: "width400", name: "description" },
    { header: "Unit", className: "table-cell-center setWidth120", name: "unit" },
    { header: "Pack", className: "table-cell-center setWidth160", name: "packSize" },
    { header: "Price", className: "table-cell-center isCurrency setWidth80", name: "price" },
    { header: "Par", className: "table-cell-center setWidth160", name: "par" }
  ];
};

export class OrderGuide extends React.Component {

  static propTypes = {
    orderGuides: PropTypes.array,
    orderGuideItems: PropTypes.array,
    documentActions: PropTypes.object,
    orderGuideActions: PropTypes.object,
    authProfileActions: PropTypes.object,
    mpOnlineActions: PropTypes.object,
    orderGuideItemsPdf: PropTypes.string,
    orderGuideItemsExcel: PropTypes.string,
    account: PropTypes.object,
    accountId: PropTypes.number,
    relatedAccounts: PropTypes.array,
    vendor: PropTypes.object,
    selectedOrderGuide: PropTypes.object,
    selectedShipDate: PropTypes.string,
    selectedDeliveryDate: PropTypes.string,
    shoppingCart: PropTypes.array,
    conceptAttributes: PropTypes.array,
    setParentState: PropTypes.func,
    attributeName: PropTypes.string,
    messages: PropTypes.array,
    maxPurchaseQuantityDetails: PropTypes.array
  };

  constructor(props) {
    super(props);

    const hasShipDate = props.selectedShipDate && props.selectedShipDate !== '';
    const hasDeliveryDate = props.selectedDeliveryDate && props.selectedDeliveryDate !== '';

    this.state = {
      orderGuides: props.orderGuides ? props.orderGuides : [],
      orderGuideItems: props.orderGuideItems ? props.orderGuideItems : [],
      filteredItems: props.orderGuideItems ? props.orderGuideItems : [],
      selectedOption: this.getSelectedOrderGuide(props.orderGuides),
      filterText: "",
      shipDate: hasShipDate ? moment(props.selectedShipDate) : moment(),
      deliveryDate: this.getEarliestDeliveryDate(hasDeliveryDate ? moment(props.selectedDeliveryDate) : moment()),
      isPromptModalVisible: false,
      isAddItemsModalVisible: false,
      relatedAccountOptions: (this.props.relatedAccounts) ? this.getDropdownOptions(this.props.relatedAccounts) : [],
      loadingGuides: false,
      loadingAttributes: false,
      loadingVendor: false,
      messages: props.messages ? props.messages : []
    };

    this.clearTextField = this.clearTextField.bind(this);
  }

  componentDidMount() {
    if (this.props.accountId > 0) {
      this.props.authProfileActions.retrieveProfile(this.props.accountId);
    }

    if (this.orderGuideHasBeenSelected()) {
      this.props.orderGuideActions.setDeliveryDate(this.getEarliestDeliveryDate(moment(this.state.deliveryDate)).format());
      const { orderGuideHeaderId } = this.props.selectedOrderGuide;
      this.props.orderGuideActions.getOrderGuideItems(orderGuideHeaderId, this.getEarliestDeliveryDate(moment(this.state.deliveryDate)), moment(this.state.shipDate));
    }

    this.props.orderGuideActions.togglePreviewOrderButtonVisibility(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newState = {};

    if (nextProps.accountId !== this.props.accountId && nextProps.accountId > 0) {
      this.props.authProfileActions.retrieveProfile(nextProps.accountId);
    }

    if (((nextProps.account !== this.props.account || (nextProps.orderGuides && nextProps.orderGuides.length === 0)) && !this.state.loadingGuides) && nextProps.account.accountId) {
      newState['loadingGuides'] = true;
      this.props.orderGuideActions.orderGuide(nextProps.account.accountId);
    }

    if ((this.props.account && this.props.account.conceptId !== nextProps.account.conceptId) || (this.props.conceptAttributes.length === 0 && !this.state.loadingAttributes)) {
      const { conceptId, accountId } = nextProps.account;
      if (!isUndefined(conceptId) && !isUndefined(accountId)) {
        newState['loadingAttributes'] = true;
        this.props.orderGuideActions.getConceptAttributes(conceptId, accountId);
      }
    }

    if (nextProps.orderGuides !== this.props.orderGuides) {
      const defaultOrderGuide = this.getAppropriateOrderGuide(nextProps.orderGuides);
      if (isUndefined(defaultOrderGuide)) {
        return;
      }
      const localDeliveryDate = nextProps.selectedDeliveryDate !== '' ? nextProps.selectedDeliveryDate : defaultOrderGuide.deliveryDate;
      const localShipDate = nextProps.selectedShipDate !== '' ? nextProps.selectedShipDate : defaultOrderGuide.shipDate;
      
      let conceptIdForVendor = undefined;
      if ((this.props.account && this.props.account.conceptId !== nextProps.account.conceptId) || (nextProps.vendor && !this.state.loadingVendor)) {
        if(!isUndefined(nextProps.account.conceptId)) {
          conceptIdForVendor = nextProps.account.conceptId;
        }
      }
      
      this.props.orderGuideActions.getOrderGuideItems(defaultOrderGuide.orderGuideHeaderId, this.getEarliestDeliveryDate(moment(localDeliveryDate), defaultOrderGuide), undefined);
      this.props.orderGuideActions.getVendor(defaultOrderGuide.vendorId, conceptIdForVendor);
      this.props.orderGuideActions.setSelectedOrderGuide(defaultOrderGuide);
      if (canSeeRequestedShipDate()) {
        this.props.orderGuideActions.setShipDate(localShipDate);
      }
      newState['loadingGuides'] = false;
      newState["orderGuides"] = nextProps.orderGuides;
      newState["selectedOption"] = defaultOrderGuide;
    }

    if (nextProps.vendor !== this.props.vendor) {
      newState['loadingVendor'] = true;
    }

    if (this.orderGuideHasBeenSelected() && nextProps.vendor === this.props.vendor && this.state.loadingVendor) {
      const selectedOrderGuide = this.getSelectedOrderGuide();
      const localDeliveryDate = nextProps.selectedDeliveryDate !== '' ? nextProps.selectedDeliveryDate : selectedOrderGuide.deliveryDate;

      this.props.orderGuideActions.setDeliveryDate(this.getEarliestDeliveryDate(moment(localDeliveryDate), selectedOrderGuide).format());

      newState['loadingVendor'] = false;
    }

    if (nextProps.orderGuideItems !== this.props.orderGuideItems) {
      if (this.shoppingCartHasItems()) {
        this.setOrderQuantity(nextProps.orderGuideItems);
      } else {
        newState["orderGuideItems"] = nextProps.orderGuideItems;
        newState["filteredItems"] = nextProps.orderGuideItems;
      }

      nextProps.orderGuideItems.forEach(item => {
        const accountId = nextProps.account !== this.props.account ? nextProps.account.accountId : this.props.account.accountId;
        const vendorId = nextProps.vendor !== this.props.vendor ? nextProps.vendor.vendorId : this.props.vendor.vendorId;
        if (item.maxPurchaseQuantity > 0 && item.maxPurchaseQuantityStartDate < this.props.selectedDeliveryDate && item.maxPurchaseQuantityEndDate > this.props.selectedDeliveryDate) {
          this.props.orderGuideActions.getOrderGuideItemMaxPurchaseQuantity(accountId, vendorId, this.props.selectedDeliveryDate, item);
        }
      });
    }

    if (nextProps.selectedShipDate !== this.props.selectedShipDate) {
      newState["shipDate"] = moment(nextProps.selectedShipDate);
    }

    if (nextProps.selectedDeliveryDate !== this.props.selectedDeliveryDate) {
      const accountId = nextProps.account !== this.props.account ? nextProps.account.accountId : this.props.account.accountId;
      const vendorId = nextProps.vendor !== this.props.vendor ? nextProps.vendor.vendorId : this.props.vendor.vendorId;
      this.props.orderGuideActions.getActiveMessages(vendorId, accountId, nextProps.selectedDeliveryDate);
      newState["deliveryDate"] = moment(nextProps.selectedDeliveryDate);

      if (this.props.orderGuideItems) {
        this.props.orderGuideItems.forEach(item => {
          if (item.maxPurchaseQuantity > 0 && item.maxPurchaseQuantityStartDate < nextProps.selectedDeliveryDate && item.maxPurchaseQuantityEndDate > nextProps.selectedDeliveryDate) {
            this.props.orderGuideActions.getOrderGuideItemMaxPurchaseQuantity(accountId, vendorId, this.props.selectedDeliveryDate, item);
          }
        });
      }
    }

    if (nextProps.conceptAttributes.length > 0) {
      newState["loadingAttributes"] = false;
    }

    if (nextProps.maxPurchaseQuantityDetails !== this.props.maxPurchaseQuantityDetails) {
      nextProps.maxPurchaseQuantityDetails.forEach(detail => {
        let i = this.props.orderGuideItems.findIndex(item => item.ItemNumber === detail.ItemNumber);
        if (i > -1) {
          this.props.orderGuideItems[i].remainingOrderQuantity = detail.maxPurchaseQuantity;
          this.props.orderGuideItems[i].maxPurchaseQuantityMessage = detail.message;
        }
      });
    }

    if (!helpers.isEmptyObject(newState)) {
      this.setState(newState);
    }

    this.downloadPdf(nextProps);
    this.downloadExcel(nextProps);
  }

  componentWillUnmount() {
    this.props.orderGuideActions.togglePreviewOrderButtonVisibility(false);
  }

  shoppingCartHasItems = () => {
    const { shoppingCart } = this.props;
    return shoppingCart && shoppingCart.length > 0 ? true : false;
  };

  setOrderQuantity = (items) => {
    const shoppingCartItems = this.props.shoppingCart;
    let updatedItems = items.map(x => ({ ...x }));

    if (shoppingCartItems) {
      shoppingCartItems.forEach(item => {
        const index = items.findIndex(x => x.masterRecordId === item.masterRecordId);
        if (index > -1) {
          updatedItems[index].order = item.order;
          // update shopping cart item price from order guide
          item.price = updatedItems[index].price;
        } else {
          this.removeFromShoppingCart(item);
        }
      });
    }

    this.setState({
      filteredItems: updatedItems,
      orderGuideItems: updatedItems
    });
  };

  getSelectedOrderGuide = (orderGuides) => {
    return this.orderGuideHasBeenSelected()
      ? this.props.selectedOrderGuide
      : this.getDefaultOrderGuide(orderGuides);
  };

  orderGuideHasBeenSelected = () => {
    return !isEmpty(this.props.selectedOrderGuide);
  };

  getAppropriateOrderGuide = (orderGuides) => {
    let orderGuide = {};

    if (orderGuides) {
      orderGuide = (this.orderGuideHasBeenSelected() && (orderGuides.findIndex(x => x.orderGuideHeaderId === this.props.selectedOrderGuide.orderGuideHeaderId) > -1)) ?
        this.props.selectedOrderGuide :
        this.getDefaultOrderGuide(orderGuides);
    }

    return orderGuide;
  };

  getDefaultOrderGuide = (orderGuides) => {
    if (orderGuides) {
      const index = orderGuides.findIndex(x => x.isDefault);
      return index >= 0 ? orderGuides[index] : orderGuides[0];
    }
  };

  getEarliestDeliveryDate = (incomingDate, orderGuide) => {
    const selectedOrderGuide = (orderGuide) ? orderGuide : this.props.selectedOrderGuide;

    return getNearestDeliveryDate(incomingDate, selectedOrderGuide, this.props.vendor, this.props.conceptAttributes);
  };

  onOrderQuantityChange = (e, item) => {
    let key = e.target.id;
    const value = e.target.value;

    if (key.indexOf('-') > -1) {
      let tempKey = key.split('-');
      key = tempKey[0];
    }

    if (helpers.isValidInteger(value)) {
      this.updateOrderQuantity(key, value, item);
    }
  };

  updateOrderQuantity = (key, value, item) => {
    const { filteredItems } = this.state;
    const index = filteredItems.findIndex(x => x.masterRecordId === item.masterRecordId);

    if (index < 0) return;

    let updatedItem = { ...filteredItems[index] };
    updatedItem[key] = value;

    this.updateShoppingCart(updatedItem);
    this.setState({
      filteredItems: update(filteredItems, { [index]: { $set: updatedItem } })
    });

    let orderIsInvalid = false;
    this.state.filteredItems.forEach(x => {
      orderIsInvalid |= (x.maxPurchaseQuantity > 0 && x.order > x.remainingOrderQuantity && x.masterRecordId !== item.masterRecordId);
    });
    orderIsInvalid |= this.state.filteredItems[index].maxPurchaseQuantity > 0 && value > this.state.filteredItems[index].remainingOrderQuantity;

    this.props.orderGuideActions.togglePreviewOrderButtonVisibility(!orderIsInvalid);
  };

  updateShoppingCart = (item) => {
    if (this.existsInShoppingCart(item)) {
      this.modifyShoppingCart(item);
    } else {
      this.addToShoppingCart(item);
    }
  };

  existsInShoppingCart = (item) => {
    const { shoppingCart } = this.props;
    const index = shoppingCart.findIndex(x => x.masterRecordId === item.masterRecordId);
    return index > -1 ? true : false;
  };

  modifyShoppingCart = (item) => {
    if (item.order.trim().length === 0) {
      this.removeFromShoppingCart(item);
    } else {
      this.props.orderGuideActions.updateShoppingCart(item);
    }
  };

  addToShoppingCart = (item) => {
    this.props.orderGuideActions.addToShoppingCart(item);
  };

  removeFromShoppingCart = (item) => {
    this.props.orderGuideActions.removeFromShoppingCart(item);
  };

  multiFilter = event => {
    const { orderGuideItems } = this.state;
    const filterText = event.target.value.toLowerCase().trim();

    if (filterText === "") {
      this.setState({
        filterText: event.target.value,
        filteredItems: this.state.orderGuideItems
      });
      return;
    }

    const matched = helpers.filterItemsV2(orderGuideItems, filterText);

    this.setState({
      filterText: event.target.value,
      filteredItems: matched
    });
  };

  onOrderGuideChange = (option) => {
    this.setState({ selectedOption: option });
    this.props.orderGuideActions.getOrderGuideItems(option.orderGuideHeaderId, moment(this.props.selectedDeliveryDate), moment(this.props.selectedShipDate));
    this.props.orderGuideActions.getVendor(option.vendorId, this.getConceptId(this.props.account));
    this.props.orderGuideActions.setSelectedOrderGuide(option);
  };

  onShipDateChange = date => {
    const momentDate = moment.isMoment(date) ? date : moment(date);
    const initialDeliveryDate = this.state.deliveryDate;
    this.props.orderGuideActions.setShipDate(momentDate.format());

    if (!canSeeRequestedDeliveryDate(this.props.conceptAttributes) || initialDeliveryDate.diff(date) < 0) {
      this.props.orderGuideActions.setDeliveryDate(this.getEarliestDeliveryDate(momentDate).format());
      this.props.orderGuideActions.getOrderGuideItems(this.props.selectedOrderGuide.orderGuideHeaderId, undefined, momentDate);
    }
  };

  onDeliveryDateChange = date => {
    const momentDate = moment.isMoment(date) ? date : moment(date);
    const formattedDate = momentDate.format();
    this.props.orderGuideActions.setDeliveryDate(formattedDate);
    if (date < this.state.shipDate) {
      this.props.orderGuideActions.setShipDate(formattedDate);
    }
    this.props.orderGuideActions.getOrderGuideItems(this.props.selectedOrderGuide.orderGuideHeaderId, moment(formattedDate), undefined);
  };

  toggleAddItemsModal = () => {
    this.setState({ isAddItemsModalVisible: !this.state.isAddItemsModalVisible });
  };

  togglePromptModal = () => {
    this.setState({ isPromptModalVisible: !this.state.isPromptModalVisible });
  };

  getConceptId = (account) => {
    if(account && !isUndefined(account.conceptId))
    {
      return account.conceptId;
    }
    return undefined;
  };

  resetOrderValues = () => {
    const { filteredItems } = this.state;
    const updatedItems = filteredItems.map(x => ({ ...x }));

    updatedItems.forEach(x => x.order = '');

    this.props.orderGuideActions.clearShoppingCart();
    this.setState({ filteredItems: updatedItems });
    this.togglePromptModal();
  };

  retrieveFile = (fileType) => {
    const { orderGuideItems } = this.state;
    const { selectedDeliveryDate } = this.props;
    const { orderGuideHeaderId } = this.props.selectedOrderGuide;
    const day = moment(selectedDeliveryDate).day();

    orderGuideItems.forEach(x => {
      x.selectedParValue = x[`par${day}`];
    });

    if (fileType === FileType.PDF) {
      this.props.documentActions.getOrderGuideItemsPdf(orderGuideHeaderId, this.props.account.accountId);
    } else {
      this.props.documentActions.getOrderGuideItemsExcel(orderGuideHeaderId, this.props.account.accountId);
    }
  };

  retrieveExcelFile = () => {
    this.retrieveFile(FileType.Excel);
  };

  retrievePDFFile = () => {
    this.retrieveFile(FileType.PDF);
  };

  downloadPdf = (nextProps) => {
    if (nextProps.orderGuideItemsPdf !== this.props.orderGuideItemsPdf) {
      const pdf = nextProps.orderGuideItemsPdf;
      documentHelpers.download(pdf, 'OrderGuideItems.pdf');
    }
  };

  downloadExcel = (nextProps) => {
    if (nextProps.orderGuideItemsExcel !== this.props.orderGuideItemsExcel) {
      const excel = nextProps.orderGuideItemsExcel;
      documentHelpers.download(excel, 'OrderGuideItems.xlsx');
    }
  };

  isDeliveryDay = (date) => {
    const day = date.day();
    const deliveryDays = [];
    const { selectedOrderGuide } = this.props;
    const { deliveryDaySchedule } = selectedOrderGuide;

    if (isUndefined(deliveryDaySchedule) || helpers.getConceptAttributeValue(this.props.conceptAttributes, 'MPOIgnoreDelivRoute') === 'true') {
      // datepicker selection should be available for all days.
      return true;
    }

    // deliveryDaySchedule is a string value that
    // represents the delivery days of the week: e.g. "246", "0135" ...
    deliveryDaySchedule.split("").forEach(x => deliveryDays.push(parseInt(x)));
    // Example for filterDates prop
    // https://reactdatepicker.com/#example-17
    return deliveryDays.includes(day);
  };

  isAllowedDeliveryDay = (date) => {
    const localDate = moment(date);
    const isDeliveryDay = this.isDeliveryDay(localDate);
    const nextDeliveryDay = this.getEarliestDeliveryDate(localDate);

    return isDeliveryDay && (localDate.startOf('day') >= moment(nextDeliveryDay).startOf('day'));
  };

  canAddItems = () => {
    const { conceptAttributes } = this.props;
    const attributeName = "MPOAddOrderGuide";
    const attributeValue = helpers.getConceptAttributeValue(conceptAttributes, attributeName);

    return isUndefined(attributeValue) || attributeValue.toLowerCase() === "false"
      ? false
      : true;
  };

  clearTextField = (e) => {
    if (e.currentTarget.onclick) {
      const { orderGuideItems } = this.state;
      this.setOrderQuantity(orderGuideItems);
      this.setState({
        filterText: ''
      });
    }
  };

  getDropdownOptions = (options) => {
    let dropdownOptions = [];

    if (options.length > 0) {
      dropdownOptions = options.map(x => {
        return (
          (x.accountId) ?
            {
              key: x.accountId,
              text: x.accountName,
              value: x.accountId
            } :
            {
              key: x,
              text: x,
              value: x
            }
        );
      });
    }

    return dropdownOptions;
  };

  getCurrentAccountSelection = () => {
    const selectedOption = this.state.relatedAccountOptions.filter(x => x.value === this.props.accountId);
    return (selectedOption.length === 1) ? selectedOption[0] : "";
  };

  onAccountChange = (option) => {
    this.props.setParentState({ accountId: option.value });
    this.props.orderGuideActions.setAccountId(option.value);
  };

  render() {
    const headers = getHeaders();
    const { account } = this.props;
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const { orderGuides, filteredItems, filterText, shipDate, deliveryDate, selectedOption, relatedAccountOptions } = this.state;
    const selectedOrderGuideId = selectedOption ? selectedOption.orderGuideHeaderId : 0;
    const deliveryDateSelected = !helpers.isEmptyObject(selectedOption) ? selectedOption.deliveryDate : moment().toDate();
    const cssClasses = "fa fa-2x vertical-align-middle";

    if (isEmpty(account) && this.props.accountId === 0) {
      return (<Redirect to="/" />);
    }

    filteredItems.forEach(item => {
      if (item.maxPurchaseQuantity > 0) {
        let remainingOrderQuantity = item.maxPurchaseQuantity;
        let message = `MaxPurchaseQuantity is ${item.maxPurchaseQuantity}.`;
        const i = this.props.maxPurchaseQuantityDetails.findIndex(detail => detail.itemNumber === item.itemNumber);
        if (i > -1) {
          remainingOrderQuantity = this.props.maxPurchaseQuantityDetails[i].maxPurchaseQuantity;
          message = `${message}  ${this.props.maxPurchaseQuantityDetails[i].message}`;
        }
        
        item.maxPurchaseQuantityMessage = message;
        item.hasInfo = true;
        item.remainingOrderQuantity = remainingOrderQuantity;
        item.isInvalid = item.order > remainingOrderQuantity;
      }
    });

    return (
      <div className="container-fluid">
        <ActionBar conceptColor={color}>
          <ClearableSearchBar
            filterText={filterText}
            multiFilter={this.multiFilter}
            ClearTextField={this.clearTextField} />

          <Dropdown id="ddOrderGuide"
            options={orderGuides}
            selectedOption={selectedOption}
            onSelectionChange={this.onOrderGuideChange} />

          {canSeeRequestedShipDate(this.props.conceptAttributes) ?
            <DatePicker id="dpShipDate"
              customInput={<ActionDatePicker text="Requested Ship Date" />}
              minDate={moment().toDate()}
              selected={shipDate.toDate()}
              onChange={this.onShipDateChange} /> : null
          }

          {canSeeRequestedDeliveryDate(this.props.conceptAttributes) ?
            <DatePicker id="dpDeliveryDate"
              customInput={<ActionDatePicker text="Requested Arrival Date" />}
              minDate={this.getEarliestDeliveryDate(deliveryDateSelected)}
              selected={deliveryDate.toDate()}
              filterDate={this.isAllowedDeliveryDay}
              onChange={this.onDeliveryDateChange} /> : null
          }

          {this.canAddItems() ?
            <ActionButton id="btnAdd"
              text="Add Items"
              className={`fa-plus ${cssClasses}`}
              clickHandler={this.toggleAddItemsModal} /> : null
          }

          <ActionButton id="btnClear"
            text="Clear"
            className={`fa-undo ${cssClasses}`}
            clickHandler={this.togglePromptModal} />

          <ActionButton id="btnExcel"
            text="Export Excel"
            className={`fa-file-excel-o ${cssClasses}`}
            clickHandler={this.retrieveExcelFile} />

          <ActionButton id="btnPdf"
            text="Export PDF"
            className={`fa-file-pdf-o ${cssClasses}`}
            clickHandler={this.retrievePDFFile} />
          {relatedAccountOptions.length > 0 ?
            <Dropdown id="ddRelatedAccounts"
              options={relatedAccountOptions}
              selectedOption={this.getCurrentAccountSelection()}
              onSelectionChange={this.onAccountChange} />
            : null
          }
        </ActionBar>
        
        <Banner filterType={MessageLocation.OrderGuideBanner}/>

        <Table id="tblOrderGuideItems"
          headers={headers}
          items={filteredItems}
          pageSize={0}
          RowComponent={OrderGuideRow}
          callback={this.onOrderQuantityChange}
          wrapperClassName="tableWrapper"
          tableClassName="fixed_header"
          filterTypes={[MessageLocation.OrderGuideBanner, MessageLocation.HeaderBanner]}
        />

        {this.state.isAddItemsModalVisible
          ? <Modal>
            <AddItemsModal show
              vendor={this.props.vendor}
              account={this.props.account}
              headerId={selectedOrderGuideId}
              closeButtonHandler={this.toggleAddItemsModal} />
          </Modal>
          : null
        }

        {/* Prompt Modal */}
        {this.state.isPromptModalVisible
          ? <Modal>
            <PromptModal show
              message={'Are you sure you want to reset current order values?'}
              messageType={MessageType.WARNING}
              noButtonHandler={this.togglePromptModal}
              yesButtonHandler={this.resetOrderValues} />
          </Modal>
          : null
        }

      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    orderGuides: selectors.orderGuides(state),
    orderGuideItems: selectors.getSortedOrderGuide(state),
    orderGuideItemsPdf: selectors.getOrderGuideItemsPdf(state),
    orderGuideItemsExcel: selectors.getOrderGuideItemsExcel(state),
    vendor: selectors.getVendor(state),
    account: state.authProfile,
    accountId: selectors.getAccountId(state),
    relatedAccounts: selectors.getRelatedAccounts(state),
    selectedOrderGuide: selectors.getSelectedOrderGuide(state),
    selectedShipDate: selectors.getSelectedShipDate(state),
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state),
    shoppingCart: state.shoppingCart,
    conceptAttributes: selectors.getConceptAttributes(state),
    messages : selectors.getActiveMessages(state),
    maxPurchaseQuantityDetails: state.maxPurchaseQuantityDetails
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    orderGuideActions: bindActionCreators(orderGuideActions, dispatch),
    authProfileActions: bindActionCreators(authProfileActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderGuide);
