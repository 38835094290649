import * as types from './actionTypes';

export function startServiceCall() {
  return {
    type: types.SERVICE_CALL_STARTED
  };
}

export function endServiceCall() {
  return {
    type: types.SERVICE_CALL_COMPLETE
  };
}
