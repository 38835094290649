import React      from "react";
import PropTypes  from "prop-types";
import { connect } from 'react-redux';
import * as generalFunctions from "../../common/utils/generalFunctions";

export class Row extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    deliveryDays: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    headers: PropTypes.array,
    conceptAttributes: PropTypes.array
  };

  getParValue = (day, row) => {
    let value = {
      "Sunday": row.par0,
      "Monday": row.par1,
      "Tuesday": row.par2,
      "Wednesday": row.par3,
      "Thursday": row.par4,
      "Friday": row.par5,
      "Saturday": row.par6
    };

    let parDay = day.value;
    let parValue = (value[parDay] !== undefined) ? value[parDay] : "Error";

    return parValue;
  };

  render() {
    const row = this.props.data;
    const {callback, deliveryDays, onCheckboxChange} = this.props;
    const price = generalFunctions.getPrice(row.price, this.props.conceptAttributes);
    const cellClass = [];
    let i = 0;
    if (this.props.headers) {
      for(i = 0; i < this.props.headers.length; i++){
        if(this.props.headers[i].className){
          cellClass[i]={className: this.props.headers[i].className};
        }
      }
    }

    return (
      <tr className="">
        <td width="40px" className="pointer">
          <div className={`checkmark-circle ${ row.isSelected ? 'checked' : ''}`} onClick={() => onCheckboxChange(row)}>
            <div className="checkmark" />
          </div>
        </td>
        <td {...cellClass[0]}>
          <input  type="text"
                  name="sequenceNumber"
                  value={row.sequenceNumber}
                  className="form-control textbox numeric-textbox"
                  onChange={(event) => callback(event, row)} />
        </td>
        <td {...cellClass[1]}>{row.itemNumber}</td>
        <td {...cellClass[2]}>{row.description}</td>
        <td {...cellClass[3]}>{row.unit}</td>
        <td {...cellClass[4]}>{row.packSize}</td>
        <td {...cellClass[5]}>{price}</td>
        {
          deliveryDays.map((day, index) => {
            const parValue = this.getParValue(day, row);
            let properties = {};
            if (cellClass[6+index]){
              properties = {
                ...cellClass[6+index]
              };
              properties['className'] = "parValues" + ((properties.className) ? " " + properties.className : "");
            } else {
              properties['className'] = "parValues";
            }

            return (
              <td key={index} {...properties}>
                <input  type="text"
                        name={day.key.toLowerCase()}
                        value={parValue}
                        className="form-control numeric-textbox inline-block"
                        onChange={(event) => callback(event, row)} />
              </td>
            );
          })
        }
        <td {...cellClass[cellClass.length-1]}>
          <input  type="text"
                  name="lineNote"
                  value={row.lineNote}
                  className="form-control textbox"
                  onChange={(event) => callback(event, row)} />
        </td>
      </tr>
    );
  }
}

export const mapStateToProps = state => {
  return {
    conceptAttributes: state.conceptAttributes
  };
};

export default connect(mapStateToProps)(Row);