import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as selectors from "../../selectors";
import moment from 'moment';
import * as generalFunctions from "../../common/utils/generalFunctions";

export class OrderGuideRow extends React.Component {
  static propTypes = {
    key: PropTypes.object,
    data: PropTypes.object,
    callback: PropTypes.func,
    selectedDeliveryDate: PropTypes.string,
    headers: PropTypes.array,
    conceptAttributes: PropTypes.array
  };

  getParValue = () => {
    const {selectedDeliveryDate} = this.props;
    const day = moment(selectedDeliveryDate).day();
    return 'par'.concat(day);
  };

  isValueValid = data => {
    return (data.order > data.remainingOrderQuantity) ? "icon-invalid" : "";
  };

  render() {
    const { data, callback } = this.props;
    const parValue = this.getParValue();
    const cellClass = [];
    let i = 0;
    if (this.props.headers) {
      for(i = 0; i < this.props.headers.length; i++){
        if(this.props.headers[i].className){
          cellClass[i]={className: this.props.headers[i].className};
        }
      }
    }

    return (
      <tr id={data.id}>
        <td {...cellClass[0]}>
          <input  id={"order-"+data.rowIndex}
                  type="text"
                  value={data.order}
                  className="form-control"
                  onChange={e => callback(e, data)}
                  style={{ width: "50px", height: "28px" }}
          />
        </td>
        <td {...cellClass[1]}>
          {data.hasInfo && <i className={`fa fa-info-circle fa-2x icon-order-informational ${this.isValueValid(data)}`} id={"info-"+data.rowIndex} title={data.maxPurchaseQuantityMessage} />}
        </td>
        <td {...cellClass[2]} id={"number-"+data.rowIndex}>
          {data.itemNumber}
        </td>
        <td {...cellClass[3]} id={"description-"+data.rowIndex}>
          {data.description}
        </td>
        <td {...cellClass[4]} id={"unit-"+data.rowIndex}>
          {data.unit}
        </td>
        <td {...cellClass[5]} id={"pack-"+data.rowIndex}>
          {data.packSize}
        </td>
        <td {...cellClass[6]} id={"price-"+data.rowIndex}>
          {generalFunctions.getPrice(data.price, this.props.conceptAttributes)}
        </td>
        <td {...cellClass[7]} id={"par-"+data.rowIndex}>
          {data[parValue]}
        </td>
      </tr>
    );
  }
}

export const mapStateToProps = state => {
  return {
    selectedDeliveryDate: selectors.getSelectedDeliveryDate(state),
    conceptAttributes: state.conceptAttributes
  };
};

export default connect(mapStateToProps)(OrderGuideRow);