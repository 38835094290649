import './pollyfill';
import React                    from 'react';
import { render }               from 'react-dom';
import { Route }                from 'react-router';
import { Provider}              from 'react-redux';
import { HashRouter }           from 'react-router-dom';
import createHistory            from 'history/createBrowserHistory';
import configureStore           from './store';
import App                      from './components/app';

import './style.scss';
import '../node_modules/font-awesome/scss/font-awesome.scss';
import '../node_modules/toastr/toastr.scss';
import '../node_modules/react-datepicker/dist/react-datepicker.css';

const initialState = {};
export const history = createHistory();
const store = configureStore(initialState, history);

render(
  <Provider store={store}>
    <HashRouter>
      <Route path="/" strict component={App}/>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
