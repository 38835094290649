/* eslint-disable no-undef */
import { OktaAuth } from '@okta/okta-auth-js';
import trimTrailingSlash from './trimTrailingSlash';

export default class AuthService {
  auth0;
  authConfig;

  constructor(){
    this.authConfig = {
      url: CONFIG.auth.domain,
      baseUrl: CONFIG.auth.domain,//eslint-disable-line no-undef,
      clientId: CONFIG.auth.clientId,
      redirectUri: trimTrailingSlash(window.location.origin + window.location.pathname),
      logo: CONFIG.auth.logo,
      scope: 'openid profile',
      issuer: CONFIG.auth.domain + '/oauth2/' + CONFIG.auth.issuer,
      useClassicEngine: true,
      authParams: {
        pkce: false,
        responseType: ['token'],
        issuer: CONFIG.auth.domain + '/oauth2/' + CONFIG.auth.issuer
      },
      helpLinks: {
        help: CONFIG.self.url,
        forgotPassword: CONFIG.self.url
      },
      transformUsername: function (username, _operation) {
        // This example will append the '@acme.com' domain if the user has
        // not entered it
        return username.indexOf('@mpo.chrobinson.com') > -1
          ? username
          : username + '@mpo.chrobinson.com';
      }
    };

    this.auth0 = new OktaAuth(this.authConfig);
  }

  authorize = () => {
    window.location.hash = '/Login';
  };

  parseHash = () => {
    const hash = window.location.hash;
    const tokenHash = hash.replace("#", "");

    let tokens = this.auth0.token.parseFromUrl(tokenHash);
    tokens.then((tokens) => {
      if (tokens) {
          this.setSession(tokens);
          window.location.hash = '/';
        }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  crossOriginVerification = () => {
    this.auth0.crossOriginVerification();
  };

  logout = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('expires_at');
    sessionStorage.removeItem('state');
    this.auth0.signOut();
  };

  isAuthenticated = () => {
    // Checks if there is a saved token and it's still valid
    const expiresAt = JSON.parse(sessionStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  };

  setSession = (tokens) => {
    const expiresAt = new Date(0);
    const token = tokens.accessToken;
    expiresAt.setUTCSeconds(token.expiresAt);

    sessionStorage.setItem('access_token', token.accessToken);
    sessionStorage.setItem('expires_at', expiresAt.getTime());

    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('state');
  };

  getAccessToken = () => {
    // Retrieves access_token from local storage
    return sessionStorage.getItem('access_token');
  };
}
