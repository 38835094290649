import {GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.maxPurchaseQuantityDetails, action) {
    if (action.type === GET_ORDER_GUIDE_ITEM_MAX_QUANTITY_COMPLETE) {
        const quantityDetail = {
            itemNumber: action.payload.itemNumber,
            maxPurchaseQuantity: action.payload.maxPurchaseQuantity,
            message: action.payload.message
        };
        const i = state.findIndex(detail => detail.itemNumber === action.payload.itemNumber);
        if (i > -1) {
            state[i] = quantityDetail;
        } 
        else {
            state.push(quantityDetail);
        }

        return state;
    } else {
        return state;
    }
}