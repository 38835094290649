import React from "react";
import PropTypes from "prop-types";
import * as generalFunctions from "../../common/utils/generalFunctions";

export const PreviousOrdersRow = (props) => {
  const row = props.data;
  const {callback} = props;
  const isVoid = row.isVoid;
  const cellClass = {};
  if (props.headers) {
    for(let i = 0; i < props.headers.length; i++){
      cellClass[i]={className: props.headers[i].className};
    }
  }

  return (
    <tr>
      <td {...cellClass[0]}>
        {row.headerId}
        { (!isVoid) ? (
          <span>
          <span className="spacer"/>
          <a onClick={() => callback(row, 'pdf')}>PDF</a>
          <span className="spacer">|</span>
          <a onClick={() => callback(row, 'excel')}>Excel</a>
          </span>
        ) : ("")
        }
      </td>
      <td {...cellClass[1]}>{row.orderDateDisplay}</td>
      <td {...cellClass[2]}>{generalFunctions.getFormattedDisplayDate(row.deliveryDate, false, true)}</td>
      <td {...cellClass[3]}>{row.total.toFixed(2)}</td>
      <td {...cellClass[4]}>{row.lineCount}</td>
      <td {...cellClass[5]}>
        <div className={`checkmark-circle ${row.isApproved && !isVoid ? 'checked' : ''}`} >
          <div className={row.isApproved && !isVoid ? "checkmark" : ""} />
        </div>
      </td>
      <td {...cellClass[6]}>
        {row.customerReferenceCode} &nbsp;
        {!(row.customerReferenceCode) || isVoid ?
        ("") :
        (
         <span>
         <a onClick={() => callback(row, 'pdfInvoice')}>PDF</a>
         </span>
        )}
        </td>
      {<td {...cellClass[7]}>{(row.invoiceNumber) ? row.invoiceNumber.trim() : null}</td>}
      <td {...cellClass[8]}>{(!isVoid) ? row.purchaseOrderNumber : "VOIDED"}</td>
      <td {...cellClass[9]}>{row.orderNote}</td>
    </tr>
  );
};

PreviousOrdersRow.propTypes = {
  data: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  headers: PropTypes.array
};
