import React from 'react';

const LoadingIndicator = () => {
  return (
    <div className="fullscreen-shader">
      <div className="fullscreen-centered">
        <i className="fa fa-5x fa-spin fa-refresh"/>
      </div>
    </div>
  );
};

export default LoadingIndicator;
