import {MP_GET_ORDER_GUIDE_HEADER_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';
import moment                   from 'moment';

export default function update(state = initialState.orderGuides, action) {
  if (action.type === MP_GET_ORDER_GUIDE_HEADER_COMPLETE) {
    if(action.payload.orderGuide[0].deliveryDate){
      action.payload.orderGuide[0].deliveryDate = moment(action.payload.orderGuide[0].deliveryDate).format();
    }
    return action.payload.orderGuide;
  } else {
    return state;
  }
}
