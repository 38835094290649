import {MP_SEARCH_REPORTS_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';
import moment                 from 'moment';

export default function update(state = initialState.reportsStartDate, action) {
  if (action.type === MP_SEARCH_REPORTS_COMPLETE) {
    return moment(action.payload.startDate);
  } else {
    return state;
  }
}
