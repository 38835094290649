import {fork, all} from 'redux-saga/effects';
import {watchAuthProfileRetrieval} from './authProfileSaga';
import * as mpo from './mpOnlineSaga';
import * as document from './documentSaga';

export default function* root() {
  yield all([
    fork(watchAuthProfileRetrieval),
    fork(mpo.watchGetOrderGuideHeader),
    fork(mpo.watchGetOrderGuideItems),
    fork(mpo.watchGetVendor),
    fork(mpo.watchGetConcept),
    fork(mpo.watchGetConceptAttributes),
    fork(mpo.watchCreatePurchaseOrder),
    fork(mpo.watchSearchPreviousOrders),
    fork(mpo.watchSearchReports),
    fork(mpo.watchGetVendorItems),
    fork(mpo.watchAddSelectedItems),
    fork(mpo.watchGetEditableOrderGuideItems),
    fork(mpo.watchUpdateOrderGuideItems),
    fork(mpo.watchSetDefaultOrderGuide),
    fork(mpo.watchDeleteSelectedItems),
    fork(document.watchGetOrderGuideItemsPdf),
    fork(document.watchGetEditableOrderGuideItemsPdf),
    fork(document.watchGetPreviousOrdersPdf),
    fork(document.watchGetReportsPdf),
    fork(document.watchGetOrderDetailPdf),
    fork(document.watchGetReportDetailPdf),
    fork(document.watchGetOrderGuideItemsExcel),
    fork(document.watchGetEditableOrderGuideItemsExcel),
    fork(document.watchGetPreviousOrdersExcel),
    fork(document.watchGetReportsExcel),
    fork(document.watchGetOrderDetailExcel),
    fork(document.watchGetReportDetailExcel),
    fork(mpo.watchGetVendorLocation),
    fork(mpo.watchGetConceptVendorCode),
    fork(mpo.watchGetAccountIdsAndNames),
    fork(mpo.watchGetActiveMessages),
    fork(mpo.watchGetOrderGuideItemMaxPurchaseQuantity)
  ]);
}
