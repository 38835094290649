import {GET_ACCOUNTIDS_NAMES_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.relatedAccounts, action) {
  if (action.type === GET_ACCOUNTIDS_NAMES_COMPLETE) {
    const actualAccounts = action.payload.relatedAccounts.filter( x => {
      let includeThis = (action.payload.relatedAccountIds && action.payload.relatedAccountIds.indexOf(x.accountId) > -1);
      return includeThis;
    });
    return actualAccounts.length === 0 ? action.payload.relatedAccounts : actualAccounts; //action.payload.relatedAccounts;
  } else {
    return state;
  }
}
