export class MessageType {
  static get SUCCESS() {
    return "SUCCESS";
  }

  static get WARNING() {
    return "WARNING";
  }

  static get ERROR() {
    return "ERROR";
  }
}

export class FileType {
  static get PDF() {
    return "PDF";
  }

  static get Excel() {
    return "Excel";
  }
}

export class ModalTypes {
  static get BelowQuantityThreshold() {
    return 'BelowQuantityThreshold';
  }

  static get OrderPastCutOff() {
    return 'OrderPastCutOff';
  }

  static get ItemDeleteWarning() {
    return 'ItemDeleteWarning';
  }

  static get SystemError() {
    return 'SystemError';
  }

  static get DayNotInDeliveryRoute() {
    return 'DayNotInDeliveryRoute';
  }

  static get OrderExceedsMaximumQuantity() {
    return 'OrderExceedsMaximumQuantity';
  }
}

export class MessagePriority {
  static get InformationalPriority() {
    return "0";
  }

  static get HighPriority() {
    return "1";
  }
  
  static get MediumPriority() {
    return "2";
  }
  
  static get LowPriority() {
    return "3";
  }  
}

export class MessageLocation {
  static get HeaderBanner() {
    return "headerbanner";
  }

  static get OrderGuideBanner() {
    return "orderguidebanner";
  }
}

