import React        from "react";
import PropTypes    from "prop-types";
import { connect }  from "react-redux";
import defaultImage from '../../assets/images-60/default.png';

export class HeaderNoMenu extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    logout: PropTypes.func
  };

  state = {
    image: defaultImage
  };

  componentDidMount(){
    this.getImage();
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.account) !== JSON.stringify(this.props.account)) {
      this.getImage();
    }
  }

  getImage = async () => {
    let image;
    const imageName = this.getImageName();
    try {
      image = await import("../../assets/images-60/".concat(imageName));
    } catch (e) {
      console.error(e);
      image = await import("../../assets/images-60/".concat('default.png'));
    }
    this.setState({
      image: image.default
    });
  };

  getImageName = () => {
    const {hostUrl} = this.props.account;
    return hostUrl !== undefined ? hostUrl.toLowerCase().concat('-header.png') : 'default.png';
  };

  render() {
    const { image } = this.state;

    return (
      <header>
        <div id="header-container-div">
          {/* LOGO & LINKS */}
          <div className="col-md-6 header-col-2">
            <img className="header-logo" src={image} alt="CustomerLogo" />
          </div>
          <div className="col-md-6 header-col-2">
            <span className="pull-right header-links">
                <span id="username">Welcome { this.props.account.webUserName }!</span>
                <span className="pad-left-10">|</span>
                <a href="http://blog.robinsonfresh.com/fresh-from-the-field/" target="_blank" rel="noreferrer" id="marketReport" className="pad-left-10">Market Report</a>
                <span className="pad-left-10">|</span>
                <a id="logout" className="pad-left-10" onClick={this.props.logout}>Logout</a>
            </span>
          </div>
        </div>

        {/* MENU-BAR - It is empty here */}
        <div className="col-md-12 menu-bar">
          <div className="col-md-8 pad-left-10"/>
          <div className="col-md-4 pad-right-0"/>
        </div>
      </header>
    );
  }
}

export const mapStateToProps = state => {
  return {
    account: state.authProfile
  };
};

export default connect(mapStateToProps)(HeaderNoMenu);
