import React                    from 'react';
import { Redirect }             from 'react-router-dom';
import PropTypes                from 'prop-types';
import { connect }              from 'react-redux';
import { bindActionCreators }   from 'redux';

import * as authProfileActions  from '../../actions/authProfileActions';
import * as mpOnlineActions     from '../../actions/mpOnlineActions';
import initialState from '../../reducers/initialState';
import { sortItems, isNullorUndefined, arraysEqual }    from "../../common/utils/generalFunctions";
import { Dropdown }             from '../common/dropdown';
import _ from 'lodash';

export class SplashPage extends React.Component {

  static propTypes = {
    authProfileActions: PropTypes.object,
    mpActions: PropTypes.object,
    profile: PropTypes.object,
    relatedAccounts: PropTypes.array,
    accountId: PropTypes.number,
    setParentState: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      localProfile: this.props.profile,
      goToOrderGuide: false, //(!isNullorUndefined(this.props.profile.relatedConceptIds) && !isNullorUndefined(this.props.profile.relatedAccountIds)),
      conceptOptions: [],
      selectedConceptOption: {key: 0, text: 'Please Select a Concept', value: 0},
      accountOptions: [],
      selectedAccountOption: {key: 0, text: 'Please Select an Account', value: 0},
      availableRelatedAccounts: []
    };

    this.onConceptIdChange = this.onConceptIdChange.bind(this);
  }

  componentDidMount() {
    if ((!this.props.profile || !this.props.profile.accountId) && this.props.accountId === 0) {
      this.props.authProfileActions.retrieveProfile();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let nextState = {};
    let nextProps = this.props;
    let availableAccountArray = [];
    let relatedAccounts = (nextProps.relatedAccounts) ? [...nextProps.relatedAccounts] : [];

    if (!_.isEqual(prevProps.profile, this.props.profile)) {
      if ((nextProps.profile) && (!(Object.entries(nextProps.profile).length === 0 && nextProps.profile.constructor === Object) && nextProps.profile !== prevState.localProfile))
      {
        let hasRelatedConceptIds = !isNullorUndefined(nextProps.profile.relatedConceptOptions) && (nextProps.profile.relatedConceptOptions.length > 0);
        let hasRelatedAccountIds = !isNullorUndefined(nextProps.profile.relatedAccountIds) && (nextProps.profile.relatedAccountIds.length > 0);
        const goToOrderGuide = (hasRelatedConceptIds || hasRelatedAccountIds) ? false : true;
        nextState = {
          localProfile: nextProps.profile,
          goToOrderGuide,
          conceptOptions: (hasRelatedConceptIds) ? this.getDropdownOptions(nextProps.profile.relatedConceptOptions) : null
        };
  
        this.props.mpActions.getConceptAttributes(nextProps.profile.conceptId);
        this.setState(nextState);
      }
    }

    if(prevState.availableRelatedAccounts.length !== 0){
      for (let x in prevState.availableRelatedAccounts) {
        availableAccountArray.push(prevState.availableRelatedAccounts[x]);
      }
    }

    if (relatedAccounts.length > 0 && !arraysEqual(availableAccountArray, relatedAccounts)) {
      this.processRelatedAccounts( relatedAccounts );
    }

    if (nextProps.accountId > 0) {
      this.setState({goToOrderGuide: true});
    }
  }

  processRelatedAccounts = (relatedAccounts) => {
    let relatedAccountIds = this.props.profile.relatedAccountIds ? [...this.props.profile.relatedAccountIds] : [];
    let actualRelatedAccounts = [];

    if (relatedAccountIds.length > 0) {
      actualRelatedAccounts = relatedAccounts.filter( x => {
        let includeThis = (relatedAccountIds.indexOf(x.accountId) > -1);
        return includeThis;
      });
    }

    actualRelatedAccounts = (actualRelatedAccounts.length === 0) ? [...relatedAccounts] : actualRelatedAccounts;

    this.setState({
      accountOptions: this.getDropdownOptions( actualRelatedAccounts ),
      availableRelatedAccounts: actualRelatedAccounts
    });

    this.props.setParentState({
      relatedAccounts: actualRelatedAccounts
    });
  };

  getDropdownOptions = (options) => {
    let dropdownOptions = [];

    if (options.length > 0) {
      dropdownOptions = options.map( x => {
        return (
          (x.accountId) ?
          {
            key: x.accountId,
            text: x.accountName,
            value: x.accountId
          } :
          {
            key: x.value,
            text: x.display,
            value: x.value
          }
        );
      });
    }

    let sortConfig = [{field:'text', direction:'asc'}, {field:'value', direction:'asc-int'}];
    return sortItems( dropdownOptions, sortConfig );
  };

  getAccountOptions = (conceptId) => {
    this.props.mpActions.getAccountIdsAndNamesStart(conceptId, null, this.state.localProfile.relatedAccountIds);
  };

  onAccountIdChange = (option) => {
    this.props.setParentState(
      {
        accountId: option.value,
        relatedAccounts: this.state.availableRelatedAccounts
      }
    );
    this.props.mpActions.setAccountId(option.value);
    this.props.authProfileActions.retrieveProfileComplete(initialState.profile);
  };

  onConceptIdChange = (option) => {
    this.getAccountOptions(option.value);
    this.setState({selectedConceptOption: option});
  };

  render() {

    if (this.state.goToOrderGuide) {
      return (<Redirect to="/Order-Guide" {...this.props}/>);
    }

    return (
      <div className="container-fluid">
        {(this.state.conceptOptions?.length > 0) ?
        <div className="col-md-12 text-center top-bottom-pad-10">
          <div className="inline-block spacer">Concept: </div>
          <div className="width300 inline-block">
            <Dropdown     
              id="ddConceptSelect"
              dropdownId="ddConceptSelect"
              options={this.state.conceptOptions}
              selectedOption={this.state.selectedConceptOption}
              onSelectionChange={this.onConceptIdChange}
              addOnButtonClass="table-cell-right"
              addOnOptionClass="width300" />
          </div>
        </div>
        : null }
        {(this.state.accountOptions?.length > 0) ?
        <div className="col-md-12 text-center top-bottom-pad-10">
          <div className="inline-block spacer">Account: </div>
          <div className="width300 inline-block">
            <Dropdown     
              id="ddAccountSelect"
              dropdownId="ddAccountSelect"
              options={this.state.accountOptions}
              selectedOption={this.state.selectedAccountOption}
              onSelectionChange={this.onAccountIdChange}
              addOnButtonClass="table-cell-right"
              addOnOptionClass="width300" />
          </div>
        </div>
        : null }
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    profile: state.authProfile,
    relatedAccounts: state.relatedAccounts,
    accountId: state.accountId
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    authProfileActions: bindActionCreators(authProfileActions, dispatch),
    mpActions: bindActionCreators(mpOnlineActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashPage);
