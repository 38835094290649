import {GET_VENDORLOCATION_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.vendorLocation, action) {
  if (action.type === GET_VENDORLOCATION_COMPLETE) {
    return action.payload.vendorLocation;
  } else {
    return state;
  }
}
