import toastr from 'toastr';
import { MessageType } from './stringConstants';

export function displayMessage(message) {
  toastr.options = {
    "progressBar": true
  };

  switch (message.type) {
    case MessageType.SUCCESS:
      toastr.success(message.text);
      break;
    case MessageType.WARNING:
      toastr.warning(message.text);
      break;
    case MessageType.ERROR:
      toastr.error(message.text);
      break;
    default:
      return "Unknown message type received";
  }
}
