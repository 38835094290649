import React          from "react";
import PropTypes      from "prop-types";
import { connect }    from "react-redux";
import * as selectors from "./../../selectors";
import chrLogoFooter from '../../assets/images/chr-footer.png';

export class Footer extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    account: PropTypes.object,
    elementPresentation: PropTypes.object
  };

  previewOrder = () => {
    this.props.history.push("/Preview-Order");
  };

  render() {
    const {account} = this.props;
    const color = account && account.conceptColor ? account.conceptColor : "#4E99CD";
    const {isPreviewOrderButtonVisible} = this.props.elementPresentation;

    return (
      <footer className="sticky-footer" style={{borderTop: `1px solid ${color}`}}>
        <div className="pull-left">
          <div className="chr-logo-footer">
            <img alt="CH Robinson logo" src={chrLogoFooter} className="vertical-align-middle" />
            <span className="pad-left-10">C.H. Robinson</span>
            <span className="pad-left-10">|</span>
            <span className="pad-left-10">Managed Procurement Online © Copyright { new Date().getFullYear() }</span>
            <span className="pad-left-10">|</span>
            <a href="https://www.chrobinson.com/en-us/Privacy-Policy/" target="_blank" rel="noreferrer" className="pad-left-10">Privacy Policy</a>
          </div>
        </div>

        <div className="sticky-footer-container pull-right">
          {
            isPreviewOrderButtonVisible &&
            <button className="btn btn-primary" id="previewOrder" onClick={() => this.previewOrder()}>Preview Order</button>
          }
        </div>
      </footer>
    );
  }
}

export const mapStateToProps = state => {
  return {
    account: selectors.getAccount(state),
    elementPresentation: selectors.getElementPresentationProps(state)
  };
};

export default connect(mapStateToProps)(Footer);

