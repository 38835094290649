import {MP_SEARCH_PREVIOUS_ORDERS_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';
import moment                 from 'moment';

export default function update(state = initialState.previousOrdersStartDate, action) {
  if (action.type === MP_SEARCH_PREVIOUS_ORDERS_COMPLETE) {
    return moment(action.payload.startDate);
  } else {
    return state;
  }
}
