import {MP_SEARCH_PREVIOUS_ORDERS_COMPLETE} from '../actions/actionTypes';
import initialState from './initialState';

export default function update(state = initialState.previousOrders, action) {
  if (action.type === MP_SEARCH_PREVIOUS_ORDERS_COMPLETE) {
    return action.payload.previousOrders;
  } else {
    return state;
  }
}
