import React from "react";
import PropTypes from "prop-types";
import * as generalFunctions from "../../common/utils/generalFunctions";

export const OrderSummary = (props) => {
  const { vendor, account, orderTotal, isSubmitButtonDisabled, submitButtonHandler, inputChangeHandler, shipDate, deliveryDate, shipDateVisible, deliveryDateVisible } = props;

  const name = account ? ((account.name) ? account.name : account.accountName) : "";
  const city = account ? ((account.city) ? account.city : account.accountCity) : "";
  const state = account ? ((account.state) ? account.state : account.accountState) : "";
  const address = account ? ((account.address) ? account.address : account.accountAddress1) : "";
  const vendorName = vendor ? vendor.vendorName : "";
  const total = orderTotal ? orderTotal.toFixed(2) : 0;

  return (
    <div className="panel panel-default height_100percent display_inline-grid" id="orderSummary">
      <div className="panel-heading">Order Summary</div>
      <div className="panel-body order-summary overflow_y_auto">

        {/* SHIP TO */}
        <div className="col-sm-12">
          <p className="col-sm-5">Ship To:</p>
          <div className="col-sm-7">
            <label className="col-sm-12" id="shipToName">{name}</label>
            <label className="col-sm-12" id="shipToAddress">{address}</label>
            <label className="col-sm-12" id="shipToCityState">{city}, {state}</label>
          </div>
        </div>

        {/* SEPARATOR LINE */}
        <span className="col-md-12"><hr /></span>

        {/* SHIP FROM */}
        <div className="col-sm-12">
          <p className="col-sm-5">Ship From:</p>
          <div className="col-sm-7">
            <label className="col-sm-12" id="vendorName">{vendorName}</label>
          </div>
        </div>

        {/* SEPARATOR LINE */}
        <span className="col-md-12"><hr /></span>

        {/* DATES & ORDER TOTAL */}
        {shipDateVisible &&
          <div className="col-sm-12">
            <p className="col-sm-7">Requested Ship Date:</p>
            <div className="col-sm-5">
              <label className="col-sm-12 isCurrency">{generalFunctions.getFormattedDisplayDate(shipDate)}</label>
            </div>
          </div>}

        {deliveryDateVisible &&
          <div className="col-sm-12">
            <p className="col-sm-7">Requested Arrival Date:</p>
            <div className="col-sm-5">
              <label className="col-sm-12 isCurrency">{generalFunctions.getFormattedDisplayDate(deliveryDate)}</label>
            </div>
          </div>}

        <div className="col-sm-12">
          <p className="col-sm-7">Order Total:</p>
          <div className="col-sm-5">
            <label className="col-sm-12 isCurrency">{(parseInt(total) === 0) ? 'MKT' : `$${total}`}</label>
          </div>
        </div>

        {/* SEPARATOR LINE */}
        <span className="col-md-12"><hr /></span>

        {/* ORDER NUMBER & SPECIAL NOTES */}
        <div className="col-sm-12">
          <input type="text"
            id="orderNumber"
            className="form-control textbox"
            placeholder="Purchase Order Number"
            onChange={(e) => inputChangeHandler(e)} />
        </div>

        <div className="col-sm-12">
          <textarea rows="3"
            id="orderNote"
            className="form-control textbox"
            placeholder="Special instructions or notes to distributor"
            onChange={(e) => inputChangeHandler(e)} 
            maxLength="250" />
        </div>

        <div className="col-sm-12">
            <p className="col-sm-12">250 characters max</p>
          </div>

        <div className="pull-right">
          <button id="submitOrder" className="btn btn-primary" disabled={isSubmitButtonDisabled} onClick={() => submitButtonHandler()}>Submit Order</button>
        </div>

      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  vendor: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  orderTotal: PropTypes.number.isRequired,
  shipDate: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
  shipDateVisible: PropTypes.bool.isRequired,
  deliveryDateVisible: PropTypes.bool.isRequired,
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
  submitButtonHandler: PropTypes.func.isRequired,
  inputChangeHandler: PropTypes.func.isRequired
};
