import { mpOnlineGatewayApiAdapter } from '../utils/apiAdapters'; //eslint-disable-line import/named
import { formatItems } from "../utils/responseHelpers";
import { getFormattedSearchDate, isNullorUndefined } from "../utils/generalFunctions";
import moment from 'moment';

export class MpOnlineGatewayApi {
  static login(username, password) {
    return new Promise((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/account`, {username, password})
        .then(response => {
            resolve(response.data);
        })
        .catch(() => {
          reject('Error getting account information');
        });
    });
  }

  static readOrderGuideHeader(accountId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/OrderGuideHeaders/${accountId}`)
      .then(response => {
        if(!response.data.orderGuideHeaders){
          response.data["orderGuideHeaders"] = {};
        }
        resolve(response.data.orderGuideHeaders);
      })
      .catch(() => {
        reject(`Error getting order guide information`);
      });
    });
  }

  static getOrderGuideItemsById(orderGuideHeaderId, deliveryDate, shipDate) {
    const requestedDeliveryDate = isNullorUndefined(deliveryDate) ? deliveryDate : getFormattedSearchDate( deliveryDate, 'start');
    const requestedShipDate = isNullorUndefined(shipDate) ? shipDate : getFormattedSearchDate(shipDate, 'start');
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/OrderGuideItems/${orderGuideHeaderId}`, {params: {requestedDeliveryDate, requestedShipDate}})
      .then(response => {
        resolve(formatItems(response.data));
      })
      .catch(() => {
        reject(`Error getting order guide items`);
      });
    });
  }

  static getVendor(vendorId, conceptId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/Vendors/${vendorId}`, {params: {conceptId}})
      .then(response => {
        resolve(response.data.vendor);
      })
      .catch(() => {
        reject(`Error getting vendor`);
      });
    });
  }

  static getConcept(username) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/Concepts/${username}`)
      .then(response => {
        resolve(response.data.concept);
      })
      .catch(() => {
        reject(`Error getting concept`);
      });
    });
  }

  static createPurchaseOrder(purchaseOrder) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/PurchaseOrders`, {purchaseOrder})
      .then(response => {
        resolve(response.data.headerId);
      })
      .catch(() => {
        reject(`Error creating purchase order`);
      });
    });
  }

  static readPreviousOrders(accountId, startDate, endDate) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/PreviousOrders/${accountId}`, {params: {startDate, endDate}})
      .then(response => {
        if(!response.data.previousOrders){
          response.data["previousOrders"] = {};
        }
        resolve(response.data.previousOrders);
      })
      .catch(() => {
        reject(`Error reading previous orders`);
      });
    });
  }

  static readReports(accountId, startDate, endDate) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/Reports/${accountId}`, {params: {startDate, endDate}})
      .then(response => {
        if(!response.data.invoiceReports){
          response.data["invoiceReports"] = {};
        }
        resolve(response.data.invoiceReports);
      })
      .catch(() => {
        reject(`Error reading reports`);
      });
    });
  }

  static readVendorItems(orderGuideHeaderId, conceptId, vendorId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/VendorItems`, {params: {orderGuideHeaderId, conceptId, vendorId}})
      .then(response => {
        resolve(response.data.vendorItems);
      })
      .catch(() => {
        reject(`Error reading vendor items`);
      });
    });
  }

  static createOrderGuideItems(selectedItems) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/OrderGuideItems`, {vendorItems: selectedItems})
      .then(response => {
        resolve(response.data.orderGuideItemIds);
      })
      .catch(() => {
        reject(`Error creating orderGuideItems`);
      });
    });
  }

  static readEditableOrderGuideItems(orderGuideHeaderId, deliveryDate, shipDate) {
    const requestedDeliveryDate = isNullorUndefined(deliveryDate) ? deliveryDate : getFormattedSearchDate( deliveryDate, 'start');
    const requestedShipDate = isNullorUndefined(shipDate) ? shipDate : getFormattedSearchDate(shipDate, 'start');
    return new Promise ((resolve, reject) => {
      //mpOnlineGatewayApiAdapter.get(`/OrderGuideItems/${orderGuideHeaderId}/Edit`, {params: {requestedDeliveryDate, requestedShipDate}})
      mpOnlineGatewayApiAdapter.get(`/OrderGuideItems/${orderGuideHeaderId}`, {params: {requestedDeliveryDate, requestedShipDate}})
      .then(response => {
        resolve(response.data.orderGuideItems);
      })
      .catch(() => {
        reject(`Error getting editable order guide items`);
      });
    });
  }

  static updateOrderGuideItems(orderGuideItems) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.put(`/OrderGuideItems`, {orderGuideItems: orderGuideItems})
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        reject(`Error updating orderGuideItems`);
      });
    });
  }

  static updateOrderGuideHeaders(orderGuideHeaders) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.put(`/OrderGuideHeaders`, {orderGuideHeaders: orderGuideHeaders})
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        reject(`Error updating orderGuideHeaders`);
      });
    });
  }

  static readConceptAttributes(conceptId, accountId) {
    let workUrl = (typeof(accountId) === "undefined") ? `/ConceptAttributes/${conceptId}/0` : `/ConceptAttributes/${conceptId}/${accountId}`;
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(workUrl)
      .then(response => {
        resolve(response.data.conceptAttributes);
      })
      .catch(() => {
        reject(`Error reading conceptAttributes`);
      });
    });
  }

  static getOrderGuideItemsPdf(orderGuideHeaderId, accountId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Pdf/OrderGuideItems`, {orderGuideHeaderId: orderGuideHeaderId, accountId: accountId})
      .then(response => {
        if(response.data.pdf){
          resolve(response.data.pdf);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading orderGuideItems pdf - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting orderGuideItems pdf`);
          }
        }
      })
      .catch(() => {
        reject(`Error downloading orderGuideItems pdf`);
      });
    });
  }

  static getEditableOrderGuideItemsPdf(orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Pdf/EditableOrderGuideItems`, {orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId})
      .then(response => {
        if(response.data.pdf){
          resolve(response.data.pdf);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading editableOrderGuideItems pdf - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting editableOrderGuideItems pdf`);
          }
        }
      })
      .catch(() => {
        reject(`Error downloading editableOrderGuideItems pdf`);
      });
    });
  }

  static getPreviousOrdersPdf(accountId, startDate, endDate) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Pdf/PreviousOrders`, {accountId: accountId, startDate: startDate, endDate: endDate})
      .then(response => {
        if(response.data.pdf){
          resolve(response.data.pdf);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading previousOrders pdf - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting previousOrders pdf`);
          }
        }
      })
      .catch(() => {
        reject(`Error downloading previousOrders pdf`);
      });
    });
  }

  static getReportsPdf(startDate, endDate, accountId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Pdf/Reports`, {startDate: startDate, endDate: endDate, accountId: accountId})
      .then(response => {
        if(response.data.pdf){
          resolve(response.data.pdf);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading reports pdf - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting reports pdf`);
          }
        }
      })
      .catch(() => {
        reject(`Error downloading reports pdf`);
      });
    });
  }

  static deleteSelectedItems(orderGuideItems){ //itemIds) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.delete(`/OrderGuideItems`, {data: {orderGuideItems: orderGuideItems}}) //itemIds}})
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        reject(`Error deleting items`);
      });
    });
  }

  static getOrderDetailPdf(accountId, purchaseOrderHeaderId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Pdf/OrderDetail`, {accountId, purchaseOrderHeaderId})
      .then(response => {
        if(response.data.pdf){
          resolve(response.data.pdf);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading order detail pdf - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting order detail pdf`);
          }
        }
      })
      .catch(() => {
        reject(`Error downloading order detail pdf`);
      });
    });
  }

  static getReportDetailPdf(accountId, invoiceId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Pdf/ReportDetail`, {accountId, invoiceId})
      .then(response => {
        if(response.data.pdf){
          resolve(response.data.pdf);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading report detail pdf - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting report detail pdf`);
          }
        }
      })
      .catch(() => {
        reject(`Error downloading report detail pdf`);
      });
    });
  }

  static getVendorLocation(warehouseCode, accountId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/Locations/${warehouseCode}`, {params:{accountId}})
      .then(response => {
        resolve(response.data.vendorLocation);
      })
      .catch(() => {
        reject('Error getting vendor location data.');
      });
    });
  }

  static getConceptVendorCode(conceptId, vendorId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/GetConceptVendorCode?ConceptVendorIds=%5B%7BItem1%3A${conceptId}%2CItem2%3A${vendorId}%7D%5D`)
      .then(response => {
        resolve(response.data.conceptVendorCodes);
      })
      .catch(() => {
        reject('Error getting concept vendor codes.');
      });
    });
  }

  static getOrderGuideItemsExcel(orderGuideHeaderId, accountId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Excel/OrderGuideItems`, {orderGuideHeaderId, accountId})
      .then(response => {
        if(response.data.file){
          resolve(response.data.file);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading orderGuideItems excel - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting orderGuideItems excel`);
          }
        }
      })
      .catch((err) => {
        reject(`Error downloading orderGuideItems excel`);
      });
    });
  }

  static getEditableOrderGuideItemsExcel(orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Excel/EditableOrderGuideItems`, {orderGuideHeaderId, deliverySchedule, onHandOnReceivedDays, accountId, vendorId})
      .then(response => {
        if(response.data.file){
          resolve(response.data.file);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading editableOrderGuideItems excel - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting editableOrderGuideItems excel`);
          }
        }
      })
      .catch((err) => {
        reject(`Error downloading editableOrderGuideItems excel`);
      });
    });
  }

  static getPreviousOrdersExcel(accountId, startDate, endDate) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Excel/PreviousOrders`, {accountId, startDate, endDate})
      .then(response => {
        if(response.data.file){
          resolve(response.data.file);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading previousOrders excel - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting previousOrders excel`);
          }
        }
      })
      .catch((err) => {
        reject(`Error downloading previousOrders excel`);
      });
    });
  }

  static getReportsExcel(startDate, endDate, accountId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Excel/Reports`, {startDate, endDate, accountId})
      .then(response => {
        if(response.data.file){
          resolve(response.data.file);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading reports excel - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting reports excel`);
          }
        }
      })
      .catch((err) => {
        reject(`Error downloading reports excel`);
      });
    });
  }

  static getOrderDetailExcel(accountId, headerId) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Excel/OrderDetail`, {accountId, purchaseOrderHeaderId: headerId})
      .then(response => {
        if(response.data.file){
          resolve(response.data.file);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading orderDetail excel - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting orderDetail excel`);
          }
        }
      })
      .catch((err) => {
        reject(`Error downloading orderDetail excel`);
      });
    });
  }

  static getReportDetailExcel(accountId, invoiceId, vendorName) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/Excel/ReportDetail`, {accountId, invoiceId, vendorName})
      .then(response => {
        if(response.data.file){
          resolve(response.data.file);
        }else{
          if(response.data.responseStatus.errorCode === "InternalServerError"){
            reject(`Error downloading reportDetail excel - ${response.data.responseStatus.message}`);
          }else{
            reject(`Error requesting reportDetail excel`);
          }
        }
      })
      .catch((err) => {
        reject(`Error downloading reportDetail excel`);
      });
    });
  }

  static getAccountIdsAndNames(conceptId, conceptCode) {
    return new Promise ((resolve, reject) => {
      mpOnlineGatewayApiAdapter.get(`/Accounts`, {params: {conceptId, conceptCode}})
      .then(response => {
        resolve(response.data.accounts);
      })
      .catch((err) => {
        reject(`Error requesting account list: ${err}`);
      });
    });
  }

  static readActiveMessages(vendorId, accountId, messageDate) {
    return new Promise ((resolve, reject) => {
      accountId = (typeof(accountId) === "undefined" || accountId === 0) ? null : accountId;
      vendorId = (typeof(vendorId) === "undefined" || vendorId === 0) ? null : vendorId;
      mpOnlineGatewayApiAdapter.get(`/InterfaceMessages`, {params: {vendorId, accountId, messageDate}})
      .then(response => {
        resolve(response.data.messages);
      })
      .catch(() => {
        reject(`Error getting messages`);
      });
    });
  }

  static readOrderGuideItemMaxQuantity(accountId, vendorId, deliveryDate, orderGuideItem) {
    return new Promise((resolve, reject) => {
      mpOnlineGatewayApiAdapter.post(`/OrderGuideItem/MaxPurchaseQuantity`, {accountId, vendorId, orderGuideItem, deliveryDate})
      .then(response => {
        let maxPurchaseQuantityDetail = {
          maxPurchaseQuantity: response.data.maxPurchaseQuantity,
          message: response.data.message
        };
        resolve(maxPurchaseQuantityDetail);
      })
      .catch(() => {
        reject(`Error retrieving MaxPurchaseQuantity`);
      });
    });
  }
}

export default MpOnlineGatewayApi;
