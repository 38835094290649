import React                  from 'react';
import PropTypes              from 'prop-types';
import { connect }            from 'react-redux';
import { Switch, Route }      from 'react-router-dom';
import { getVendor }          from '../selectors';
import { AuthenticatedRoute } from "../components/common/AuthenticatedRoute";
import { AuthenticatedRouteNoHeader } from "../components/common/AuthenticatedRouteNoHeader";
import auth                   from '../auth/authInit';
import Callback               from '../components/common/Callback';
import LoadingIndicator       from './common/loading';
import SplashPage             from "../components/splash-page";
import OrderGuide             from "../components/order-guide";
import PreviewOrder           from "../components/preview-order";
import EditOrderGuide         from "../components/edit-order-guide/EditOrderGuide";
import PreviousOrders         from "../components/previous-orders";
import Reports                from "../components/order-reports";
import Support                from "../components/support";
import PageNotFound           from "../components/common/PageNotFound";
import ForgotPassword         from "../components/login/ForgotPassword";
import Login                  from "../components/login/Login";
import initialState           from '../reducers/initialState';

export class App extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    history: PropTypes.object,
    vendor: PropTypes.object.isRequired
  };

  constructor(props){
    super(props);

    this.setParentState.bind(this);
    this.logoutResetState.bind(this);
    this.state = {...initialState};
  }

  UNSAFE_componentWillMount() {
    if (window.location.hash.includes('access_token')) {
      auth.parseHash();
    }
  }

  setParentState = (stateUpdater) => {
    this.setState(stateUpdater);
  };

  logoutResetState = () => {
    auth.logout();
    this.setState(initialState);

    window.location.hash = '/';
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          {/* PUBLIC ROUTES */}
          <Route exact path="/Login"          render={(props) => <Login          auth={auth} {...props} />} />
          <Route exact path="/Callback"       render={(props) => <Callback       auth={auth} {...props} />} />
          <Route exact path="/ForgotPassword" render={(props) => <ForgotPassword auth={auth} {...props} />} />

          {/* AUTHENTICATED ROUTES */}
          <AuthenticatedRouteNoHeader {...this.props}
            exact path="/"
            Component={SplashPage}
            setParentState={this.setParentState}
            logout={this.logoutResetState}
            />
          <AuthenticatedRoute {...this.props}
            exact path="/Order-Guide"
            Component={OrderGuide}
            setParentState={this.setParentState}
            logout={this.logoutResetState}
            />
          <AuthenticatedRoute {...this.props}
            exact path="/Preview-Order"
            setParentState={this.setParentState}
            Component={PreviewOrder}
            logout={this.logoutResetState}
            />
          <AuthenticatedRoute
            {...this.props}
            exact path="/Edit-Order-Guide"
            Component={EditOrderGuide}
            logout={this.logoutResetState}
            />
          <AuthenticatedRoute
            {...this.props}
            exact path="/Previous-Orders"
            Component={PreviousOrders}
            setParentState={this.setParentState}
            previousOrdersFilterText={this.state.previousOrdersFilterText}
            previousOrdersFilteredOrders={this.state.previousOrdersFilteredOrders}
            logout={this.logoutResetState}
            />
          <AuthenticatedRoute
            {...this.props}
            exact path="/Reports"
            Component={Reports}
            setParentState={this.setParentState}
            reportsFilterText={this.state.reportsFilterText}
            reportsFilteredReports={this.state.reportsFilteredReports}
            logout={this.logoutResetState}
            />
          <AuthenticatedRoute {...this.props} exact path="/Support"
            Component={Support}
            logout={this.logoutResetState}
            />

          {/* UNKNOWN ROUTES */}
          <Route component={PageNotFound} />
        </Switch>

        {this.props.loading && <LoadingIndicator />}
      </React.Fragment>
    );
  }
}

export const mapStateToProps = state => {
  return {
    loading: state.serviceCallsInProgress > 0,
    vendor: getVendor(state)
  };
};

export default connect(mapStateToProps)(App);
