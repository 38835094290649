import React from "react";
import PropTypes from "prop-types";
import * as generalFunctions from "../../common/utils/generalFunctions";

function getTotalQuantity(rows) {
  let totalQuantity = 0;

  rows.forEach(row => {
    totalQuantity += parseInt(row.order);
  });

  return totalQuantity;
}

function getTotalAmount(rows) {
  let totalAmount = 0;

  rows.forEach(row => {
    if (row.price === 0) { return 'MKT'; }
    totalAmount += generalFunctions.round(row.price * row.order, 2);
  });

  return totalAmount.toFixed(2);
}

export const TotalRow = (props) => {
  const totalQuantity = getTotalQuantity(props.rows);
  const totalAmount = getTotalAmount(props.rows);
  const cellClass = {};
  let i = 0;
  if (props.headers) {
    for(i = 0; i < props.headers.length; i++){
      cellClass[i]={className: props.headers[i].className};
    }
  }

  return (
    <tr>
      <td {...cellClass[0]} >{totalQuantity}</td>
      <td {...cellClass[1]} />
      <td {...cellClass[2]} />
      <td {...cellClass[3]} />
      <td {...cellClass[4]} />
      <td {...cellClass[5]} />
      <td {...cellClass[6]} >{(parseInt(totalAmount) === 0) ? 'MKT' : totalAmount}</td>
      <td {...cellClass[7]} />
      {(props.headers.length > 8) ? <td {...cellClass[8]} /> : null}
      {(props.headers.length > 9) ? <td {...cellClass[9]} /> : null}
    </tr>
  );
};

TotalRow.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array
};
