import _ from "lodash";
import moment from 'moment';
import { MessageType, MessagePriority, MessageLocation } from './stringConstants';

export function getCssClass(messageType) {
  switch (messageType) {
    case MessageType.SUCCESS:
      return 'fa fa-3x fa-check-circle c-green';
    case MessageType.WARNING:
      return 'fa fa-3x fa-exclamation-triangle c-yellow';
    case MessageType.ERROR:
      return 'fa fa-3x fa-exclamation-circle c-red';
    case MessageType.BelowQuantityThreshold:
      return 'fa fa-3x fa-exclamation-triangle c-yellow';
    case MessageType.OrderPastCutOff:
      return 'fa fa-3x fa-exclamation-triangle c-yellow';
    case MessageType.ItemDeleteWarning:
      return 'fa fa-3x fa-exclamation-triangle c-yellow';
    default:
      return "";
  }
}

// items: an array of plain JS objects or ES6 class instances
export function filterItemsV2(items, searchText) {
  const tokens = searchText.split(/\s+/);
  const matched = [];

  items.forEach(item => {
    const propertyNames = Object.getOwnPropertyNames(item);

    propertyNames.forEach(propertyName => {

      // props are preceded with an underscore when the object is an ES6 class
      const propName = propertyName.substring(0, 1) === '_'
        ? propertyName.substring(1)
        : propertyName;

      if (item[propName] !== undefined) {
        const propertyValue = item[propName].toString().toLowerCase();

        const filter = _.filter(tokens, x => {
          return propertyValue.includes(x);
        });

        if (filter.length > 0 && !matched.includes(item)) {
          matched.push(item);
        }
      }
    });

  });

  return matched;
}

export function isValidInteger(value) {
  const integerOnlyRegEx = /^[1-9][\d]*$/;

  if (value === '') {
    return true;
  }

  return integerOnlyRegEx.test(value);
}

export function getConceptAttributeValue(conceptAttributes, attributeName) {
  try {
    const attribute = conceptAttributes.find(x => x.name.toLowerCase() === attributeName.toLowerCase());
    return (attribute || typeof (attribute.value) !== 'undefined' || attribute.value !== '') ? attribute.value : attribute.defaultValue;
  } catch (e) {
    return undefined;
  }
}

export function isNullorUndefined(item) {
  return (typeof item === 'undefined' || item === null);
}

export function sortItems(items, sortConfig) {

  if (isNullorUndefined(sortConfig)) {
    return items;
  }

  return items.sort(function (a, b) {
    let sortReturn = 0;
    for (let i = 0, n = sortConfig.length; i < n; i++) {
      let aInternal = a[sortConfig[i].field];
      let bInternal = b[sortConfig[i].field];
      if (aInternal !== bInternal) {
        let dirConfig = sortConfig[i].direction.toLowerCase().split('-');
        let direction = dirConfig[0];
        let type = 'string';
        let start = 0;
        if (dirConfig.length > 1) {
          type = dirConfig[1];
          if (dirConfig.length > 2) {
            start = parseInt(dirConfig[2]);
          }
        }
        if (type === 'int') {
          let first = parseInt(aInternal) || 0;
          let second = parseInt(bInternal) || 0;
          if (direction === 'asc') {
            first = (first >= start) ? first : 999999;
            second = (second >= start) ? second : 999999;
            sortReturn = first - second;
          } else {
            first = (first >= start) ? first : 0;
            second = (second >= start) ? second : 0;
            sortReturn = second - first;
          }
        } else {
          if (direction === 'asc') {
            sortReturn = 1;
            if (aInternal < bInternal) {
              sortReturn = -1;
            }
          } else {
            sortReturn = -1;
            if (aInternal < bInternal) {
              sortReturn = 1;
            }
          }
        }
      }
      if (sortReturn !== 0) {
        break;
      }
    }

    return sortReturn;
  });
}

export function getFormattedSearchDate(dateValue, dateType) {
  const dateFormat = 'YYYY-MM-DDT' + ((dateType.toLowerCase() === 'start') ? '00:00:00' : '23:59:59.999');

  return moment(dateValue).format(dateFormat);
}

export function getLeftString(workString, splitValue) {
  if (!(workString)) {
    return workString;
  }

  let index = workString.indexOf(splitValue);

  if (index === -1) {
    return workString;
  } else {
    return workString.substr(0, index);
  }
}

export function getFormattedDisplayDate(dateValue, dateFormat, dateOnly) {
  const localFormat = (dateFormat) ? dateFormat : "MM/DD/YYYY";
  let localDateValue;
  let returnValue;

  if (typeof dateValue === "undefined" || dateValue === "") {
    return "";
  }

  if (dateOnly) {
    localDateValue = getLeftString(dateValue, "T");
  } else {
    localDateValue = dateValue;
  }

  returnValue = moment(localDateValue).format(localFormat);

  return returnValue;
}

export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length)
    return false;
  for (let i = arr1.length; i--;) { // potential issue with indexing check?
    if (arr1[i] !== arr2[i])
      return false;
  }

  return true;
}

export const isEmptyObject = obj => {
  for (let name in obj) {
    return false;
  }

  return true;
};

export const getPrice = (price, conceptAttributes) => {
  const FOUR_DECIMAL_PRECISION = 'FourDecimalPrecision';
  const index = conceptAttributes.findIndex(x => x.name.toLowerCase() === FOUR_DECIMAL_PRECISION.toLowerCase());
  const fourDecimal = (index > -1) && conceptAttributes[index].value.toLowerCase() === 'true';
  if (!price) {
    return 'MKT';
  }
  else if (fourDecimal) {
    return roundFixed(price, 4);
  }
  else {
    return roundFixed(price, 2);
  }
};

export const roundFixed = (number, scale) => {
  const roundedFixed = round(number, scale).toFixed(scale);
  return roundedFixed;
};

export const round = (number, scale) => {
  const leftShift = Math.pow(10, scale);
  const rounded = Math.round(number * leftShift) / leftShift;
  return rounded;
};

export function getFilteredActiveMessages (messages, location) {
  const activeMostRecentMessages = (messages || []).filter(x => x.location.toLowerCase() === location);
  if(activeMostRecentMessages.length <= 0) return null;
  return activeMostRecentMessages[0];
}

export function getCssMessageClass (messagePriority) {
  switch (messagePriority) {
      case MessagePriority.InformationalPriority:
        return 'msg-Informational';
      case MessagePriority.HighPriority:
        return 'msg-high-priority';
      case MessagePriority.MediumPriority:
        return 'msg-medium-priority';
      case MessagePriority.LowPriority:
        return 'msg-low-priority';            
      default:
        return "";
    }
}

export function getCssIconClass (messagePriority) {
  switch (messagePriority) {
      case MessagePriority.InformationalPriority:
        return 'fa fa-info-circle icon-informational';
      case MessagePriority.HighPriority:
        return 'fa fa-exclamation-triangle icon-high-priority';
      case MessagePriority.MediumPriority:
        return 'fa fa-exclamation-triangle icon-medium-priority';
      case MessagePriority.LowPriority:
        return 'fa fa-exclamation-circle icon-low-priority';            
      default:
        return "";
    }
}

export function getStyleHeightCalcMainContent(messages)
{
  if(getFilteredActiveMessages (messages, "headerbanner") !== null)
  {
    return 'main-content-padding main-content-height';
  }

  return 'main-content';
}

export function getCssClassHeightCalc(messages, messagesLocation, pageSize, totalRow)
{  
  let heightToRemove = 0;

  (messagesLocation || []).forEach(messageLocation => {
    if(getFilteredActiveMessages (messages, messageLocation) !== null)
    {
      heightToRemove += 1;
    }
  });
  
  if(!pageSize || pageSize <= 0)
  {
    if(heightToRemove === 1)
    {
      if((messagesLocation || []).includes(MessageLocation.HeaderBanner) && totalRow)
      {
        return "table-noPaging-totalRow-heightcalc";
      }
      return "table-header-order-heightcalc";
    } 
    if(heightToRemove === 2)
    {
      return "table-nopaging-heightcalc";
    } 
  }
  else{
    if(heightToRemove === 1)
    {
      return "table-paging-heightcalc";
    } 
  } 

  return "";
}
