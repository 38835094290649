import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { getCssClass } from './../../common/utils/generalFunctions';

export const PromptModal = (props) => {

  const iconClassName = getCssClass(props.messageType);
  const modalTitle = props.title ? props.title : 'Managed Procurement Online';

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className={`modal-icon ${iconClassName} vertical-align-middle`} />
        <span className="modal-message">{props.message}</span>
      </Modal.Body>
      <Modal.Footer>
        {(props.yesButtonHandler) ? <button type="button" className="btn btn-primary" style={{ width: '15%' }} onClick={props.yesButtonHandler}>Yes</button> : null}
        {(props.noButtonHandler) ? <button type="button" className="btn btn-primary" style={{ width: '10%' }} onClick={props.noButtonHandler}>No</button> : null}
        {(props.okButtonHandler) ? <button type="button" className="btn btn-primary" style={{ width: '10%' }} onClick={props.okButtonHandler}>OK</button> : null}
      </Modal.Footer>
    </Modal>
  );
};

PromptModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  yesButtonHandler: PropTypes.func,
  noButtonHandler: PropTypes.func,
  okButtonHandler: PropTypes.func
};
