import React      from 'react';
import PropTypes  from 'prop-types';
import { Modal }  from 'react-bootstrap';

export class SetDaysModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    deliveryDays: PropTypes.array.isRequired,
    closeButtonHandler: PropTypes.func.isRequired,
    selectedOnHandOnReceivedDays: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      deliveryDays: props.selectedOnHandOnReceivedDays.length > 0 ? props.selectedOnHandOnReceivedDays : props.deliveryDays
    };
  }

  onDaySelection = (day, index) => {
    const {deliveryDays} = this.state;
    let updatedDeliveryDays = deliveryDays.map(x => ({...x}));
    updatedDeliveryDays[index].isSelected = !updatedDeliveryDays[index].isSelected;

    this.setState({deliveryDays: updatedDeliveryDays});
  };

  render() {
    const closeModal = this.props.closeButtonHandler;
    const {deliveryDays} = this.state;

    return (
      <Modal id="setDaysModal" show={this.props.show}>
        <Modal.Header>
          <Modal.Title>
            Set OH / OR
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
              {
                deliveryDays.map((day, index) => {
                  return (
                    <td key={index} style={{borderTop: "none"}} >
                      <div className={`checkmark-circle ${ day.isSelected ? 'checked' : ''}`}>
                        <div className="checkmark pointer" onClick={() => this.onDaySelection(day, index)}>
                          <span style={{paddingLeft: '25px'}}>{day.value}</span>
                        </div>
                      </div>
                    </td>
                  );
                })
              }
              </tr>
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <span className="alert alert-warning pull-left">
            <strong>Note:</strong> Select day(s) you want OH/OR columns added when exporting
          </span>
          <button className="btn btn-primary" style={{width: '15%'}} onClick={() => closeModal(deliveryDays)}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SetDaysModal;
