import React from "react";
import PropTypes from "prop-types";
import { Message } from './message';
import { useSelector } from 'react-redux';
import { getFilteredActiveMessages } from './../../common/utils/generalFunctions';

export const Banner = (props) => {
    const messages = useSelector(state => state.messages) || [];
    const message = getFilteredActiveMessages(messages, props.filterType);

    if(message !== null)
    {
        return(
            <div className="bannerContainer" >{
                <Message message = {message}/>
              }
            </div>
        );
    }
    return (null);   
};

Banner.propTypes = {
    filterType: PropTypes.string
};
